.yellow-header {
    background-color: #faa61a;
    display: flex;
    align-items: center;
    position: relative;
    top: 87px !important;
    padding: 0.4rem 1rem 0.4rem 1rem;

    .yellow-header-inner {
        display: flex;
        align-items: baseline;
        // width: 70%;
        width: 50%;
        margin: auto;
        z-index: 11;
        flex-wrap: wrap;

        .yellow-header-inner-big {
            padding-right: 0.5rem;
            font-size: clamp(0.8rem, 4vw, 2.8rem);
            font-weight: 900;
            font-family: "GothamBold" !important;
        }

        .yellow-header-inner-sm {
            font-size: clamp(0.6rem, 4vw, 2.2rem);
            font-weight: 400;
            color: #2e2e2e;
            font-family: "GothamBold" !important;
        }
    }

    .yellow-header-abs {
        position: absolute;
        bottom: -25px;
        left: 35%;
        width: 50px;
        height: 50px;
        background-color: #faa61a;
        transform: rotate(45deg);
        z-index: 10;
    }
}

.yellow-header {
    display: flex;
    align-items: center;
    position: relative;
    top: 83px !important;
}

@font-face {
    font-family: "Gotham";
    src: url("../../assets/fonts/Gotham-Book.otf");
}

p {
    font-family: "Gotham" !important;
    // font-size: 400;
}

.main-container {
    text-align: left;
}

.yellow-line {
    width: 100%;
    height: 5px;
    background-color: #faa61a;
    border: unset;
    margin: 0 !important;
}

.newdetail {
    width: 70%;
    margin: auto;
    position: relative;

    .newdetail-main {
        .newdetail-grid {
            display: flex;
            flex-wrap: wrap;
            // flex-direction: column;
            align-content: flex-start;
            // flex-flow: column wrap;
            gap: 2rem;
            position: relative;

            margin: 1rem 0rem 2rem 0rem;

            // display: grid;

            // grid-template-rows: 1fr 1fr 1fr 1fr;
            // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

            // gap: 2rem;
            // height: 100%;

            // column-count: 2;
            // column-gap: 2rem;

            // columns: 50% 2;

            .newdetail-grid-item {
                &:nth-child(1) {
                    width: 48%;
                    // grid-row-start: 1;
                    //     grid-column-start: 1;

                    //     grid-row-end: 3;
                    //     grid-column-end: 4;
                    //     height: auto;
                }

                &:nth-child(2) {
                    width: 48%;
                    margin: 0 auto;
                    // grid-row-start: 1;
                    //     grid-column-start: 4;

                    //     grid-row-end: 3;
                    //     grid-column-end: 7;
                    //     height: auto;
                    .address-grid {
                        display: flex;
                        flex-direction: column;
                        margin: 0.5rem 0;

                        .address-grid-item {
                            // display: grid;
                            align-items: center;
                            // grid-template-columns: 0.1fr 1.9fr;
                            // grid-gap: 0.5rem;

                            display: flex;
                            gap: 1rem;
                            margin: 0.2rem 0;

                            .address-grid-item-img {
                                img {
                                    display: block;
                                    width: 20px;
                                    max-width: 20px;
                                }
                            }

                            .address-grid-item-text {
                                p {
                                    font-size: clamp(10px, 3vw, 18px) !important;
                                    color: #faa61a;
                                }
                            }

                            &:first-child {
                                p {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .rate-grid {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        align-items: center;
                        border-top: 3px solid gray;
                        border-bottom: 3px solid gray;
                        padding: 0.4rem 0;

                        .rate-grid-item {
                            p {
                                display: flex;
                                align-items: baseline;
                                justify-content: center;
                                font-size: clamp(15px, 3vw, 25px);
                                color: #fff;

                                .rate-item-span {
                                    font-size: clamp(12px, 3vw, 16px);
                                    color: #faa61a;
                                }
                            }

                            &:nth-child(2) {
                                border-left: 2px solid gray;
                                border-right: 2px solid gray;
                            }
                        }
                    }

                    .owner-grid {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 2rem;
                        align-items: center;
                        margin: 1.5rem 0rem;

                        .owner-grid-item {
                            &:first-child {
                                // display: grid;
                                // grid-template-columns: repeat(2, 1fr);
                                // grid-gap: 2rem;
                                display: flex;
                                gap: 1rem;
                                align-items: center;

                                .owner-profile-img {
                                    img {
                                        display: block;
                                        width: 70px;
                                        max-height: 70px;
                                        height: 80px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                        border: 2px solid white;
                                    }
                                }

                                .owner-name-para {
                                    p {
                                        &:first-child {
                                            font-size: clamp(14px, 3vw, 18px) !important;
                                            color: #faa61a;
                                        }

                                        &:last-child {
                                            font-size: clamp(14px, 3vw, 18px) !important;
                                            color: #ffffff;
                                        }
                                    }
                                }
                            }

                            .owner-grid-item-div {
                                .owner-a {
                                    display: flex;
                                    align-items: center;
                                    // align-items: flex-end;
                                    gap: 0.5rem;
                                    text-decoration-color: #faa61a;

                                    // .msg-img {
                                    //     img {

                                    //     }
                                    // }
                                    .visit-img,
                                    .msg-img {
                                        img {
                                            display: block;
                                            width: 30px;
                                            height: 30px;
                                        }
                                    }

                                    .owner-a-text {
                                        p {
                                            color: #faa61a;
                                            font-size: clamp(12px, 3vw, 18px);
                                        }
                                    }

                                }

                                &:last-child {
                                    .renter-a-text {
                                        p {
                                            color: #faa61a;
                                            font-size: clamp(12px, 3vw, 18px);
                                            margin-top: 6px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    width: 52%;
                    // height: auto;
                    // grid-row-start: 3;
                    //     grid-column-start: 1;

                    //     grid-row-end: 5;
                    //     grid-column-end: 5;
                    .newdetail-grid-item-div {
                        margin: 1rem 0;

                        .newdetail-equip-heading {
                            font-size: clamp(16px, 3vw, 24px);
                            font-family: "GothamBold" !important;
                            color: #fff;
                        }

                        .top-list {
                            margin: 1rem 0;
                        }

                        .newdetail-equip-rate-detail {
                            font-size: clamp(12px, 3vw, 18px) !important;
                            color: #fff;
                            display: flex;
                            justify-content: space-between;
                            white-space: pre-line;
                        }

                        .bottom-list {
                            margin: 1rem 0;
                        }

                        .yellow-line {
                            border: none;
                            background-color: #faa61a;
                            height: 2.5px;
                        }

                        .newdetail-equip-para {
                            font-size: clamp(12px, 3vw, 18px) !important;
                            color: #fff;
                            margin-top: 5px;

                            .see-terms-link {
                                color: #faa61a;
                                text-decoration-color: #faa61a;
                                font-size: clamp(12px, 3vw, 18px) !important;
                                font-weight: 500;
                                cursor: pointer;
                            }

                            .distance-text,
                            .weekday {
                                color: #faa61a;
                            }
                        }

                        .newdetail-equip-subheading {
                            color: #faa61a;
                            font-size: clamp(12px, 3vw, 18px) !important;
                        }

                        .see-terms {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            margin: 1rem 0;

                            img {
                                width: 50px;
                                height: auto;
                                max-width: 100%;
                                display: block;
                            }
                        }

                        &:nth-child(1) {}

                        &:nth-child(2) {}

                        &:nth-child(3) {}

                        &:nth-child(4) {}

                        &:nth-child(5) {}

                        &:nth-child(6) {}

                        &:nth-child(7) {}
                    }
                }

                &:nth-child(4) {
                    // width: 33%;
                    width: 35%;
                    margin: -135px auto;
                    margin-bottom: 2rem;

                    position: -webkit-sticky;
                    position: sticky;
                    top: 15%;
                    // top: -1px;
                    // height: auto;
                    // grid-row-start: 3;
                    //     grid-column-start: 5;

                    //     grid-row-end: 5;
                    //     grid-column-end: 7;
                    .newdetail-checkout-div {
                        border: 2px solid #faa61a;
                        padding: 1rem 2rem;
                        border-radius: 15px;
                        //   width: auto;
                        width: 80%;
                        position: -webkit-sticky;
                        position: -moz-sticky;
                        position: -o-sticky;
                        position: -ms-sticky;
                        position: sticky;

                        top: 8rem;


                        .newdetail-equip-heading {
                            font-size: clamp(12px, 3vw, 24px);
                            font-family: "GothamBold" !important;
                            color: #fff;
                            margin-bottom: 0.5rem;
                        }

                        .rental-summary-div {
                            .rental-summary-text {
                                font-size: clamp(12px, 3vw, 18px);
                                color: #fff;
                                display: flex;
                                justify-content: space-between;
                            }

                            .equipprotection-link {
                                span {
                                    color: #faa61a !important;
                                    text-decoration-color: #faa61a !important;
                                    text-decoration-line: underline;
                                }

                            }

                            .equipme-protect {
                                //   color: #faa61a;
                                //   text-decoration-color: #faa61a;
                                //   text-decoration: underline;

                                span {
                                    color: #fff;
                                    // text-decoration: none !important;
                                }
                            }
                        }

                        .total-p {
                            font-size: clamp(16px, 3vw, 28px);
                            text-align: center;
                            color: #fff;
                            font-family: "GothamBold" !important;
                            margin: 0.5rem 0;

                            .total-span {
                                color: #faa61a;
                            }
                        }

                        .rent-now-btn {
                            background-color: #149247;
                            color: #fff;
                            // font-family: "GothamBold" !important;
                            font-size: clamp(16px, 3vw, 28px);
                            border-radius: 8px;
                            width: 100%;
                            height: 35px;
                            padding: 5px 0 5px 0 !important;
                            font-weight: bolder;
                        }

                        .select-date-div {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 1rem;

                            p {
                                color: #fff;
                                font-size: clamp(12px, 3vw, 18px);
                            }

                            .select-date-textfield {
                                // border: 1px solid #faa61a;
                                // border-radius: 5px;
                                // height: 30px;
                            }
                        }

                        .transport-options {
                            margin: 1rem 0rem;

                            .transport-options-heading {
                                color: #faa61a;
                                font-size: clamp(12px, 3vw, 24px);
                                font-family: "GothamBold" !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.equipment-carousel-main-div {
    background-color: #faa61a;
    // height: 400px;
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .rmdp-container {}

    .calendar-save-sec.cal-avail-large {
        width: 100% !important;
        margin: auto;
    }

    .newdetail {
        width: 85%;
        margin: auto;

        .newdetail-main {
            .newdetail-grid {
                .newdetail-grid-item {
                    &:nth-child(4) {
                        width: 35%;
                        margin: -135px auto;
                        position: sticky;
                        top: 0px;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .calendar-save-sec.cal-avail-large {
        width: 100% !important;
        margin: auto;
    }



    .newdetail {
        width: 100%;
        margin: auto;
    }

    // .twin-cal .twin-cal-custom-calendar .rmdp-day,
    // .twin-cal .twin-cal-custom-calendar .rmdp-week-day {
    //     color: #ffffff !important;
    //     cursor: pointer;
    //     height: 40px !important;
    //     position: relative;
    //     width: 40px !important;
    // }
}

@media screen and (max-width: 1024px) {
    .firstoption-inner-div p {
        font-size: 14px !important;
    }

    .calendar-save-sec.cal-avail-large {
        width: 100% !important;
        margin: auto;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(2) .owner-grid .owner-grid-item .owner-grid-item-div .owner-a .owner-a-text p {
        color: #faa61a;
        font-size: 16px !important;
    }

    .newdetail {
        width: 100%;
        margin: auto;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(4) {
        width: 40% !important;
        margin: -135px auto;
        position: sticky;
        top: 0px;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 992px) {
    .dextop {
        display: none !important;
    }

    .newdetail-grid.mobile.MuiBox-root.css-0 {
        display: flex !important;
    }

    // .twin-cal {
    //     display: none !important;
    // }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(4) {
        width: 100% !important;
        margin: -135px auto;
        position: sticky;
        top: -1px;
    }

    .equipment-carousel-main-div {
        margin-top: 2.5rem;
    }

    .rental-summry-sec {
        display: block !important;
    }

    .newdetail {
        width: 60%;

        .newdetail-main {
            .newdetail-grid {
                gap: 1rem;
                flex-direction: column;
                margin-top: 1rem;

                .newdetail-grid-item {
                    &:nth-child(1) {
                        width: 100%;
                    }

                    &:nth-child(2) {
                        width: 100%;

                        .owner-grid {
                            grid-gap: 0rem;

                            .owner-grid-item {
                                &:first-child {
                                    .owner-profile-img {
                                        img {
                                            width: 60px;
                                            height: 60px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(3) {
                        width: 100%;
                    }

                    &:nth-child(4) {
                        width: 100%;
                        margin: 0;

                        .newdetail-checkout-div {
                            padding: 1rem 1rem;
                        }
                    }
                }
            }
        }
    }

    .rental-dates-sec {
        display: flex !important;
    }

    .calendar-save-sec {
        // display: flex !important;
        flex-direction: column;
        width: 60%;
        margin: auto !important;
    }

    .mapnslide {
        width: 100% !important;
    }

    .proceed-sec {
        width: 60% !important;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {
    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(4) .newdetail-checkout-div {
        border: 2px solid #faa61a;
        border-radius: 15px;
        padding: 1rem 2rem;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        top: 105px;
        width: 90%;
    }

    .address-main-div {
        margin-top: 1rem;
    }

    .see-terms-link.equipprotection-link {
        text-decoration-color: #faa61a;
    }

    .owner-grid-item-div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .proceed-sec {
        width: 70% !important;
        margin: auto;
    }

    .newdetail {
        width: 70%;

        .newdetail-main {
            .newdetail-grid {
                flex-direction: column;
            }
        }
    }

    .main-container {
        min-height: calc(100vh - 228px);
        margin: 40px 16px !important;
        text-align: left !important;
    }

    .mapnslide {
        width: 100% !important;
    }

    .equipment-carousel-main-div {
        // height: 385px !important;
    }

    .below-owner-photo {
        margin-bottom: 1rem;
        display: flex !important;

        .rent-now-btn {
            background-color: #149247;
            color: #fff;
            // font-family: "GothamBold" !important;
            font-size: 24px;
            line-height: 36px;
            border-radius: 8px;
            width: 100%;
            height: 35px;
            padding: 5px 0px 5px 0px !important;
            font-weight: bolder;

            &:hover {
                background-color: #149247;
            }
        }
    }

    .rental-summry-sec {
        display: block !important;
    }

    .calendar-save-sec {
        width: 70% !important;
    }
}

@media screen and (max-width: 576px) {
    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(4) .newdetail-checkout-div {
        border: 2px solid #faa61a;
        border-radius: 15px;
        padding: 1rem 2rem;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        top: 105px;
        width: 90%;
    }

    .mapnslide .mapsec-heading .mapsec-icon img {
        width: 17px !important;
    }

    .mapsec-heading-text.MuiBox-root.css-0>h5 {
        font-size: 20px !important;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(2) .owner-grid .owner-grid-item .owner-grid-item-div .owner-a .owner-a-text p {
        color: #faa61a;
        font-size: 16px !important;
        margin-top: 2px;
    }

    .visit-img img {
        width: 25px !important;
        height: 25px !important;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(2) .address-grid .address-grid-item .address-grid-item-text p {
        font-size: 14px !important;
        color: #faa61a;
    }

    .renter-section-grid p {
        font-size: 13px;
    }

    .transport-total {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;
    }

    .rental-summry-sec .rental-summry-rate-grid .rental-summry-rate-item p {
        font-size: 11px !important;
    }

    .rental-summry-sec .rental-summry-rate-grid {
        grid-gap: 1rem !important;
    }

    .rental-summry-sec .rental-summry-total p {
        color: #fff;
        font-size: 22px !important;
        /* font-weight: bold; */
        font-family: 'GothamBold' !important;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(4) .newdetail-checkout-div .total-p {
        font-size: 28px;
        text-align: center;
        color: #fff;
        font-family: "GothamBold" !important;
        margin: 0.5rem 0;
        font-weight: bold;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(4) .newdetail-checkout-div .rent-now-btn {
        background-color: #149247;
        color: #fff;
        // font-family: "GothamBold" !important;
        font-size: 28px;
        border-radius: 8px;
        width: 100%;
        height: 35px;
        padding: 5px 0 !important;
        font-weight: bolder;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(3) .newdetail-grid-item-div .see-terms img {
        width: 35px !important;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(2) .address-grid .address-grid-item .address-grid-item-img img {
        display: block;
        width: 15px !important;
        max-width: 15px !important;
    }

    .proceed-sec {
        width: 100% !important;
        margin: auto;
    }

    .equipment-carousel-main-div {
        // height: 200px !important;
    }

    .calendar-save-sec {
        width: 100% !important;
    }

    .newdetail {
        width: 100%;

        .newdetail-main {
            .newdetail-grid {
                gap: 1rem;
                flex-direction: column;
                margin-top: 1rem;

                .newdetail-grid-item {
                    &:nth-child(1) {
                        width: 100%;
                    }

                    &:nth-child(2) {
                        width: 100%;

                        .owner-grid {
                            grid-gap: 0rem;

                            .owner-grid-item {
                                &:first-child {
                                    .owner-profile-img {
                                        img {
                                            width: 60px;
                                            height: 60px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(3) {
                        width: 100%;
                    }

                    &:nth-child(4) {
                        width: 100%;
                        margin: 0;

                        .newdetail-checkout-div {
                            padding: 1rem 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {

    // .rental-date-m {
    //     font-size: 12px !important;
    // }
    .rental-summry-sec .rental-summry-rate-grid .rental-summry-rate-item p {
        font-size: 9px !important;
    }

    .calendar-heading-grid .calendar-grid-icon img {
        width: 30px !important;
    }

    .calendar-heading-grid .calendar-heading-p p {
        font-size: 17px !important;
    }

    .newdetail .newdetail-main .newdetail-grid .newdetail-grid-item:nth-child(2) .owner-grid .owner-grid-item .owner-grid-item-div .owner-a .owner-a-text p {
        color: #faa61a;
        font-size: 12px !important;
        margin-top: 2px;
    }

    .newdetail {
        .newdetail-main {
            .newdetail-grid {
                flex-direction: column;
            }
        }
    }
}

.newdetail-grid.mobile.MuiBox-root.css-0 {
    display: none;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px !important;
    border: none !important;

    &:hover {
        outline: none !important;
        border: none !important;
    }
}

.equipment-carousel-main-div {
    background-color: #faa81a00;
    // height: 400px;
    width: 100%;
    position: relative;
}

.equipment-map-heading {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
        width: 100%;
        display: block;
    }
}

.main-container {
    min-height: auto;
    // min-height: calc(100vh - 228px);
    margin: 25px 80px 50px 80px;
    text-align: left !important;
}

.eqp-map-heading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;

    .eqp-map-icon {
        img {
            display: block;
            width: 25px;
            max-width: 25px;
            height: auto;
        }
    }
}

.mapnslide {
    width: 48%;
    margin: 0.5rem 0 1.5rem 0;

    .mapsec-heading {
        display: flex;
        align-items: center;
        gap: 14px;
        margin: 1rem 0;

        .mapsec-icon {
            img {
                width: 20px;
                height: auto;
                display: block;
            }
        }
    }
}

.renter-section-grid {
    // display: flex;
    // align-items: center;
    // gap: 0.5rem;
    display: grid;
    grid-template-columns: 0.2fr 1.8fr;
    margin: 5px 0;

    .secondoption-img {
        img {
            margin-top: 5px;
            width: 16px;
            height: auto;
            display: block;
        }
    }

    .renter-radio,
    .owner-radio {
        margin-top: 5px;
        width: 15px;
        height: auto;
        display: block;
    }
}

.owner-section-detail {
    margin-top: 1rem;

    .renter-section-grid {
        // display: flex;
        // align-items: center;
        // gap: 0.5rem;
        display: grid;
        grid-template-columns: 0.2fr 1.8fr;
        margin-top: 5px;

        .secondoption-img {
            img {
                margin-top: 10px;
                width: 16px;
                height: auto;
                display: block;
            }
        }
    }
}

.ltr-left {
    font-size: 12px !important;
    color: gray;
}

.firstoption-inner-div {
    display: flex;
    gap: 1rem;

    p {
        font-size: 14px;
    }
}

@media (max-width:1600px) {
    .firstoption-inner-div p {
        font-size: 11px !important;
    }
}

@media (max-width:1500px) {
    .firstoption-inner-div p {
        font-size: 11px !important;
    }
}

@media (max-width:1400px) {
    .firstoption-inner-div p {
        font-size: 12px !important;
    }
}

@media (max-width:1300px) {
    .firstoption-inner-div p {
        font-size: 11px !important;
    }
}

@media (max-width:1200px) {
    .firstoption-inner-div p {
        font-size: 10px !important;
    }
}

@media (max-width:1024px) {
    .firstoption-inner-div p {
        font-size: 11px !important;
    }
}



.calendar-date-select {
    border: 1.5px solid #faa61a !important;
    border-radius: 5px;
    height: 22px;
    margin: 1px 0;
    padding: 2px 5px;
    background: transparent !important;
    color: #fff !important;
    width: 180px !important;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rmdp-input {
    display: none !important;
}

.ep-arrow.rmdp-ep-arrow.rmdp-ep-shadow.my-custom-calendar {
    display: none !important;
}

// .rmdp-wrapper.rmdp-shadow.my-custom-calendar {
//     top: -25px !important;
//     position: absolute;
//     left: -100px;
// }

.below-owner-photo {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: none;

    .rent-now-btn {
        background-color: #149247;
        color: #fff;
        // font-family: "GothamBold" !important;
        // font-size: clamp(16px, 3vw, 28px);
        font-size: 28px;
        font-weight: bolder;
        border-radius: 8px;
        width: 100%;
        height: 35px;
        padding: 5px 0px !important;

    }
}


.rental-summry-sec {
    border: 2px solid #faa61a;
    padding: 0.7rem;
    border-radius: 15px;
    display: none;

    .rental-summry-heading {
        p {
            color: #faa61a;
            font-size: clamp(16px, 3vw, 16px);
            font-family: "GothamBold" !important;
        }
    }

    .rental-summry-rate-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem !important;
        align-items: center;
        margin: 0.2rem 0 0 0;

        .rental-summry-rate-item {
            p {
                color: #fff;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
            }

            .rental-dates-heading {
                font-size: 14px;
                font-weight: 700;
            }
        }
    }

    .rental-summry-edit {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .rental-summry-edit-btn {
            font-size: 12px;
            padding: 0;
            margin: 0 !important;
            color: #faa61a;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .rental-summry-total {
        margin-top: 0rem;
        margin-bottom: 0.1rem;

        p {
            color: #fff;
            font-weight: 700;
            font-size: 18px;

            .rental-summry-yellow-text {
                color: #faa61a;
            }
        }
    }

    .bottom-note {
        p {
            color: #fff;
            font-size: 11px;
        }
    }
}

.calendar-save-sec {
    margin: 1rem 0;
    // display: none;

    .calendar-btn-sec {
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        align-items: center;
        margin: 1rem 0;

        .reset-btn,
        .save-btn {
            color: #fff !important;
            background-color: #faa61a;
            font-size: 26px;
            line-height: normal;
            font-weight: bolder;
            padding: 0 !important;
            // padding: 0 1rem !important;
            // font-family: "GothamBold" !important;
            font-weight: bolder;
            border-radius: 10px;
        }
    }

    .calendar-sec-slider {
        margin: 1rem 0;
    }
}

.Calendar__monthArrowWrapper.-right {
    transform: rotate(-90deg);
    position: absolute !important;
    top: 50% !important;
    left: -18px !important;
}

.Calendar__monthArrowWrapper.-left {
    transform: rotate(-90deg);
    position: absolute !important;
    top: 50% !important;
    // left: -18px !important;
}

.rental-dates-sec {
    display: flex;
    align-items: center;
    gap: 1rem;
    display: none;

    .rental-dates-icon {
        img {
            width: 35px;
            height: 35px;
            display: block;
        }
    }

    .rental-dates-p {
        p {
            color: #fff;
            font-size: 16px;
            line-height: normal;
            font-family: "GothamBold" !important;
        }
    }
}

.address-main-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.rental-dates-sec {
    display: flex;
    align-items: center;
    gap: 8px;
    display: none;
}

.calendar-heading-grid {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;

    .calendar-grid-icon {
        img {
            width: 40px;
            height: auto;
            display: block;
        }
    }

    .calendar-heading-p {
        p {
            font-family: "GothamBold" !important;
            color: #fff;
            // font-size: clamp(18px, 3vw, 26px);
            font-size: 20px;
        }
    }
}

.proceed-sec {
    width: 40%;
    margin: auto;

    .proceed-sec-heading {
        display: flex;
        align-items: center;
        gap: 1rem;

        .proceed-sec-icon {
            img {
                display: block;
                width: 35px;
                height: auto;
            }
        }

        .proceed-sec-text {
            p {
                font-family: "GothamBold" !important;
                color: #fff;
                font-size: clamp(35px, 3vw, 50px) !important;

                span {
                    color: #faa61a;
                }
            }
        }
    }

    .total-amount-div {
        margin: 1rem 0;

        p {
            font-family: "GothamBold" !important;
            text-align: center;
            color: #fff;

            &:first-child {
                color: #faa61a !important;
            }
        }

        .total-value-text {
            font-size: clamp(20px, 3vw, 24px);
        }

        .proceed-btn-div {
            text-align: center;

            .proceed-btn {
                font-family: "GothamBold" !important;
                color: black !important;
                background: #faa61a;
                text-align: center;
                border-radius: 10px;
                width: 70%;
                margin: 1rem 0;
            }
        }
    }

    .wallet-div {
        p {
            font-family: "GothamBold" !important;
            color: #faa61a;
            text-align: center;

            span {
                color: #fff;
            }
        }
    }

    .addmoney-div {
        display: flex;
        justify-content: center;
        margin: 1rem 0;

        .addmoney-a {
            font-family: "GothamBold" !important;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            text-decoration-color: #fff;

            img {
                width: 15px;
                height: auto;
                max-height: 15px;
            }

            p {}
        }
    }
}


// .rmdp-day-picker {
//     padding: 5px;
//     display: flex !important;
//     gap: 2rem !important;
//     justify-content: space-around !important;
//     height: auto !important;

//     div {
//         &:first-child {

//             // width: 50% !important;
//             .rmdp-week {
//                 display: grid;
//                 grid-template-columns: repeat(7, 1fr);
//                 grid-gap: 1rem;
//                 margin: 0.5rem 0 !important;
//             }
//         }

//         &:last-child {

//             // width: 50% !important;
//             .rmdp-week {
//                 display: grid;
//                 grid-template-columns: repeat(7, 1fr);
//                 grid-gap: 1rem;
//                 margin: 0.5rem 0 !important;
//             }
//         }
//     }
// }

// .twin-cal {
//     margin-bottom: 2rem;
//     margin-top: 1rem;



//     .rmdp-wrapper {
//         background-color: #ffffff00 !important;
//         // background-image: url("../../assets/images/banner_img.png") !important;
//         border-radius: 0px !important;
//         border: 1px solid #fff;
//         direction: ltr;
//         text-align: center;
//         width: 100% !important;
//         box-shadow: none !important;
//     }

//     .twin-cal-custom-calendar {

//         .rmdp-day,
//         .rmdp-week-day {
//             color: #ffffff !important;
//             cursor: pointer;
//             height: 45px !important;
//             position: relative;
//             width: 45px !important;
//         }

//         .rmdp-calendar {
//             width: 100% !important;
//             // padding: 1rem 2rem 2rem 2rem !important;
//             padding: 1rem !important;
//             height: 450px !important;
//         }

//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//             background-color: rgb(0 87 161) !important;
//             color: #fff;
//         }

//         .rmdp-day.rmdp-today.rmdp-range.start {
//             .twin-cal .twin-cal-custom-calendar .rmdp-day.rmdp-today span {
//                 background-color: rgba(16, 110, 190, 0) !important;
//                 color: rgb(255 255 255) !important;
//             }
//         }



//         .rmdp-day span {
//             border-radius: 2px;
//             bottom: 3px;
//             // font-size: 13px;
//             font-size: clamp(16px, 3vw, 20px) !important;
//             left: 3px;
//             position: absolute;
//             right: 3px;
//             top: 3px;
//             // width: 100% !important;
//             // height: 100% !important;
//         }

//         .rmdp-range {
//             //   background-color: #faa61a !important;
//             box-shadow: none !important;
//             color: #fff;
//             border-radius: 2px !important;
//         }

//         .rmdp-day.rmdp-today span {
//             background-color: rgb(16 110 190 / 0%) !important;
//             color: rgb(0 147 255) !important;
//         }
//         .rmdp-day.rmdp-today span.reserved {
//             background-color: #ff2929 !important;
//             color: #fff !important;
//         }

//         .rmdp-range-hover.start,
//         .rmdp-range.start {
//             border-bottom-left-radius: 50% !important;
//             border-top-left-radius: 50% !important;
//         }

//         .rmdp-range-hover.end,
//         .rmdp-range.end {
//             border-bottom-right-radius: 50% !important;
//             border-top-right-radius: 50% !important;
//         }

//         .rmdp-range-hover.start:hover,
//         .rmdp-range.start:hover {
//             .sd {
//                 border-bottom-left-radius: 50% !important;
//                 border-top-left-radius: 50% !important;
//             }
//         }

//         .rmdp-range-hover.end:hover,
//         .rmdp-range.end:hover {
//             .sd {
//                 border-bottom-right-radius: 50% !important;
//                 border-top-right-radius: 50% !important;
//             }

//         }

//         .rmdp-arrow-container {
//             height: 40px !important;
//             justify-content: center;
//             margin: 0 !important;
//             width: 40px !important;
//             // background-color: rgb(16, 110, 190) !important;
//             display: flex !important;
//             justify-content: center !important;
//             align-items: center !important;
//         }

//         .rmdp-arrow-container.disabled .rmdp-arrow,
//         .rmdp-arrow-container.disabled:hover .rmdp-arrow {
//             border: solid black;
//             border-width: 0 2px 2px 0;
//         }

//         .rmdp-arrow {
//             // border: solid #000 !important;
//             border-width: 0 2px 2px 0;
//             display: inline-block;
//             height: 8px !important;
//             margin-top: 0px !important;
//             padding: 0px !important;
//             width: 8px !important;
//             color: #000 !important;
//         }

//         .rmdp-calendar {
//             width: 100% !important;
//         }

//         .rmdp-arrow {
//             border: solid #0074d9;
//             border-width: 0 2px 2px 0;
//             display: inline-block;
//             height: 3px;
//             margin-top: 5px;
//             padding: 2px;
//             width: 3px;
//         }

//         .rmdp-header {
//             // font-size: 16px !important;
//             font-size: clamp(18px, 3vw, 22px) !important;
//             width: 100% !important;
//             max-width: 100% !important;
//             color: #fff !important;
//         }

//         .rmdp-header-values {
//             color: #fff !important;
//         }

//         .rmdp-day.rmdp-today span {
//             //   background-color: #faa61a !important;
//             color: #fff;
//             box-shadow: none !important;
//         }

//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//             //   background-color: #faa61a !important;
//             color: #fff;
//             box-shadow: none !important;
//         }

//         .rmdp-day,
//         .rmdp-week-day {
//             color: #fff !important;
//             font-weight: 300 !important;
//         }

//         .rmdp-day.rmdp-disabled {
//             color: rgb(99, 99, 99) !important;
//         }

//         .rmdp-week-day {
//             color: #ffffff !important;
//             font-size: 22px;
//             font-weight: 500;
//         }
//     }
// }

.map-location div:nth-child(3) {
    display: none !important;
}

.map-location {
    .google-address-field div:nth-child(1) {
        // height: 2rem !important;
        margin-top: 5px !important;
    }

    .google-address-field input {
        // height: 0rem !important;
        font-size: 10px !important;
    }
}

.mobile-thumb {
    .image-gallery-thumbnails-wrapper {
        display: none !important;
    }
}

.back-btn-icon {
    color: #fff !important;
    font-weight: bold;
    cursor: pointer;
}

/*********************************************/
// .rmdp-disabled {
//     cursor: not-allowed !important;
//     pointer-events: none !important;
//     // background-color: #e00a0a !important;
//     color: rgb(99, 99, 99) !important;
//     border-radius: 25px !important;
// }

// .rmdp-day .reserved {
//     //background-color: #faa61a !important;
//     background-color: #ee1717 !important;
//     color: #ffffff;
//     border-radius: 2px !important;
// }

.reserved {
    cursor: not-allowed !important;
    pointer-events: none !important;
    background-color: #ee1717 !important;
    color: #ffffff;
    border-radius: 2px !important;
}

.my-custom-calendar {
    background-color: #2e2e2e !important;
}

// .rmdp-header-values,
// .rmdp-week-day {
//     color: #ffffff !important;
// }

// .block_day,
// .rmdp-input,
// .rmdp-day:has(.reserved) {
//     cursor: not-allowed !important;
//     pointer-events: none !important;
// }

.sd {
    color: #ffffff !important;
}

// ----------------------------------
.calendar-save-sec.cal-avail-large {
    width: 70%;
    margin: auto;
}

.large-save-btn,
.small-save-btn {
    width: 100%;
}

// .large-cal {
//     .rmdp-wrapper {
//         background-color: #ffffff00 !important;
//         background-image: url("../../assets/images/banner_img.png") !important;
//         border-radius: 0px !important;
//         border: 1px solid #fff;
//         direction: ltr;
//         text-align: center;
//         width: 100% !important;
//         box-shadow: none !important;
//     }

//     .twin-cal-custom-calendar {

//         .rmdp-day,
//         .rmdp-week-day {
//             color: #ffffff !important;
//             cursor: pointer;
//             height: 45px !important;
//             position: relative;
//             width: 45px !important;
//         }

//         .rmdp-calendar {
//             width: 100% !important;
//             // padding: 1rem 2rem 2rem 2rem !important;
//             padding: 1rem !important;
//             height: 450px !important;
//         }

//         .rmdp-day span {
//             border-radius: 50%;
//             bottom: 3px;
//             // font-size: 13px;
//             font-size: clamp(16px, 3vw, 20px) !important;
//             left: 3px;
//             position: absolute;
//             right: 3px;
//             top: 3px;
//             // width: 100% !important;
//             // height: 100% !important;
//         }

//         .rmdp-range {
//             background-color: #1976d2 !important;
//             box-shadow: none !important;
//             color: #fff;
//             border-radius: 5px !important;
//         }

//         .rmdp-arrow-container {
//             height: 40px !important;
//             justify-content: center;
//             margin: 0 !important;
//             width: 40px !important;
//             background-color: #1976d2 !important;
//             display: flex !important;
//             justify-content: center !important;
//             align-items: center !important;
//         }

//         .rmdp-arrow-container.disabled .rmdp-arrow,
//         .rmdp-arrow-container.disabled:hover .rmdp-arrow {
//             border: solid black;
//             border-width: 0 2px 2px 0;
//         }

//         .rmdp-arrow {
//             // border: solid #000 !important;
//             border-width: 0 2px 2px 0;
//             display: inline-block;
//             height: 8px !important;
//             margin-top: 0px !important;
//             padding: 0px !important;
//             width: 8px !important;
//             color: #000 !important;
//         }

//         .rmdp-calendar {
//             width: 100% !important;
//         }

//         .rmdp-header {
//             // font-size: 16px !important;
//             font-size: clamp(18px, 3vw, 22px) !important;
//             width: 100% !important;
//             max-width: 100% !important;
//             color: #fff !important;
//         }

//         .rmdp-header-values {
//             color: #fff !important;
//         }

//         .rmdp-day.rmdp-today span {
//             background-color: #1976d2 !important;
//             color: #fff;
//             box-shadow: none !important;
//         }

//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//             background-color: #1976d2 !important;
//             color: #fff;
//             box-shadow: none !important;
//         }

//         .rmdp-day,
//         .rmdp-week-day {
//             color: #fff !important;
//             font-weight: 300 !important;
//         }

//         .rmdp-day.rmdp-disabled {
//             color: rgb(99, 99, 99) !important;
//         }

//         .rmdp-week-day {
//             color: #ffffff !important;
//             font-size: 22px;
//             font-weight: 500;
//         }
//     }
// }


// .rmdp-range {
//     background-color: #0074d9;
//     box-shadow: none !important;
//     color: #fff;
// }

// .rmdp-day span {
//     border-radius: unset;
//     bottom: 3px;
//     font-size: 14px;
//     left: 3px;
//     position: absolute;
//     right: 3px;
//     top: 3px;
// }

// .rmdp-range-hover.start,
// .rmdp-range.start {
//     border-bottom-left-radius: 50% !important;
//     border-top-left-radius: 50% !important;
// }

// .rmdp-range-hover.end,
// .rmdp-range.end {
//     border-bottom-right-radius: 50% !important;
//     border-top-right-radius: 50% !important;
// }

// .rmdp-range-hover.start:hover,
// .rmdp-range.start:hover {
//     .sd {
//         border-bottom-left-radius: 50% !important;
//         border-top-left-radius: 50% !important;
//     }
// }

// .rmdp-range-hover.end:hover,
// .rmdp-range.end:hover {
//     .sd {
//         border-bottom-right-radius: 50% !important;
//         border-top-right-radius: 50% !important;
//     }

// }

.confirm-btn {
    font-size: 17px !important;
    font-family: "GothamBold" !important;
    margin-bottom: 1rem !important;
    background-color: #149247 !important;
    color: #fff !important;
    border-radius: 7px !important;
}

.plus-btn {
    font-size: 18px !important;
    background: rgba(250, 168, 26, 0) !important;
    font-family: "GothamBold" !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.minus-btn {
    font-size: 18px !important;
    background: #faa81a00 !important;
    font-family: "GothamBold" !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.cal-time-input {
    width: 50px;

    .Mui-disabled {
        color: #000 !important;
        padding: 5px !important;
        text-align: center;
    }

    .Mui-disabled {
        opacity: 1;
        -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
    }
}

.time-main-box-top {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.time-main-box-mid {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0.5rem 0;
}

.time-main-box-btm {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.time-main-box {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.time-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2e2e2e !important;
}

.confirm-div {
    background-color: #2e2e2e !important;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem 0 0.5rem;
}

.time-box-label {
    color: #fff !important;
    font-family: "Gotham" !important;
    font-size: 16px !important;
}

.cal-main-btm {
    .confirm-btn {
        display: none !important;
    }
}

.modal-cal {
    .confirm-btn {
        display: none !important;
    }
}

// .my-custom-calendar {
//     .rmdp-day.rmdp-today span {
//         background-color: #7fdbff00 !important;
//         color: rgb(0, 147, 255) !important
//     }
//     .rmdp-day.rmdp-today span.reserved {
//         background-color: #ff2929 !important;
//         color: #fff !important;
//     }
// }

// .my-custom-calendar {
//     :root {
//             --rmdp-primary: #0074d9;
//             --rmdp-secondary: #4ca6f5;
//             --rmdp-shadow: #8798ad;
//             --rmdp-today: #7fdbff;
//             --rmdp-hover: #7ea6f0;
//             --rmdp-border: #cfd8e2;
//             --highlight-red-color: #cc0303;
//             --highlight-red-color-deactive: #e08e8e;
//             --highlight-red-selected: #ea0034;
//             --highlight-red-selected-deactive: #e4b0ba;
//             --highlight-red-hover: #ff6687;
//             --highlight-green-color: #00796b;
//             --highlight-green-color-deactive: #7ab3ac;
//             --highlight-green-selected: #009688;
//             --highlight-green-selected-deactive: #749c98;
//             --highlight-green-hover: #4db6ac;
//         }

//         .rmdp-wrapper {
//             width: max-content;
//             text-align: center;
//             background-color: white;
//             border-radius: 5px;
//             direction: ltr;
//         }

//         .rmdp-shadow {
//             box-shadow: 0 0 5px var(--rmdp-shadow);
//         }

//         .rmdp-border {
//             border: 1px solid var(--rmdp-border);
//         }

//         .rmdp-calendar {
//             padding: 4px;
//             height: max-content;
//         }

//         .rmdp-border-top {
//             border-top: 1px solid var(--rmdp-border);
//         }

//         .rmdp-border-bottom {
//             border-bottom: 1px solid var(--rmdp-border);
//         }

//         .rmdp-border-left {
//             border-left: 1px solid var(--rmdp-border);
//         }

//         .rmdp-border-right {
//             border-right: 1px solid var(--rmdp-border);
//         }

//         .rmdp-week,
//         .rmdp-ym {
//             display: flex;
//             justify-content: space-between;
//         }

//         .rmdp-ym {
//             height: 25%;
//         }

//         .rmdp-day,
//         .rmdp-week-day {
//             height: 34px;
//             width: 34px;
//             cursor: pointer;
//             position: relative;
//             color: black;
//         }

//         .rmdp-week-day {
//             cursor: default;
//             color: var(--rmdp-primary);
//             font-weight: 500;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             font-size: 13px;
//         }

//         .rmdp-day span {
//             position: absolute;
//             left: 3px;
//             right: 3px;
//             top: 3px;
//             bottom: 3px;
//             border-radius: 50%;
//             display: flex;
//             justify-content: center;
//             flex-direction: column;
//             font-size: 14px;
//         }

//         .rmdp-day.rmdp-today span {
//             background-color: var(--rmdp-today);
//             color: white;
//         }

//         .rmdp-day.rmdp-selected span:not(.highlight) {
//             background-color: var(--rmdp-primary);
//             box-shadow: 0 0 3px var(--rmdp-shadow);
//             color: white;
//         }

//         .rmdp-day.rmdp-deactive,
//         .rmdp-day.rmdp-disabled {
//             color: var(--rmdp-shadow);
//         }

//         .rmdp-day.rmdp-deactive.rmdp-selected span {
//             background-color: var(--rmdp-secondary);
//             box-shadow: 0 0 3px #bac5d3;
//         }

//         .rmdp-ym .rmdp-day {
//             flex: 1;
//             margin: auto;
//         }

//         .rmdp-ym .rmdp-day span {
//             border-radius: 12px;
//             padding: 2px 0px;
//         }

//         .rmdp-range {
//             color: white;
//             background-color: var(--rmdp-primary);
//             box-shadow: 0 0 3px var(--rmdp-shadow);
//         }

//         .rmdp-range-hover {
//             background-color: var(--rmdp-hover);
//             color: white;
//         }

//         .rmdp-range.start,
//         .rmdp-range-hover.start {
//             border-top-left-radius: 50%;
//             border-bottom-left-radius: 50%;
//         }

//         .rmdp-range.end,
//         .rmdp-range-hover.end {
//             border-top-right-radius: 50%;
//             border-bottom-right-radius: 50%;
//         }

//         .rmdp-ym .rmdp-range.start,
//         .rmdp-ym .rmdp-range-hover.start {
//             border-top-left-radius: 15px;
//             border-bottom-left-radius: 15px;
//         }

//         .rmdp-ym .rmdp-range.end,
//         .rmdp-ym .rmdp-range-hover.end {
//             border-top-right-radius: 15px;
//             border-bottom-right-radius: 15px;
//         }

//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//             background-color: var(--rmdp-hover);
//             color: white;
//         }

//         .rmdp-day-picker {
//             padding: 5px;
//         }

//         .rmdp-header {
//             margin-top: 5px;
//             font-size: 14px;
//             padding: 9px 0;
//         }

//         .rmdp-month-picker,
//         .rmdp-year-picker {
//             background-color: white;
//             position: absolute;
//             top: 2px;
//             left: 2px;
//             bottom: 2px;
//             right: 2px;
//             border-radius: 0 0 5px 5px;
//         }

//         .only.rmdp-month-picker,
//         .only.rmdp-year-picker {
//             position: static;
//             height: 240px;
//         }

//         .only.rmdp-month-picker .rmdp-day,
//         .only.rmdp-year-picker .rmdp-day {
//             width: 80px;
//         }

//         .rmdp-header-values {
//             color: black;
//             margin: auto;
//         }

//         .rmdp-header-values span {
//             padding: 0 5px;
//             padding-right: 0;
//         }

//         .rmdp-arrow {
//             border: solid var(--rmdp-primary);
//             border-width: 0 2px 2px 0;
//             display: inline-block;
//             padding: 2px;
//             height: 3px;
//             width: 3px;
//             margin-top: 5px;
//         }

//         .rmdp-right i {
//             transform: rotate(-45deg);
//             -webkit-transform: rotate(-45deg);
//             margin-right: 3px;
//         }

//         .rmdp-left i {
//             transform: rotate(135deg);
//             -webkit-transform: rotate(135deg);
//             margin-left: 3px;
//         }

//         .rmdp-left {
//             left: 0;
//         }

//         .rmdp-right {
//             right: 0;
//         }

//         .rmdp-arrow-container {
//             cursor: pointer;
//             border-radius: 50%;
//             height: 20px;
//             width: 20px;
//             display: flex;
//             justify-content: center;
//             margin: 0 5px;
//         }

//         .rmdp-arrow-container:hover {
//             background-color: var(--rmdp-primary);
//             box-shadow: 0 0 3px var(--rmdp-shadow);
//         }

//         .rmdp-arrow-container:hover .rmdp-arrow {
//             border: solid white;
//             border-width: 0 2px 2px 0;
//         }

//         .rmdp-arrow-container.disabled {
//             cursor: default;
//         }

//         .rmdp-arrow-container.disabled:hover {
//             background-color: inherit;
//             box-shadow: inherit;
//         }

//         .rmdp-arrow-container.disabled .rmdp-arrow,
//         .rmdp-arrow-container.disabled:hover .rmdp-arrow {
//             border: solid gray;
//             border-width: 0 2px 2px 0;
//         }

//         .rmdp-rtl {
//             direction: rtl;
//         }

//         .rmdp-rtl .rmdp-left i {
//             transform: rotate(-45deg);
//             -webkit-transform: rotate(-45deg);
//             margin-left: 0;
//             margin-right: 3px;
//         }

//         .rmdp-rtl .rmdp-right i {
//             transform: rotate(135deg);
//             -webkit-transform: rotate(135deg);
//             margin-right: 0;
//             margin-left: 3px;
//         }

//         .rmdp-rtl .rmdp-right {
//             right: auto;
//             left: 0;
//         }

//         .rmdp-rtl .rmdp-left {
//             left: auto;
//             right: 0;
//         }

//         .rmdp-rtl .rmdp-range.start,
//         .rmdp-rtl .rmdp-range-hover.start {
//             border-top-left-radius: unset;
//             border-bottom-left-radius: unset;
//             border-top-right-radius: 50%;
//             border-bottom-right-radius: 50%;
//         }

//         .rmdp-rtl .rmdp-range.end,
//         .rmdp-rtl .rmdp-range-hover.end {
//             border-top-right-radius: unset;
//             border-bottom-right-radius: unset;
//             border-top-left-radius: 50%;
//             border-bottom-left-radius: 50%;
//         }

//         .rmdp-rtl .rmdp-range.start.end {
//             border-radius: 50%;
//         }

//         .rmdp-rtl .rmdp-ym .rmdp-range.start,
//         .rmdp-rtl .rmdp-ym .rmdp-range-hover.start {
//             border-top-right-radius: 15px;
//             border-bottom-right-radius: 15px;
//         }

//         .rmdp-rtl .rmdp-ym .rmdp-range.end,
//         .rmdp-rtl .rmdp-ym .rmdp-range-hover.end {
//             border-top-left-radius: 15px;
//             border-bottom-left-radius: 15px;
//         }

//         .rmdp-day-hidden,
//         .rmdp-day.rmdp-disabled {
//             cursor: default;
//         }

//         .rmdp-selected .highlight {
//             box-shadow: 0 0 3px var(--rmdp-shadow);
//         }

//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-red:hover {
//             background-color: var(--highlight-red-hover);
//         }

//         .rmdp-day:not(.rmdp-deactive) .highlight-red {
//             color: var(--highlight-red-color);
//         }

//         .rmdp-day.rmdp-deactive .highlight-red {
//             color: var(--highlight-red-color-deactive);
//         }

//         .rmdp-day.rmdp-selected .highlight-red {
//             background-color: var(--highlight-red-selected);
//             color: white;
//         }

//         .rmdp-day.rmdp-deactive.rmdp-selected .highlight-red {
//             background-color: var(--highlight-red-selected-deactive);
//             color: white;
//         }

//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-green:hover {
//             background-color: var(--highlight-green-hover);
//         }

//         .rmdp-day:not(.rmdp-deactive) .highlight-green {
//             color: var(--highlight-green-color);
//         }

//         .rmdp-day.rmdp-deactive .highlight-green {
//             color: var(--highlight-green-color-deactive);
//         }

//         .rmdp-day.rmdp-selected .highlight-green {
//             background-color: var(--highlight-green-selected);
//             color: white;
//         }

//         .rmdp-day.rmdp-deactive.rmdp-selected .highlight-green {
//             background-color: var(--highlight-green-selected-deactive);
//             color: white;
//         }

//         .rmdp-day-hidden:hover span,
//         .rmdp-day-hidden {
//             background-color: unset;
//             color: transparent;
//         }

//         .rmdp-month-name {
//             font-size: 14px;
//             margin: 3px 0;
//             cursor: default;
//         }

//         .rmdp-full-year {
//             grid-template-columns: 1fr 1fr 1fr;
//         }

//         @media (max-width: 450px),
//         (max-height: 450px) {

//             .rmdp-day,
//             .rmdp-week-day {
//                 height: 28px;
//                 width: 28px;
//             }

//             .rmdp-day span {
//                 padding-left: 0.5px;
//                 font-size: 12px;
//             }

//             .only.rmdp-year-picker,
//             .only.rmdp-month-picker {
//                 width: 205px;
//                 height: 200px;
//             }

//             .rmdp-header {
//                 padding: 3px 0 0 0;
//                 font-size: 12px;
//             }

//             .rmdp-month-name {
//                 font-size: 12px;
//             }

//             .rmdp-full-year {
//                 grid-template-columns: 1fr 1fr;
//             }
//         }
// }
// .rmdp-arrow {
//     border: solid #ffffff;
//     border-width: 0 2px 2px 0;
//     display: inline-block;
//     height: 3px;
//     margin-top: 5px;
//     padding: 2px;
//     width: 3px;
// }

// .modal-cal {
//     .rmdp-wrapper {
//         background-color: #2e2e2e !important;
//         box-shadow: none !important;
//         border: 1px solid #fff;
//     }

//     .rmdp-day.rmdp-today span {
//         background-color: #7fdbff00 !important;
//         color: rgb(0, 147, 255) !important
//     }

//     .rmdp-day.rmdp-today span.reserved {
//         background-color: #ff2929 !important;
//         color: #fff !important;
//     }

//     .rmdp-arrow {
//         border: solid #0074d9;
//         border-width: 0 2px 2px 0;
//         display: inline-block;
//         height: 3px;
//         margin-top: 5px;
//         padding: 2px;
//         width: 3px;
//     }
// }

// .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//     background-color: rgb(0, 87, 161) !important;
//     color: #fff;
// }

// .rmdp-day span {
//     border-radius: 2px !important;
//     bottom: 3px;
//     font-size: 14px;
//     left: 3px;
//     position: absolute;
//     right: 3px;
//     top: 3px;
// }

.cmmn-green-btn-2 {
    padding: 0.5rem 1rem;
    font-family: "GothamBold" !important;
    background-color: #149247 !important;
    border-radius: 10px !important;
    color: #fff !important;
    padding: 0.5rem 1rem !important;
}

.cmmn-green-btn-2:hover {
    background-color: #01682c !important;
}

.cmmn-cancel-btn {
    color: #fff !important;
    font-family: "GothamBold" !important;
}

.modal-cal-btn-grp {
    display: flex;
    align-items: center !important;
    gap: 1rem;
    justify-content: flex-end;
}

.main-radio-section.modal-radio-sec {
    margin: 1rem 0;
}

.am-pm {
    padding: 0px 8px !important;
    font-size: 10px !important;
    background-color: #fff !important;
    color: #2d2d2d !important;
}

.am-pm.active {
    background-color: #2196f3 !important;
    color: #fff !important;
}

.eqip-detail-div-list-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 18px !important;
    color: #fff !important;
}

.eqip-detail-div {
    width: 70%;
    margin: auto;
}

@media screen and (max-width:576px) {
    .eqip-detail-div {
        width: 100% !important;
    }
}

.eqip-detail-div-link {
    color: #faa61a !important;
    text-decoration-color: #faa61a !important;
}

.eqip-detail-div-head {
    font-weight: 600 !important;
    color: #fff !important;
    margin: 1rem 0 !important;
    font-size: 20px !important;
}

.image-dislike-icon {
    // position: absolute;
    // top: 50px;
    width: 30px;
    height: 30px;
    // right: 10px;
    cursor: pointer;
}

.image-like-icon img {
    width: 100%;
    height: 100%;
    display: block;
}

.image-dislike-icon img {
    width: 100%;
    height: 100%;
    display: block;
    transform: rotate(180deg);
}

.image-like-icon {
    // position: absolute;
    // top: 10px;
    width: 30px;
    height: 30px;
    // right: 10px;
    cursor: pointer;
}

.like-dislike-grp {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 10px;
    right: 10px;
}

// Extended rental section
// .rmdp-day .active-order {
//     background-color: #0da721 !important;
//     color: #ffffff;
//     border-radius: 2px !important;
// }

.active-order {
    cursor: not-allowed !important;
    pointer-events: none !important;
    background-color: #0da721 !important;
    color: #ffffff;
    border-radius: 2px !important;
}

.submitpayment-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .submitpayment-btn {
        padding: 0.5rem 1rem;
        width: max-content;
        background-color: #faa61a;
        color: #2d2d2d;
        font-family: 'GOTHAMBOLD' !important;
        font-size: 18px;

        &:hover {
            background-color: #d38302;
        }
    }
}

.er-grid {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.confirm-btns.extend-confirm-btn {
    background-color: #097f38;
    font-family: 'GothamBold' !important;
    padding: 0.5rem 2rem;
    color: #fff !important;
    font-size: 16px !important;
}

// .cfi-btn {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #0da721;
//     border: 2px solid #faa61a;
//     border-radius: 10px;
//     color: #fff !important;
//     &:hover {
//         background-color: #097f38;
//     }
// }

.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.cfi-btn {
    font-family: 'GothamBold' !important;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border: 2px solid #faa61a;
    border-radius: 9px;
    background-color: #097f38;
    color: #fff !important;
    margin: auto;
    text-transform: inherit !important;
    &:hover {
        background-color: #01682c;
    }
}

.eqp-price {
    background-color: #000;
    border-radius: 0;
    padding: 5px 10px;
    width: fit-content;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;

    p {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
        font-family: 'Gotham' !important;

        span {
            color: #fff;
            font-size: 20px;

            &:last-child {
                color: #faa61a;
                font-size: 15px;
            }
        }
    }
}

.new-eqp-d {
    border-bottom: 5px solid #faa61a;
    .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
        display: none;
    }
}

.proceed-btn.letsgo {
    text-transform: capitalize;
}

span.radio-span-white {
    white-space: pre-line;
}