.hw {
    width: 60%;
    margin: auto;
    padding: 0rem !important;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;

    @media (min-width: 2001px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 2000px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1800px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1700px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1600px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1500px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1400px) {
        width: 85% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1300px) {
        width: 100% !important;
    }

    @media (max-width: 1024px) {
        width: 100% !important;
    }
}

.htgs {
    border: 3px solid #faa61a;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
    margin: 4rem 0;

    .htgs-heading {
        font-size: 35px;
        font-family: 'GothamBold' !important;
        color: #fff;
        // margin-bottom: 0;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 27px !important;
        }
    }

    .htgs-btn {
        color: #fff;
        background-color: #149247;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        font-size: clamp(16px, 3vw ,20px);
        border-radius: 8px;
        font-family: 'Montserrat' !important;
        font-weight: 600;
        padding: 0.2rem 1rem;
        text-transform: capitalize;

        &:hover {
            background-color: #117e3c;
        }
    }

    .htgs-in {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .htgs-text {
        color: #fff;
        font-size: clamp(14px, 3vw, 20px);
        line-height:clamp(20px, 3vw, 26px);
        margin: 0;
        font-family: 'Montserrat' !important;
        font-weight: 300;

        .htgs-link {
            color: #faa61a !important;
            font-size: clamp(14px, 3vw, 20px);
            line-height: clamp(20px, 3vw, 26px);
            text-decoration-color: #faa61a !important;
            text-decoration: underline;
            text-underline-offset: 2px;
            font-weight: 600;
        }
    }

    .htgs-link {
        color: #faa61a !important;
        font-size: 18px;
        text-decoration-color: #faa61a !important;
    }
}

.arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .d-arrowimg {
        display: block;
        width: 140px;
        height: auto;
        object-fit: contain;

        @media (max-width: 992px) {
            display: none !important;
        }
    }

    .m-arrowimg {
        display: none;

        @media (max-width: 992px) {
            display: block !important;
            width: 100%;
            height: 110px;
            object-fit: contain;
        }

        @media (max-width: 768px) {
            height: 60px !important;
        }
    }
}

.mipt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin: 4rem 0;
    position: relative;

    @media (max-width: 992px) {
        gap: 5rem !important;
        grid-template-columns: 1fr !important;
    }

    .mipt-item {
        border-radius: 13px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background-color: #47463E;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);

        p.mipt-item-heading {
            color: #faa61a;
            font-size: clamp(20px, 3vw, 25px);
            font-family: 'GothamBold' !important;
            // height: 70px;
            text-align: center;
            margin: 0;
        }

        p.mipt-item-text {
            color: #ffffff;
            font-size: clamp(16px, 3vw, 19px);
            font-family: 'Montserrat' !important;
            text-align: center;
        }

        .mipt-item-img {
            margin-top: 0rem;

            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100px;

                @media (max-width: 768px) {
                    height: 70px !important;
                }
            }
        }
    }
}



.lgt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: #47463E;
    border-radius: 20px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);
    margin: 5rem 0;

    @media (max-width: 992px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;

        .lgt .lgt-content {
            text-align: center !important;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .lgt-content {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width: 768px) {
            text-align: center !important;
        }

        .lgt-heading {
            font-size: clamp(24px, 3vw, 36px) !important;
            font-family: 'GothamBold' !important;
            color: #faa61a;
            // text-transform: uppercase;
            margin: 0.5rem 0;
        }

        .lgt-text {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            margin: 0.5rem 0;
        }
    }

    .lgt-img {
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 300px;
            object-fit: contain;
        }
    }
}


.bac-top-main {
    margin-bottom: 1rem;

    p.bac-subheading {
        color: #fff;
        font-family: 'Montserrat' !important;
        font-weight: 300 !important;
        margin-top: 0.4rem;
        width: 90%;
        font-size: clamp(16px, 3vw, 18px) !important;

        @media (max-width: 768px) {
            font-size: 13px !important;
            margin: 0.4rem 0 0 0;
            width: 100% !important;
        }
    }

    .bac-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0rem 0;

        p.bac-heading {
            font-size: 45px;
            font-family: 'GothamBold' !important;
            color: #fff;
            margin: 0 !important;

            @media (max-width: 768px) {
                font-size: 24px !important;
            }

            span {
                color: #faa61a;
            }
        }



        .bac-btn {
            font-size: 16px !important;
            font-family: 'Montserrat' !important;
            font-weight: 700;
            color: #fff !important;
            background: #149247 !important;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            padding: 0.5rem 1rem !important;
            width: max-content;
            font-size: clamp(12px, 3vw, 20px) !important;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;


            @media (max-width: 992px) {
                border-radius: 8px !important;
                padding: 0.3rem 0.6rem !important;
                width: max-content;
            }

            @media (max-width: 576px) {
                border-radius: 8px !important;
                padding: 0.15rem 0.6rem !important;
                font-size: 13px !important;
                width: max-content;
                border-radius: 5px !important;
            }
        }
    }
}

.mw-head {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    margin: 2rem 0;

    .mw-heading {
        color: #faa61a;
        font-family: 'GothamBold' !important;
        font-size: 40px;
        margin-bottom: 0 !important;
        margin-top: 1rem;

        @media (max-width:768px) {
            font-size: 24px !important;
            margin-top: 0 !important;
            margin-bottom: 0.5rem !important;
        }
    }

    .mw-subheading {
        color: #ffffff;
        font-family: 'GothamBold' !important;
        font-size: 25px;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin: 0;

        @media (max-width:768px) {
            font-size: 18px !important;
            width: 70%;
            margin: auto;

            flex-direction: column !important;
        }

        span {
            color: #faa61a;
        }
    }

    .mw-text {
        color: #ffffff;
        font-family: 'Montserrat' !important;
        font-size: clamp(16px, 3vw, 20px);
        margin-top: 0.5rem;

        @media (max-width:768px) {
            font-size: 12px !important;
        }
    }
}

.mw-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;

    @media (max-width:768px) {
        grid-template-columns: 1fr !important;
    }



    .mw-gridL {
        padding: 4rem;

        @media (max-width:768px) {
            display: none !important;
        }

        img {
            display: block;
            object-fit: contain;
            width: 100%;
            height: auto;
        }
    }

    .mw-gridR {
        display: flex;
        flex-direction: column;
        gap: 3.5rem;
        padding: 1rem;

        @media (max-width: 576px) {
            padding: 0 !important;
            gap: 3rem !important;
            margin-top: 1rem !important;
        }

        .mw-item {
            background-color: #47463E;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            border-radius: 10px;
            padding: 2rem 1rem 1.5rem 1rem;
            position: relative;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
            cursor: pointer;

            &.active {
                box-shadow: 0px 0px 20px 0px #faa61aa8;
            }

            span {
                position: absolute;
                top: -7px;
                left: 50%;
                transform: translate(-50px, -7px);
                background-color: #149247;
                padding: 0.4rem 1rem;
                border-radius: 8px;
                color: #fff;
                font-family: 'GothamBold' !important;
                font-size: 20px;
                line-height: 22px;
                text-transform: uppercase;
            }

            .mw-item-heading {
                font-size: clamp(20px, 3vw, 26px);
                text-align: center;
                color: #faa61a;
                font-family: 'GothamBold' !important;
                margin: 0;
            }

            .mw-item-text {
                color: #fff;
                text-align: center;
                font-size: clamp(16px, 3vw, 18px);
                line-height: 25px;
                margin-top: 0rem;
                font-family: 'Montserrat' !important;
                font-weight: 300;
                margin-bottom: 0;
            }
        }
    }
}

.mw {
    margin-bottom: 0rem;
}