.bc {

  // .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  //   border-radius: 0px !important;
  // }

  // ul.MuiList-root.MuiList-padding.MuiMenu-list {
  //     padding: 0 !important;

  //     li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  //       background-color: #47463E;
  //       &:hover {
  //         background-color: #47463E;
  //       }
  //     }
  // }

  .expandIconWrapper,
  .collapsIconWrapper {
    margin-top: 4px;

    vertical-align: middle;
  }

  .expandIconWrapper svg,
  .collapsIconWrapper svg {
    fill: #faa61a;
  }

  .MuiAccordionSummary-content {
    flex-grow: inherit;
}

  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    padding: 0;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0;
    display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  }

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: unset !important;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
    background: transparent !important;
    box-shadow: none !important;
    color: #fff;
    border-bottom: 0px solid #ffffff00;
    border-radius: 0px !important;
    font-family: 'Montserrat' !important;

  }

  .MuiCollapse-wrapperInner.MuiCollapse-vertical {
    background: #47463E;
    border-radius: 10px;
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.plus-icon {
    color: #faa61a !important;
  }

  .share-icon {
    width: 24px;
    height: auto;
    display: block;
    @media (max-width: 1200px) {
      width: 18px !important;
    }
    @media (max-width: 900px) {
      width: 16px !important;
    }

    @media (max-width: 600px) {
      width: 13px !important;
    }
  }

  .my-crew {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1.5rem;

    // display: grid;
    // grid-template-columns: 1fr 4fr;

    form {
      // display: flex;
      // align-items: flex-end;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 5px;
      gap: 2rem;
      width: 100%;

      display: grid;
      grid-template-columns: auto 1fr 1fr;
      // width: 60%;

      @media (max-width: 992px) {
        grid-template-columns: auto 1fr !important;
        gap: 1rem !important;
        align-items: center !important;

        display: flex !important;
        flex-wrap: wrap !important;
      }

      .checbox-grp {
        display: grid;
        grid-template-columns: auto auto;
        gap: 2rem;

        @media (max-width: 992px) {
          gap: 1rem !important;
        }
      }

      .search-my-crew-input {
        @media (max-width: 900px) {
          width: 100% !important;
        }
      }
    }
  }

  .form-group {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    label {
      position: relative;
      cursor: pointer;
      font-size: 20px;
      font-family: "Montserrat" !important;
      font-weight: 600;
      color: #fff !important;

      @media (max-width: 900px) {
        font-size: 16px !important;
      }

      @media (max-width: 600px) {
        font-size: 14px !important;
      }

      span {
        color: #faa61a !important;
      }
    }
  }

  .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: #2d2d2d;
    border: 2px solid #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;

    @media (max-width: 600px) {
      width: 8px !important;
      height: 8px !important;
      padding: 4px !important;
    }
  }

  .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #faa61a;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    @media (max-width: 600px) {
      top: 3px !important;
      left: 8px !important;
      width: 4px !important;
      height: 8px !important;
    }
  }

  .bc-thead-row {
    background-color: #2e2e2e;
    border-bottom: 4px solid #faa61a !important;
  }

  .bc-thead {
    border: none;
    padding: 15px 5px !important;
    font-size: 20px;

    @media (max-width: 900px) {
      font-size: 16px !important;
    }

    @media (max-width: 600px) {
      font-size: 12px !important;
    }

    color: #fff;

    font-family: "Montserrat" !important;
    font-weight: 600;

    @media (max-width: 576px) {
      font-size: 14px !important;
    }
  }

  td.MuiTableCell-root.MuiTableCell-footer {
    border: none;
    padding: 15px 5px !important;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;

    font-family: "Montserrat" !important;
    font-weight: 500;
  }

  .bc-cell {
    border: none;
    padding: 14px 5px !important;
    font-size: 18px;
    display: flex;
    gap: 5px;
    z-index: 100;

    @media (max-width: 900px) {
      font-size: 15px !important;
    }

    @media (max-width: 600px) {
      font-size: 12px !important;
    }

    color: #fff;

    font-family: "Montserrat" !important;
    font-weight: 500;

    .bc-cell-span {
      color: #faa61a !important;
    }
  }

  .bc-cell.disabled-cell {
    color: gray;

    .bc-cell-span {
      color: gray;
    }

    svg.expand-icons {
      color: #faa61a !important;
    }
  }

  .msg-cell {
    padding: 1.5rem 1rem !important;
    // text-align: left;
  }

  svg.expand-icons {
    color: #faa61a;
    font-size: 16px;

    @media (max-width: 900px) {
      font-size: 14px !important;
      padding-left: 5px;
    }

    @media (max-width: 600px) {
      font-size: 12px !important;
      padding-left: 5px;
    }
  }

  .arrow-icon {
    width: 20px;
    height: 20px;
    display: block;
    object-fit: contain;

    @media (max-width: 900px) {
      width: 16px !important;
      height: 16px !important;
    }

    @media (max-width: 600px) {
      width: 14px !important;
      height: 14px !important;
    }
  }

  .learnmore {
    color: #faa61a !important;
    text-decoration-color: #faa61a !important;
  }
}

@media only screen and (max-width: 768px) {
  .react-pdf__Page {
    width: 100%;
  }
}

input#refereeal-code {
  text-align: center;
}

.billing-zipcode {
  
  .MuiInputBase-input.MuiOutlinedInput-input {
font-family: 'Gotham' !important;
  font-size: 16px;
  }
}

.popup-input-strp.cc {
  font-family: 'Gothma' !important;
}

.popup-input-strp-2.cc {
font-family: 'Gothma' !important;
}

.credit-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  form {
    margin-top: 1rem !important;
}
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #0b0b0b !important;
  }
}