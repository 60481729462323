.welcome-container {
    display: flex;
    flex-direction: row;
    height: 100% !important;
    margin-bottom: 2rem;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    column-gap: 5rem;
    margin-top: 3.5rem;
    height: 100% !important;
}

.form-container-inner {
    padding: 2rem 1rem !important;
}

.form-container-inner .last-form {
    overflow-y: scroll;
    max-height: auto !important;
}

.form-container-last {
    border: none !important;
    background: none !important;
}

.step-one-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.thank-you-box {
    display: flex !important;
    justify-content: center !important;
}

.thankyou-inner {
    width: 100% !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    padding: 1rem !important;
}

.thankyou-inner .img-thank-you {
    width: 75%;
    height: auto;
    display: block;
}

.welcome-to {
    width: 75%;
}

.go-lending {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 75%;
}

#evolution_text {
    color: white;
    font-size: 28px;
    border-top: 3px solid #faa61a;
    border-bottom: 3px solid #faa61a;
    font-weight: 700;
}

#financing_text {
    color: white;
    font-size: clamp(16px, 3vw, 27px);
    width: 100%;
    margin: 30px auto;
}

.financing-list {
    margin: auto;
    width: fit-content;
    color: #faa61a;
    font-weight: 700;
    font-size: x-large;
}

.financing-list li {
    width: fit-content;
}

.financing-list li::marker {
    color: white;
}

#btn_start {
    margin-top: 20px;
    padding: 0 30px;
    border-radius: 10px;
    height: 50px;
    background-color: green;
    text-transform: capitalize;
    width: 200px;
    border: none !important;
    outline: none !important;
}

.btn_start {
    font-weight: 400 !important;
    font-size: clamp(1.5rem, 3vw, 2rem);
    outline: none !important;
}

#btn_start:hover {
    background-color: green;
    outline: none !important;
}

#btn_start.deactive {
    background: gray;
    outline: none !important;
    border: none !important;
}

#btn_start span {
    font-size: 40px;
    color: #faa61a;
    padding: 0 10px;
}

#btn_start p {
    font-size: 30px;
    font-weight: 900;
    color: white;
}

.form-container {
    width: 100% !important;
    border: 5px solid #faa61a;
    border-radius: 25px;
    background-color: white;
    /* height: 500px !important; */
}

.equip-lending-form {
    display: flex;
    width: 70% !important;
    padding: 1rem;
    justify-content: center !important;
    column-gap: 4rem;
}

.welcome-form {
    display: flex;
    justify-content: center !important;
    width: 50% !important;
}

.form-title-wrapper {
    display: flex;
    align-items: center;
}

.step-bar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.form-input {
    margin-top: 50px;
}

.form-input fieldset {
    border-color: #faa61a !important;
    border-width: 3px !important;
}

.next-btn-container {
    margin: 1rem 0 !important;
}

.step-bar {
    display: flex;
    /* width: 180px; */
    height: 7px;
    background-color: #e6e7e8;
    cursor: pointer;
}

.step-bar.active {
    background-color: #faa61a;
}

.next-btn-container {
    text-align: center;
}

#form_title {
    color: #faa61a;
    font-weight: 900;
    width: auto;
    margin-left: -10px !important;
    font-size: clamp(1.45rem, 3vw, 2.7rem) !important;
}

#form_title.form_title_last {
    margin-left: 0px !important;
}

.form-title-wrapper-img img {
    width: 70%;
    height: auto;
}

img.web-landing {
    width: 480px;
}

.left-container {
    text-align: center;
}

.span-number {
    padding: 3px 10px;
    background: green;
    border-radius: 50%;
    color: white;
    border: 1px solid #faa61a;
    height: 22px;
    margin-top: 8px;
    margin-right: 8px;
}

.equipment-group {
    display: flex;
}

.equipment-type {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #a7a8ac;
}

.img-thank-you {
    width: 75%;
    height: auto;
    display: block;
}

#loan_type {
    margin-top: -5px;
}


.thankyou {
    width: 40% !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
}


@media screen and (max-width: 1400px) {
    .img-thank-you {
        width: 50% !important;
    }


}

@media screen and (max-width:1200px) {
    .equip-lending-form {
        width: 85% !important;
        column-gap: 2rem;
    }

    #form_title {
        font-size: clamp(1.45rem, 3vw, 2.2rem) !important;
    }

    .img-thank-you {
        width: 75% !important;
    }
}

@media screen and (max-width: 1024px) {
    .equip-lending-form {
        width: 100% !important;
        column-gap: 1rem;
    }

    .img-thank-you {
        width: 65% !important;
    }

    p#financing_text {
        font-size: clamp(16px, 3vw, 27px);
        max-width: 100%;
        margin: 15px auto;
        line-height: 1.2;
    }

    .form-title-wrapper {
        display: flex;
        align-items: center;
    }

    .step-bar {
        display: flex;
        /* width: 100px; */
        height: 7px;
        background-color: #e6e7e8;
        cursor: pointer;
    }

    .step-bar.active {
        background-color: #faa61a;
    }

    .form-input {
        margin-top: 20px;
    }

    .form-input fieldset {
        border-color: #faa61a !important;
        border-width: 3px !important;
    }

    .next-btn-container {
        margin: 1rem 0 !important;
    }

    .thankyou {
        width: 100% !important;
    }

    .img-thank-you {
        width: 50% !important;
    }
}

@media screen and (max-width: 900px) {
    .mobile-hidden {
        display: none;
    }

    .welcome-form {
        width: 100% !important;
    }

    .img-thank-you {
        width: 75% !important;
    }

    img.go-lending {
        max-width: calc(100% - 40px);
    }

    p#evolution_text {
        font-size: 17px;
    }

    ul.financing-list {
        font-size: 18px;
    }

    #btn_start {
        margin-top: 20px;
        padding: 0 30px;
        border-radius: 10px;
        height: 50px;
        background-color: green;
        text-transform: capitalize;
        /* width: 100px; */
    }

    #btn_start span {
        font-size: 20px;
    }

    button#btn_start p {
        font-size: 20px;
    }

    button#btn_start {
        padding: 0 10px;
    }

    #form_title {
        font-size: 23px;
    }

    .form-title-wrapper #form_title {
        font-size: 30px;
        margin-left: 10px;
    }

    .step-bar {
        flex: 1;
        margin-right: 10px;
    }

    .step-bar:last-child {
        margin-right: 0px;
    }

    .form-title-wrapper {
        display: flex;
        align-items: center;
    }

    .step-bar {
        display: flex;
        /* width: 100px; */
        height: 7px;
        background-color: #e6e7e8;
        cursor: pointer;
    }

    .step-bar.active {
        background-color: #faa61a;
    }

    .next-btn-container {
        margin: 1rem 0 !important;
    }

    .form-input {
        margin-top: 20px;
    }

    .form-input fieldset {
        border-color: #faa61a !important;
        border-width: 3px !important;
    }

    .thankyou {
        width: 100% !important;
    }

    .img-thank-you {
        width: 75% !important;
    }
}

@media screen and (max-width: 600px) {
    .mobile-hidden {
        display: none;
    }

    .equip-lending-form {
        width: 95% !important;
        column-gap: 1rem;
        flex-direction: column;
        margin: 0rem !important;
    }

    .welcome-form {
        width: 100% !important;
    }

    .thankyou {
        width: 100% !important;
    }

    .img-thank-you {
        width: 90% !important;
    }
}

@media screen and (max-width: 320px) {
    .mobile-hidden {
        display: none;
    }

    .equip-lending-form {
        width: 90% !important;
        column-gap: 1rem;
        flex-direction: column;
        margin: 0rem !important;
    }

    .welcome-form {
        width: 100% !important;
    }

    .thankyou {
        width: 100% !important;
    }

    .img-thank-you {
        width: 100% !important;
    }
}

@media (prefers-color-scheme: dark) {
    .layout-container {
        background-color: #2E2E2E !important;
    }
    .download-yellow-text {
        color: #FAA61A;
    }
    .download-popup {
        border: 5px solid #FAA61A !important;
    }
}

.lending-input-box {
    display: flex;
    align-items: flex-end;
}

.loan-box {
    .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
            color: #2d2d2d !important;
        }
}

#add_equipment {
    .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
        color: #2d2d2d !important;
    }
}