.profile {
    width: 55%;
    margin: auto;
    margin: 1rem auto;

    .profile-main {
        .profile-inner {
            .profile-heading {
                margin: 0rem 0rem 2rem 0;
                p {
                    color: #fff;
                    font-size: clamp(35px, 3vw, 50px) !important;
                    font-family: 'GothamBold' !important;
                    text-align: center;
                }
            }

            .user-pimg {
                position: relative;
                    width: 150px;
                    height: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    flex-direction: column;
                    gap: 1rem;
                

                .user-p-img {
                    width: 120px !important;
                    height: 120px;
                    max-height: 120px;
                    display: block;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 2px solid #faa61a;
                }

                .camera-icon {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    width: 23px;
                    height: 23px;
                    background-color: #fff;
                    border-radius: 50%;
                    border: 1px solid #faa61a;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    background-image: url('../../assets/images/Scamera.png');
                    background-size: 50%;
                    background-position: center;
                    background-repeat: no-repeat;

                    input {
                        opacity: 0 !important;
                        cursor: pointer;
                    }


                }

                                .profile-change-photo-link {
                                    color: #faa61a;
                                    text-decoration-color: #faa61a;
                                }
            }

            .user-name {
                margin: 1rem 0rem;
                // text-align: center;

                p {
                    color: #fff;
                    font-size: clamp(18px, 3vw, 30px);
                    font-family: 'GothamBold' !important;
                }
            }

            .user-address {
                margin: 1rem 0rem;
                    position: relative;
                    display: grid;
                    grid-template-columns: 1.5fr 1fr;
                    align-items: flex-end;

                p {
                    color: #ffffff;
                    font-size: clamp(16px, 3vw, 18px);
                }

                .edit-icon {
                    position: absolute;
                    top: -15px;
                    right: -25px;
                    width: 20px;
                    height: 20px;
                    background-color: #ffffff62;
                    border-radius: 50%;
                    border: 1px solid #faa61a;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    img {
                        display: block;
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            .user-detail {
                display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 0;
                    margin: 0.5rem 0;
                    align-items: flex-end;

                .user-detail-item {
                    p {
                        &:first-child {
                            color: #fff;
                            font-size: clamp(16px, 3vw, 20px);
                        }

                        &:last-child {
                            color: #fff;
                            font-size: clamp(16px, 3vw, 20px);
                        }
                    }
                }

                // .change-link {
                //     text-align: right;
                //     text-decoration: none;
                //     color: #faa61a;
                //     font-size: clamp(16px, 3vw, 20px);
                //     cursor: pointer;
                // }

                .ddl {
                    color: #fff;
                    font-size: clamp(16px, 3vw, 20px);
                    font-family: 'GothamBold' !important;
                }

                .change-image {
                    // background-color: #faa61a;
                    // width: 300px;
                    // width: 250px;
                    // height: 150px;
                    // max-height: 250px;
                    position: relative;
                    border: 1px solid #8b8b8b;

                    img {
                        width: 100%;
                        max-height: 100%;
                        display: block;
                        object-fit: contain;
                        object-position: center;
                    }

                    .camera-icon {
                        position: absolute;
                        top: -15px;
                        right: -15px;
                        width: 23px;
                        height: 23px;
                        background-color: #fff;
                        border-radius: 50%;
                        border: 1px solid #faa61a;
                        padding: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        background-image: url('../../assets/images/Scamera.png');
                        background-size: 50%;
                        background-position: center;
                        background-repeat: no-repeat;

                        input {
                            opacity: 0 !important;
                            cursor: pointer;
                        }


                    }
                }
            }
        }
    }
}

.account-setting {
    // width: 40%;
    margin: 1rem auto;
}

.setting-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    align-items: center;
    margin: 1rem 0;

    .labels {
        color: #fff;
        font-size: clamp(14px, 3vw, 18px);
        font-family: 'GothamBold' !important;
    }

    .switchbox {

        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

}

.profile-heading {
    p {
        color: #fff;
        font-size: clamp(18px, 3vw, 30px);
        font-family: 'GothamBold' !important;
    }
}



.session-expire.address-update-popup {
    padding: 1rem 2rem !important;

    .testttabc {
        padding: 1rem !important;
    }

    .pop-up-btn-grps {
        padding: 0 1rem 1rem 1rem !important;

        .btn-load-more {
            width: auto !important;
            background: #faa61a;
            color: #2d2d2d !important;
            border: none !important;
            font-weight: 600;
        }
    }

}

.session-expire.pwd-change-popup {
    padding: 1rem 2rem !important;

    .testttabc {
        padding: 0 1rem 1rem 1rem !important;
    }

    .input-grid.search-address-field {
        margin: 0.8rem 0;
    }

    .pop-up-btn-grps {
        padding: 0 1rem 1rem 1rem !important;

        .btn-load-more {
            width: auto !important;
            background: #faa61a;
            color: #2d2d2d !important;
            border: none !important;
            font-weight: 600;
        }
    }
}

.session-expire.phone-update-popup {
    padding: 1rem !important;

    .testttabc {
        padding: 1rem !important;
    }

    .input-grid.search-address-field {
        margin: 0.8rem 0 !important;
    }

    .pop-up-btn-grps {
        padding: 0 1rem 1rem 1rem !important;

        .btn-load-more {
            width: auto !important;
            background: #faa61a;
            color: #2d2d2d !important;
            border: none !important;
            font-weight: 600;
        }
    }
}

.btn-load-more.update-btn {
    width: fit-content;
}

h2#draggable-dialog-title {
    padding-bottom: 0;
}


@media screen and (max-width:1200px) {
    .profile {
        width: 70% !important;
    }

    // .account-setting {
        // width: 70% !important;
    // }

    .mywallet-btn-grps {
        padding: 0 !important;
        grid-gap: 3rem !important;
    }
}

@media screen and (max-width: 1024px) {
    .rentyrd-grid.wallet-grid {
            display: grid !important;
            grid-template-columns: 2fr 1fr !important;
            grid-gap: 1rem !important;
        }
}

@media screen and (max-width:992px) {
    .profile-info-grid {
            display: flex !important;
            flex-direction: column;
            // grid-template-columns: 1fr !important;
            // justify-content: center;
            // gap: 1rem;
    }
    .profile {
        width: 80% !important;
    }

    .account-setting {
        width: 100% !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right {
        position: relative !important;
        top: 0rem !important;
    }
}


@media screen and (max-width:768px) {
    .profile {
        width: 90% !important;
    }

    .account-setting {
        width: 100% !important;
    }

        .cross-btn {
            top: -17px !important;
            right: -17px !important;
        }
}

@media screen and (max-width:567px)  {
    .profile .profile-main .profile-inner .user-detail .change-image {
        width: 180px;
        height: 110px;
        max-height: 180px;
        position: relative;
        // border: 1px solid white;
        img {
            object-fit: contain;
            // object-fit: cover;
        }
    }
}



// ----------mywallet------------//


.mywallet-card-grid {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card {
    padding: 1rem 1rem 0.5rem 1rem;
}

.rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card:hover {
    padding: 1rem 1rem 0.5rem 1rem;
}

.mywallet-status {
    color: #fff;
    font-size: clamp(20px, 3vw, 32px) !important;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding: 0 1rem;
    font-family: 'GothamBold' !important;
}

.status-span.complete-status {
    border-radius: 10px;
    border: 2px solid #faa61a;
    background-color: #149247;
    font-size: 14px;
    padding: 0.1rem 0.5rem;
    text-transform: uppercase;
}

.status-span.gps-payment {
    border-radius: 10px;
    border: 2px solid #faa61a;
    background-color: #D32F2F;
    font-size: 14px;
    padding: 0.1rem 0.5rem;
    text-transform: uppercase;
}

.status-span.pending-status {
    border-radius: 10px;
    background-color: #faa61a;
    color: #2e2e2e;
    border: 2px solid #fff;
    // font-family: 'Gotham';
    font-size: 14px;
    padding: 0.1rem 0.5rem;
    text-transform: uppercase;
}

.mywallet-trans {
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 1rem !important;
    font-size: clamp(16px, 3vw, 18px) !important;
}

.mywallet-btn-grps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    align-items: center;
    padding: 0rem 3rem;

    .edit-listing-btn.deposit-btn {
        padding: 4rem 0 !important;
        background-color: #faa81a00 !important;
        // border: 3px solid #fff;
        border-radius: 15px !important;
        font-family: 'Gotham';
        cursor: pointer;

        img {
            display: block;
            width: 100%;
        }
    }

    .edit-listing-btn.withdrawal-btn {
        padding: 4rem 0 !important;
        background-color: #14924600 !important;
        // border: 3px solid #fff;
        border-radius: 15px !important;
        font-family: 'Gotham';
        font-weight: 700;
        cursor: pointer;

        img {
            display: block;
            width: 100%;
        }
    }
}

.mywallet-fees {
    color: #fff;
    font-size: clamp(16px, 3vw, 18px) !important;
}

.pending-balance {
    color: gray !important;
}


.wallet-heading {
    text-align: center;

    p {
        color: #fff;
        font-size: clamp(30px, 3vw, 50px) !important;
        font-family: 'GothamBold' !important;

    }

}


.total-bal-p {
    display: flex;
    flex-direction: column;

    .total-balance-text {
        color: #faa61a;
    }

    .total-balance-amt {
        font-size: clamp(30px, 3vw, 60px) !important;
    }
}

.equipment-details-heading.wallet-amt-div {
    border-bottom: none !important;
}



.wallet-trnsc-history {
    font-size: clamp(30px, 3vw, 50px) !important;
    color: #fff;
    font-family: 'GothamBold';
    text-align: center;
    // padding-bottom: 1rem;
}

.gray-line-2 {
    height: 6px;
    background-color: gray;
    width: 95%;
    border: none;
    margin: 1.5rem auto;
}

.mywallet-details-box {
    // background-image: url('../../assets/images/banner_img.png');
    padding: 0.8rem 1rem;
}

.rentyrd-card.wallet-card {
    padding: 0 !important;
    border: none !important;

    &:hover {
        border: none !important;
        box-shadow: none !important;
    }
}


.cross-btn {
    position: absolute !important;
    top: -17px !important;
    right: -17px !important;
    background-color: #faa61a !important;
    color: #fff !important;
    z-index: 1301;
}

.addmoney-modal-title.modal-title {
    padding: 0;
}

.addmoney-modal-content.modal-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.reset-cal-btn.addmoney--checkout-btn.new-money-btn {
    background-color: #149247;
    font-family: 'Gotham';
}

.profile-info-grid {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    gap: 1rem;
}

.change-link {
    text-align: right;
    color: #faa61a !important;
    text-decoration-color: #faa61a !important;
    font-size: clamp(16px, 3vw, 18px);
    cursor: pointer;
    font-family: 'Gotham';
    position: relative;
}

.profile-info-label {
    color: #faa61a;
    font-size: clamp(18px, 3vw, 24px);
    font-family: 'GothamBold' !important;
}

.profile-edit-icon {
    height: 24px;
    width: 24px;
}

.license-image-new {
    // width: 500px;
    // width: 80%;
    width: 100%;
    height: 300px;
    max-height: 300px !important;
    border: 4px solid #faa61a;
    aspect-ratio: 16/9;
    object-fit: contain;
    display: block;
}

.user-detail-div {
    margin: 2rem 0;
}

.user-detail.lic-change {
    align-items: center !important;
}


.addmoney-modal-textfields-grid {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.switch-off-color {
    color: gray;
}

.switch-on-color {
    color: #149247;
}

.addmoney-modal-textfields-grid.payment-card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}

.reset-cal-btn.addmoney--checkout-btn.new-money-btn.transfer-btn {
    font-size: clamp(1em, 3vw, 1.2em);
    background-color: #169245;
    font-family: "GothamBold" !important;
    margin: 1rem 0;
    border-radius: 10px !important;
}

.addmoney-modal-content.modal-content.wallet-modal-content {
    display: flex;
    flex-direction: column;
}

.addmoney-modal-content.modal-content.wallet-modal-content {
    .MuiFormControl-root.MuiTextField-root.popup-input {
            background: #fff;
            border: 2px solid #faa61a;
            border-radius: 10px;
            color: #ffffff;
            margin: 0.4rem 0;
        }
}

// .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
//     color: #505050 !important;
// }

.profilewarning {
    position: absolute;
    top: -20px;
    right: -12px;
    width: 80px !important;
    height: 80px !important;
}

.addresswarning {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translate(-50px,-50%);
    width: 80px;
    height: 80px;

    @media (max-width:600px) {
        left: -10px !important;
    }
}

.dlwarning {
    position: absolute;
    top: -40px;
    right: -45px;
    width: 80px;
    height: 80px;
}

.change-image {
    // background-color: #faa61a;
    // width: 300px;
    // width: 250px;
    // height: 150px;
    // max-height: 250px;
    position: relative;
    // border: 5px solid red;
    width: 80%;
}



// Flip CSS
.flip-card {
    perspective: 1000px;
    // width: 300px;
    width: 100%;
    height: 300px;
    border: 4px solid #faa61a;
    aspect-ratio: 16/9;
    
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  