/* REACT MULTI DATE PICKER CALENDAR CSS starts */
:root {
  --rmdp-primary: #faa61a;
  --rmdp-secondary: #4ca6f5;
  --rmdp-shadow: #8798ad;
  --rmdp-today: #149247;
  /* --rmdp-hover: #ca8001; */
  --rmdp-hover: skyblue;
  --rmdp-border: #cfd8e2;
  --highlight-red-color: #cc0303;
  --highlight-red-color-deactive: #e08e8e;
  --highlight-red-selected: #ea0034;
  --highlight-red-selected-deactive: #e4b0ba;
  --highlight-red-hover: #ff6687;
  --highlight-green-color: #00796b;
  --highlight-green-color-deactive: #7ab3ac;
  --highlight-green-selected: #009688;
  --highlight-green-selected-deactive: #749c98;
  --highlight-green-hover: #4db6ac;
}

.rmdp-wrapper {
  width: max-content;
  text-align: center;
  /* background-color: #2d2d2d !important; */
  background: transparent !important;
  border-radius: 5px;
  direction: ltr;
  height: 427px;
}

@media (max-width: 576px) {
  .rmdp-wrapper {
    height: 380px !important;
  }
}

.rmdp-shadow {
  /* box-shadow: 0 0 5px var(--rmdp-shadow); */
  box-shadow: none !important;
}

.rmdp-border {
  border: 1px solid var(--rmdp-border);
}

.rmdp-calendar {
  /* padding: 4px; */
  padding: 0 !important;
  height: max-content;
}

.rmdp-border-top {
  border-top: 1px solid var(--rmdp-border);
}

.rmdp-border-bottom {
  border-bottom: 1px solid var(--rmdp-border);
}

.rmdp-border-left {
  border-left: 1px solid var(--rmdp-border);
}

.rmdp-border-right {
  border-right: 1px solid var(--rmdp-border);
}

.rmdp-week,
.rmdp-ym {
  display: flex;
  justify-content: space-between;
}

.rmdp-ym {
  height: 25%;
}

.rmdp-day,
.rmdp-week-day {
  /* height: 34px; */
  /* width: 34px; */
  width: 50px !important;
  height: 50px !important;
  font-family: Montserrat !important;
  cursor: pointer;
  position: relative;
  /* color: black; */
  color: #fff;
  font-weight: 400;
  border: 1px solid #3a3a3a;
  overflow: hidden;
}

@media (max-width: 420px) {
  .rmdp-day,
  .rmdp-week-day {
    /* height: 34px; */
    /* width: 34px; */
    width: 38px !important;
    height: 38px !important;
  }
}

.rmdp-calendar *:focus {
  outline-color: #00539c;
}

.rmdp-day:not(.rmdp-range):focus {
  /* border-radius: 50%; */
  border-radius: 0 !important;
}

.rmdp-ym .rmdp-day:not(.rmdp-range):focus {
  /* border-radius: 15px; */
  border-radius: 0 !important;
}

.rmdp-week-day {
  cursor: default;
  /* color: var(--rmdp-primary); */
  color: #fff !important;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.rmdp-day span {
  /* position: absolute;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important; */
  /* border-radius: 50%; */
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
  bottom: unset !important;
  left: unset !important;
  top: unset !important;
  right: unset !important;
  font-size: 14px;
  overflow: hidden;
  /* color: #fff; */
  z-index: 1000;
}

.rmdp-day.rmdp-today span {
  /* background-color: transparent !important; */
  /* background-color: var(--rmdp-today) !important; */
  background-color: transparent !important;
  /* background-color: skyblue !important; */
  color: #faa61a !important;
}

.rmdp-day.rmdp-today span:hover {
  color: #fff !important;
}

.rmdp-day span[data-status*="full-booked"] {
  background-color: #faa61a !important;
  color: #fff !important;
}

.rmdp-day span[data-status*="full-pending"] {
  background-color: skyblue !important;
  color: #fff !important;
}

.rmdp-day.rmdp-today span[data-status*="full-booked"] {
  background-color: #faa61a !important;
  color: #fff !important;
}

.rmdp-day.rmdp-today span[data-status*="full-pending"] {
  background-color: skyblue !important;
  color: #fff !important;
}

.rmdp-day.rmdp-today span[data-status*="partial"] {
  /* background-color: skyblue!important; */
  color: #fff !important;
}

.rmdp-day.rmdp-today.custom-today span {
  color: #fff !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  /* background-color: var(--rmdp-primary) !important; */
  background-color: red !important;
  /* box-shadow: 0 0 3px var(--rmdp-shadow); */
  box-shadow: none !important;
  color: white;
}

.rmdp-day.rmdp-deactive,
.rmdp-day.rmdp-disabled {
  /* color: var(--rmdp-shadow); */
  color: rgb(112, 112, 112) !important;
  cursor: not-allowed !important;
}

.rmdp-day.rmdp-deactive.rmdp-selected span {
  background-color: var(--rmdp-secondary);
  /* box-shadow: 0 0 3px #bac5d3; */
  box-shadow: none !important;
}

.rmdp-ym .rmdp-day {
  flex: 1;
  margin: auto;
}

.rmdp-ym .rmdp-day span {
  border-radius: 12px;
  padding: 2px 0px;
}

.rmdp-range {
  color: white;
  /* background-color: var(--rmdp-primary) !important; */
  background-color: skyblue !important;
  /* box-shadow: 0 0 3px var(--rmdp-shadow); */
  box-shadow: none !important;
}

.rmdp-range-hover {
  background-color: var(--rmdp-hover) !important;
  color: white;
}

.rmdp-range.start:not(.force),
.rmdp-range-hover.start:not(.force) {
  /* border-top-left-radius: 50%; */
  /* border-bottom-left-radius: 50%; */
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rmdp-range.end:not(.force),
.rmdp-range-hover.end:not(.force) {
  /* border-top-right-radius: 50%; */
  /* border-bottom-right-radius: 50%; */
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rmdp-ym .rmdp-range.start:not(.force),
.rmdp-ym .rmdp-range-hover.start:not(.force) {
  /* border-top-left-radius: 15px; */
  /* border-bottom-left-radius: 15px;  */
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rmdp-ym .rmdp-range.end:not(.force),
.rmdp-ym .rmdp-range-hover.end:not(.force) {
  /* border-top-right-radius: 15px; */
  /* border-bottom-right-radius: 15px; */
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@media (hover: hover) {
  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: var(--rmdp-hover) !important;
    /* background-color: transparent !important; */
    /* border:2px solid #faa61a; */
    color: white;
  }
}

.rmdp-day-picker {
  /* padding: 5px; */
  padding: 0px !important;
}

.rmdp-header {
  margin-top: 5px;
  font-size: 14px;
  padding: 9px 0;
}

.rmdp-month-picker,
.rmdp-year-picker {
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 0 0 5px 5px;
}

.only.rmdp-month-picker,
.only.rmdp-year-picker {
  position: static;
  height: 240px;
  width: 240px;
}

.rmdp-header-values {
  color: #fff !important;
  font-family: Montserrat !important;
  font-weight: 600;
  font-size: clamp(16px, 3vw, 18px);
  margin: auto;
}

.rmdp-header-values span {
  padding: 0 5px;
  padding-right: 0;
}

.rmdp-arrow {
  /* border: solid var(--rmdp-primary); */
  border-color: #fff !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block;
  padding: 4px !important;
  height: 3px;
  width: 3px;
  margin-top: 0px !important;
}

.rmdp-right i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-right: 3px;
}

.rmdp-left i {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  margin-left: 3px;
}

.rmdp-left {
  left: 0;
}

.rmdp-right {
  right: 0;
}

.rmdp-arrow-container {
  cursor: pointer;
  /* border-radius: 50%; */
  border-radius: 0 !important;
  /* height: 20px; */
  /* width: 20px; */
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 5px;
  background: transparent;
  border: none;
  padding: 0;
}

.rmdp-arrow-container:hover {
  /* background-color: var(--rmdp-primary) !important; */
  background-color: transparent !important;
  /* box-shadow: 0 0 3px var(--rmdp-shadow); */
  box-shadow: none !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #faa61a !important;
  border-width: 0 3px 3px 0 !important;
}

.rmdp-arrow-container.disabled {
  cursor: default;
}

.rmdp-arrow-container.disabled:hover {
  background-color: inherit;
  box-shadow: inherit;
}

.rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-arrow-container.disabled:hover .rmdp-arrow {
  border: solid gray !important;
  border-width: 0 3px 3px 0 !important;
}

.rmdp-rtl {
  direction: rtl;
}

.rmdp-rtl .rmdp-left i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-left: 0;
  margin-right: 3px;
}

.rmdp-rtl .rmdp-right i {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  margin-right: 0;
  margin-left: 3px;
}

.rmdp-rtl .rmdp-right {
  right: auto;
  left: 0;
}

.rmdp-rtl .rmdp-left {
  left: auto;
  right: 0;
}

.rmdp-rtl .rmdp-range.start:not(.force),
.rmdp-rtl .rmdp-range-hover.start:not(.force) {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
  /* border-top-right-radius: 50%; */
  border-top-right-radius: 0 !important;
  /* border-bottom-right-radius: 50%; */
  border-bottom-right-radius: 0 !important;
}

.rmdp-rtl .rmdp-range.end:not(.force),
.rmdp-rtl .rmdp-range-hover.end:not(.force) {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  /* border-top-left-radius: 50%; */
  border-top-left-radius: 0 !important;
  /* border-bottom-left-radius: 50%; */
  border-bottom-left-radius: 0 !important;
}

.rmdp-rtl .rmdp-range.start.end:not(.force) {
  /* border-radius: 50%; */
  border-radius: 0 !important;
}

.rmdp-rtl .rmdp-ym .rmdp-range.start:not(.force),
.rmdp-rtl .rmdp-ym .rmdp-range-hover.start:not(.force) {
  /* border-top-right-radius: 15px; */
  border-top-right-radius: 0 !important;
  /* border-bottom-right-radius: 15px; */
  border-bottom-right-radius: 0 !important;
}

.rmdp-rtl .rmdp-ym .rmdp-range.end:not(.force),
.rmdp-rtl .rmdp-ym .rmdp-range-hover.end:not(.force) {
  /* border-top-left-radius: 15px; */
  border-top-left-radius: 0 !important;
  /* border-bottom-left-radius: 15px; */
  border-bottom-left-radius: 0 !important;
}

.rmdp-day-hidden,
.rmdp-day.rmdp-disabled {
  cursor: default;
}

.rmdp-selected .highlight {
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-red:hover {
  background-color: var(--highlight-red-hover);
}

.rmdp-day:not(.rmdp-deactive) .highlight-red {
  color: var(--highlight-red-color);
}

.rmdp-day.rmdp-deactive .highlight-red {
  color: var(--highlight-red-color-deactive);
}

.rmdp-day.rmdp-selected .highlight-red {
  background-color: var(--highlight-red-selected);
  color: white;
}

.rmdp-day.rmdp-deactive.rmdp-selected .highlight-red {
  background-color: var(--highlight-red-selected-deactive);
  color: white;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-green:hover {
  background-color: var(--highlight-green-hover);
}

.rmdp-day:not(.rmdp-deactive) .highlight-green {
  color: var(--highlight-green-color);
}

.rmdp-day.rmdp-deactive .highlight-green {
  color: var(--highlight-green-color-deactive);
}

.rmdp-day.rmdp-selected .highlight-green {
  background-color: skyblue !important;
  color: white;
}

.rmdp-day.rmdp-deactive.rmdp-selected .highlight-green {
  background-color: skyblue !important;
  color: white;
}

.rmdp-day-hidden:hover span,
.rmdp-day-hidden {
  background-color: unset;
  color: transparent;
}

.rmdp-month-name {
  font-size: 14px;
  margin: 3px 0;
  cursor: default;
}

.rmdp-full-year {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 450px), (max-height: 450px) {
  .rmdp-day,
  .rmdp-week-day {
    height: 28px;
    width: 28px;
  }

  .rmdp-day span {
    padding-left: 0.5px;
    font-size: 12px;
  }

  .only.rmdp-year-picker,
  .only.rmdp-month-picker {
    width: 230px;
    height: 200px;
  }

  .rmdp-header {
    padding: 3px 0 0 0;
    font-size: 12px;
  }

  .rmdp-month-name {
    font-size: 12px;
  }

  .rmdp-full-year {
    grid-template-columns: 1fr 1fr;
  }
}

/* old partial rental design start */

/* .rmdp-day:has([data-status="partial-booked-end"]) {
  position: relative;
  height: 50px;
  width: 50px;
  overflow: hidden;
  background-color: #faa61a !important;
  color: #fff;
}

.rmdp-day:has([data-status="partial-booked-end"])::before {
  content: "";
  position: absolute;
  right: 0%;
  width: 50px;
  height: 50px;
  background-color: #2d2d2d;
  bottom: 50%;
  transform: rotate(90deg);
  color: #fff;
}

.rmdp-day:has([data-status="partial-pending-end"]) {
  position: relative;
  height: 50px;
  width: 50px;
  overflow: hidden;
  background-color: skyblue !important;
  color: #fff;
}

.rmdp-day:has([data-status="partial-pending-end"])::before {
  content: "";
  position: absolute;
  right: 0%;
  width: 50px;
  height: 50px;
  background-color: #2d2d2d;
  bottom: 50%;
  transform: rotate(90deg);
  color: #fff;
}

.rmdp-day:has([data-status="partial-selecting-end"]) {
  position: relative;
  height: 50px;
  width: 50px;
  overflow: hidden;
  background-color: skyblue !important;
  color: #fff;
}

.rmdp-day:has([data-status="partial-selecting-end"])::before {
  content: "";
  position: absolute;
  right: 0%;
  width: 50px;
  height: 50px;
  background-color: #2d2d2d;
  bottom: 50%;
  transform: rotate(90deg);
  color: #fff;
} */

/* old partial rental design end */

.body-fix {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

/* New Range Design */
/* partial booked Start Triangle */
/* .rmdp-day.start-range.start:before { */
.rmdp-day:has([data-status="partial-booked-start"])::before {
  /* content: "";
  position: absolute;
  right: 0%;
  width: 100px;
  height: 100px;
  background-color: #faa61a !important;
  top: 0%;
  transform: rotate(-45deg);
  transform-origin: top right;
  color: #fff; */
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid #faa61a;
  border-left: 50px solid transparent;
  z-index: 1000;
}

/* partial booked End triangle */
/* .rmdp-day.end-range:before { */
.rmdp-day:has([data-status="partial-booked-end"])::before {
  /* content: "";
  position: absolute;
  right: 0%;
  width: 100px;
  height: 100px;
  background-color: #faa61a !important;
  top: 0%;
  transform: rotate(45deg);
  transform-origin: top right;
  color: #fff; */
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid #faa61a;
  border-right: 50px solid transparent;
  z-index: 1000;
}

/* partial pending start triangle */

/* .rmdp-day.start-range.start:before { */
.rmdp-day:has([data-status="partial-pending-start"])::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid skyblue;
  border-left: 50px solid transparent;
  z-index: 1000;
}

/* partial pending end triangle */

/* .rmdp-day.end-range:before { */
.rmdp-day:has([data-status="partial-pending-end"])::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid skyblue;
  border-right: 50px solid transparent;
  z-index: 1000;
}

/* partial selecting start triangle */

/* .rmdp-day.start-range.start:before { */
.rmdp-day.rmdp-range.start.partial-selecting-start {
  background: #2d2d2d !important;
  color: #fff !important;
}

.rmdp-day.rmdp-range.start span {
  color: #fff !important;
}

.rmdp-day.rmdp-range.start.partial-selecting-start span {
  color: #fff !important;
}

.rmdp-day.rmdp-range.start.partial-selecting-start::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid skyblue;
  border-left: 50px solid transparent;
  z-index: 1000;
  background: none;
}

.rmdp-day.rmdp-range.partial-selecting-start {
  background: #2d2d2d !important;
  color: #fff !important;
}

.rmdp-day.rmdp-range span {
  color: #fff !important;
}

.rmdp-day.rmdp-range.partial-selecting-start span {
  color: #fff !important;
}

.rmdp-day.rmdp-range.partial-selecting-start::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid skyblue;
  border-left: 50px solid transparent;
  z-index: 1000;
  background: none;
}

/* partial selecting end triangle */

/* .rmdp-day.end-range:before { */

.rmdp-day.rmdp-range.end span {
  color: #fff !important;
}

.rmdp-day.rmdp-range.end.partial-selecting-end {
  background: #2d2d2d !important;
  color: #fff !important;
}

.rmdp-day.rmdp-range.end.partial-selecting-end span {
  color: #fff !important;
}

.rmdp-day.rmdp-range.end.partial-selecting-end::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid skyblue;
  border-right: 50px solid transparent;
  z-index: 1000;
}

.rmdp-day.rmdp-range.start.full-book {
  background: #faa61a !important;
}

.rmdp-day.rmdp-range.end.full-book {
  background: #faa61a !important;
}

.rmdp-day.rmdp-range.start.full-book::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid #faa61a;
  border-left: 50px solid #faa61a;
  z-index: 1000;
  background: none;
}

.rmdp-day.rmdp-range.end.full-book::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid #faa61a;
  border-right: 50px solid #faa61a;
  z-index: 1000;
}

.booked-end-pending-start::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-right: 50px solid skyblue;
  border-top: 50px solid #faa61a;
  z-index: 1000;
}

.booked-start-pending-end::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid skyblue;
  border-right: 50px solid #faa61a;
  z-index: 1000;
}

.rmdp-day:has([data-status="booked-start-pending-end"])::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid skyblue;
  border-right: 50px solid #faa61a;
  z-index: 1000;
}

.rmdp-day:has([data-status="booked-end-pending-start"])::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-right: 50px solid skyblue;
  border-top: 50px solid #faa61a;
  z-index: 1000;
}

/* css for order tab calendar start */
.rmdp-day:has(.partial-booked-start)::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid #faa61a;
  border-left: 50px solid transparent;
  z-index: 1000;
}

.rmdp-day:has(.partial-booked-end)::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid #faa61a;
  border-right: 50px solid transparent;
  z-index: 1000;
}
.rmdp-day:has(.partial-pending-start)::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 50px solid skyblue;
  border-left: 50px solid transparent;
  z-index: 1000;
}

.rmdp-day:has(.partial-pending-end)::before {
  content: "";
  position: absolute;
  right: 0%;
  top: 0%;
  width: 0;
  height: 0;
  border-top: 50px solid skyblue;
  border-right: 50px solid transparent;
  z-index: 1000;
}

.rmdp-day.rmdp-today .partial-booked-start,
.rmdp-day.rmdp-today .partial-booked-end,
.rmdp-day.rmdp-today .partial-pending-start,
.rmdp-day.rmdp-today .partial-pending-end {
  color: #fff !important;
}
.equipment-calendar .rmdp-range {
  background-color: transparent !important;
}
.equipment-calendar .rmdp-range:hover {
  background-color: transparent !important;
}

/* css for order tab calendar end */

/* Line wala code */
.rmdp-day.partial-divider::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 50%;
  width: 100px;
  height: 1px;
  background: black;
  z-index: 1300;
  transform: translate(50%, -50%) rotate(-45deg);
}

.rmdp-day.selection-divider::after {
  position: absolute;
  content: "";
  top: 50%;
  right: 50%;
  width: 100px;
  height: 1px;
  background: black;
  z-index: 1300;
  transform: translate(50%, -50%) rotate(-45deg);
}

.filter-calendar {
  .rmdp-range {
    color: white;
    background-color: #faa61a !important;
    /* box-shadow: 0 0 3px var(--rmdp-shadow); */
    box-shadow: none !important;
  }
  .rmdp-range-hover {
    background-color: #faa61a !important;
    color: white;
  }

  @media (hover: hover) {
    .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
      background-color: #faa61a !important;
      /* background-color: transparent !important; */
      /* border:2px solid #faa61a; */
      color: white;
    }
  }
}
