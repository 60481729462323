.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.h-full {
  height: 100vh;
}

.d-flex {
  display: flex;
}

h1, h2, h3, h4, h5 {
  font-family: "GothamBold"!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Globally style the Checkbox */
.MuiCheckbox-root {
  color: #fff !important; 
}

.MuiCheckbox-root.Mui-checked {
  color: #fff !important; 
}

/* Globally style the Radio Button */
// .MuiRadio-root {
//   color: #fff !important; 
// }

// .MuiRadio-root.Mui-checked {
//   color: #faa61a !important; 
// }

