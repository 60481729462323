.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: transparent;
    padding: 0 80px;
    border-bottom: 7px solid #faa61a;
    padding: 1rem 0 1rem 0;


        // display: flex;
        // align-items: flex-start;
        // justify-content: center;
        // color: white;
        // background-color: transparent;
        // padding: 0 80px;
        // border-bottom: 7px solid #faa61a;
        // padding: 3rem 0 1rem 0;
        // height: 260px;
  

    .footer-inner {
        display: flex;
        justify-content: space-between;
        width: 80%;
        align-items: center;
        flex-direction: column;

        .footer-inner-grid {

            // padding-bottom: 1rem;
            // border-bottom: 1px solid #faa61a;
            .footer-inner-grid-left {
                border-right: 2px solid gray;
            }
        }

        .footer-find-us {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // padding: 1rem 0;

            a {
                text-decoration: none;
                color: white;
                width: 45px;
                height: auto;
                margin: 0 10px;

                &:hover {
                    color: gray;
                }
            }

            &-find-us {
                display: flex;
                align-items: center;
            }

            &-middle-border {
                background-color: gray;
                width: 3px;
                height: 80px;
            }
        }
    }

    .footer-links {
        width: 100%;
        margin: 1rem 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .footer-useful-link {
            color: #faa61a;
            text-decoration: none !important;
            cursor: pointer;
            margin: 0 1rem 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }
}

.footer-sd {
    padding: 1rem 0 9rem 0;
}

// .service-item-div:hover{
//     margin: 0 !important;
//     box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 1) !important;
// }

.service-item-div {
    padding: 1rem;
}

.footer-get-app {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer-inner-grid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-find-us-text {
    display: flex;
    width: fit-content;
}

.footer-inner-grid-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .footer-find-social {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .social-icon {
        width: 60px;
        height: auto;
        margin: 0 1rem 0 1rem;

        &:last-child {
            margin: 0 1rem 0 0 !important;
        }
    }
}

.find-us,
.get-app {
    color: #faa61a;
    font-size: clamp(14px, 3vw, 30px) !important;
}

.img-social {
    // width: 55px;
    // margin-left: 20px;
    width: 100%;
    height: auto;
}

.footer-download-app {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // padding: 1rem 0;

    .download-app-link {
        width: 150px;
        height: auto;
        margin: 0 10px;

        .img-download-app {
            width: 100%;
            height: auto;
        }

        &:last-child {
            margin: 0;
        }
    }
}


@media screen and (max-width: 1024px) {
    .footer {
        .footer-find-us {
            padding: 2rem 0 1rem 0 !important;
        }

        .footer-links {
            justify-content: center;
        }

        .get-app {
            padding-bottom: 1rem;
        }
    }



    .social-icon {
        width: 40px !important;
    }

    .footer-inner-grid-left {
        justify-content: center !important;
    }

    .footer-inner-grid-right {
        margin-top: 0rem !important;
    }

    .footer-inner {
        width: 100%;

        .footer-find-us {
            a {
                width: 30px !important;
            }
        }
    }

    .footer-inner-grid-left {
        border-right: none !important;
    }

}

@media screen and (max-width: 900px) {
    .footer {
        .footer-find-us {
            padding: 2rem 0 1rem 0 !important;
        }

        .footer-links {
            justify-content: center;
        }

        .get-app {
            padding-bottom: 1rem;
        }
    }



    .social-icon {
        width: 40px !important;
    }

    .footer-inner-grid-left {
        justify-content: center !important;
    }

    .footer-inner-grid-right {
        margin-top: 1rem !important;
    }

    .footer-inner {
        width: 100%;

        .footer-find-us {
            a {
                width: 30px !important;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .livehelp-box {
        position: fixed;
        bottom: 20px;
        right: 15px;
        padding: 10px;
        width: 50px;
    }
    .footer {
        .footer-find-us {
            padding: 2rem 0 1rem 0 !important;
        }

        .footer-links {
            justify-content: center;
        }

        .get-app {
            padding-bottom: 1rem;
        }
    }
    .social-icon {
        width: 40px !important;
    }

    .footer-inner-grid-left {
        justify-content: center !important;
    }

    .footer-inner-grid-right {
        margin-top: 1rem !important;
    }

    .footer-inner {
        width: 100%;

        .footer-find-us {
            a {
                width: 30px !important;
            }
        }
    }
}