.equipments-wrapper {
  // max-width: 1060px;
  // left: 50%;
  width: 1200px;
  margin: auto;
  position: relative;
  // transform: translateX(-50%);
  // margin: 30px 0;
}

.img-stripes-long {
  width: 100%;
}

.grid-container {
  // margin-top: 30px;
  min-height: 310px;
  //min-height: 2000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.equipment-grid {
  width: 100% !important;
}

.item-container {
  width: 100%;
  padding: 1rem;
  border: 2px solid #faa61a00;
  border-radius: 10px;
}

.item-container:hover {
  width: 100%;
  padding: 1rem;
  border: 2px solid #faa61a;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgb(233, 147, 35, 0.9);
}

.hr {
  height: 5px;
  background-color: #faa61a;
  border: none;
}

// .pagination-sectio{
//   nav ul li button{
//    // background-color: #000;    
//   }
// }

@media screen and (max-width: 1200px) {
    .equipments-wrapper {
        width: 100% !important;
      }
    
      .equipment-grid {
        width: 100% !important;
      }
  .equipment-grid {
      width: 100% !important;
  }
}

@media screen and (max-width: 900px) {
  .equipments-wrapper {
    width: 100% !important;
  }

  .equipment-grid {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .equipments-wrapper {
      width: 100% !important;
    }
  .equipment-grid {
      width: 100% !important;
  }
}