.div-para {
    margin-top: 10px;
    margin-bottom: 50px;
    color: #fff !important;
}

.wrapper {
    padding-bottom: 3rem;
}

.a-link-gem {
    color: #faa61a !important;
}

h1.heading {
    font-size: clamp(1.7rem, 3vw, 3.5rem);
    text-align: center;
    font-weight: 100;
    color: #fff !important;
}

.p3.parap3 {
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    text-align: center;
    margin-bottom: 25px;
    font-weight: 400;
}

p.para-1 {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: justify;
    color: #fff !important;
}


.p2 {
    font-size: 1.2rem clamp(1rem, 3vw, 1.2rem);
    text-align: center;
    margin-top: 40px;
    color: #fff !important;
}

.p3 {
    font-size: clamp(1.2rem, 3vw, 3.5rem);
    text-align: center;
    margin-bottom: 25px;
    font-weight: 100;
    color: #fff !important;
}

/* .p4 {
     font-size: 5.5vw;
     text-align: center;
 } */

.p {
    font-size: clamp(0.8rem, 3vw, 1.2rem);
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fff !important;
}

p.para-1,
.p,
.b {
    font-size: clamp(0.8rem, 3vw, 1.2rem);
    color: #fff !important;
}

.h5 {
    text-align: center;
    font-size: clamp(1.2rem, 3vw, 2rem);
    font-weight: 600;
    color: #fff !important;
}

.h4 {
    text-align: center;
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #fff !important;
}

.h3 {
    font-family: serif;
    font-size: clamp(1.2rem, 3vw, 2rem);
    text-align: center;
    color: #fff !important;
}

.h2 {
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 100;
    color: #fff !important;
}

.h1 {
    font-size: clamp(2rem, 3vw, 3.5rem);
    text-align: center;
    color: #fff !important;
}

.li {
    font-size: clamp(0.8rem, 3vw, 1.2rem);
    margin-bottom: 15px;
    padding-left: 20px;
    margin-top: 15px;
    text-align: justify;
    color: #fff !important;
}


/*table*/

#t1 {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

#t1 td,
#t1 th {
    border: 1px solid #fff !important;
    padding: 8px;
    font-size: clamp(1rem, 3vw, 1.2rem);
}

#t1 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #dbe0dc !important;
    color: black !important;
    text-align: center;
    font-size: clamp(1rem, 3vw, 1.2rem);
}

ol.lower-alpha {
    list-style-type: lower-alpha;
}

ol.upper-roman {
    list-style-type: upper-roman;
}

@media (min-width: 1400px) {
    .wrapper {
        margin-left: 200px;
        margin-right: 200px;
    }

}

@media (max-width: 1400px) {
    .wrapper {
        margin-left: 100px;
        margin-right: 100px;
    }

    .div-para {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    // h1.heading {
    //     font-size: 76.5px;
    // }

    // .p.para-1 {
    //     margin-top: 10px;
    //     font-size: 1.5rem;
    //     margin-bottom: 30px;
    // }

    .p0 {
        text-align: center;
    }

    .p2 {
        text-align: center;
        margin-top: 40px;
    }

    .p3 {
        text-align: center;
        margin-bottom: 25px;
    }

    .p4 {
        text-align: center;
    }

    .p {
        padding: auto;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .h5 {
        text-align: center;
        font-weight: 600;
    }

    .h4 {
        text-align: center;
    }

    .h3 {
        font-family: serif;
        text-align: center;

    }

    .h2 {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .h1 {
        text-align: center;
    }

    .li {
        margin-bottom: 15px;
        padding-left: 20px;
        margin-top: 15px;
    }


    /*table*/

    #t1 {
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    #t1 td,
    #t1 th {
        border: 1px solid black;
        padding: 8px;
        padding: auto;
    }

    #t1 th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #dbe0dc;
        color: black;
        text-align: center;
        padding: auto;
    }
}

@media (max-width: 1200px) {
    .wrapper {
        margin-left: 100px;
        margin-right: 100px;
    }

    .div-para {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    // h1.heading {
    //     font-size: 76.5px;
    // }

    p.para-1 {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .p0 {
        text-align: center;
    }

    .p2 {
        text-align: center;
        margin-top: 40px;
    }

    .p3 {
        text-align: center;
        margin-bottom: 25px;
    }

    .p4 {
        text-align: center;
    }

    .p {
        padding: auto;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .h5 {
        text-align: center;
        font-weight: 600;
    }

    .h4 {
        text-align: center;
    }

    .h3 {
        font-family: serif;
        text-align: center;

    }

    .h2 {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .h1 {
        text-align: center;
    }

    .li {
        margin-bottom: 15px;
        padding-left: 20px;
        margin-top: 15px;
    }


    /*table*/

    #t1 {
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    #t1 td,
    #t1 th {
        border: 1px solid black;
        padding: 8px;
        padding: auto;
    }

    #t1 th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #dbe0dc;
        color: black;
        text-align: center;
        padding: auto;
    }
}

@media (max-width: 992px) {
    .wrapper {
        margin-left: 30px;
        margin-right: 30px;
    }

    .div-para {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    // h1.heading {
    //     font-size: 4.5rem;
    // }

    .p {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: justify;
    }

    p.para-1 {
        margin-top: 10px;
        margin-bottom: 20px;
    }


    .p2 {
        margin-top: 20px;
    }

    .p3 {
        margin-bottom: 15px;
    }

    /*table*/

    #t1 {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }

    #t1 th {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        margin-left: 30px;
        margin-right: 30px;
    }

    .div-para {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p.para-1 {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    ol.lower-alpha {
        list-style-type: lower-alpha;
        padding-left: 0;
    }


    .p2 {
        margin-top: 20px;
    }

    .p3 {
        margin-bottom: 15px;
    }

    .h2 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .li {
        margin-bottom: 10px;
        padding-left: 10px;
        margin-top: 10px;

    }

    .ol {
        padding-left: 0;
    }

    ol.upper-roman {
        list-style-type: upper-roman;
        padding-left: 0;
    }

    /*table*/

    #t1 {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }

    #t1 th {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}


@media (max-width: 576px) {
    .wrapper {
        margin-left: 30px;
        margin-right: 30px;
    }

    .div-para {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    // h1.heading {
    //     font-size: 4.75rem;
    // }

    p.para-1 {
        margin-top: 10px;
        margin-bottom: 30px;
    }


    .p2 {
        margin-top: 40px;
    }

    .p3 {
        margin-bottom: 25px;
    }

    .p {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .h5 {
        font-weight: 600;
    }

    .h2 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .li {
        margin-bottom: 15px;
        padding-left: 20px;
        margin-top: 15px;
    }


    /*table*/

    #t1 {
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    #t1 td,
    #t1 th {
        border: 1px solid black;
        padding: 8px;
    }

    #t1 th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #dbe0dc;
        color: black;
        text-align: center;
    }
}

@media (max-width: 320px) {
    .wrapper {
        margin-left: 30px;
        margin-right: 30px;
    }

    .div-para {
        margin-top: 10px;
        margin-bottom: 50px;
    }
    p.para-1 {
        margin-top: 10px;
        margin-bottom: 30px;
    }


    .p2 {
        margin-top: 40px;
    }

    .p3 {
        margin-bottom: 25px;
    }

    .p {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .h2 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .li {
        margin-bottom: 15px;
        padding-left: 20px;
        margin-top: 15px;
    }
}