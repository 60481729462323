.app-download {
    padding: 3rem 0 0;

    .app-download-main {
        .app-download-heading {
            font-size: clamp(35px, 3vw, 50px) !important;
            color: #fff;
            font-family: "GothamBold" !important;
            text-align: center;
            margin: 0rem 0rem 2rem 0;
            font-weight: 900;
        }

        .app-download-subheading {
            margin: 2rem 0;
            color: #faa61a;
            font-size: clamp(18px, 3vw, 25px);
            font-family: "GothamBold" !important;
            width: 70%;
            margin: auto;
            text-align: center;
        }

        .app-download-p {
            color: #faa61a;
            font-size: clamp(18px, 3vw, 25px);
            font-family: "Gotham" !important;
        }

        .app-download-grid {
            display: flex;
            gap: 2rem;
            margin: 3rem 0;

            // display: grid;
            // gap: 2rem;
            // margin: 3rem 0;
            // grid-template-columns: 1fr 1.2fr;

            .app-download-grid-item-left {
                width: 50%;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    max-height: 500px;
                    object-fit: contain;
                }
            }

            .app-download-grid-item-right {
                width: 50%;

                .app-download-grid-item-right-grid {
                    // display: flex;
                    // display: grid;
                    // grid-template-columns: 1fr 1fr;

                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    // width: 100%;
                    width: unset !important;
                    align-items: center;
                    gap: 2rem;

                    .app-download-grid-item-right-grid-item-left {
                        display: flex;
                        flex-direction: column;

                        gap: 1rem;
                        width: unset !important;

                        img {
                            display: block;
                            width: 150px;
                            height: 50px;
                            max-height: 50px;
                            object-fit: contain;
                        }
                    }

                    .app-download-grid-item-right-grid-item-right {
                        width: unset !important;
                        img {
                            display: block;
                            width: 170px;
                            height: 170px;
                            max-height: 170px;
                            object-fit: contain;
                        }
                    }
                }

                .app-download-p {
                    margin-top: 1rem;
                    text-align: left;
                }
            }
        }
    }
}

.app-download-grid-item-right {
    width: 100%;
}

@media screen and (max-width:1400px) {
    
}

@media screen and (max-width:1200px) {

}
@media screen and (max-width:1024px) {

}



@media screen and (max-width:992px) {
    .app-download .app-download-main .app-download-subheading {
        width: 90%;
    }

        .app-download .app-download-main .app-download-grid .app-download-grid-item-left {
            width: 100%;
            margin: auto;
        }

    // .app-download .app-download-main .app-download-grid .app-download-grid-item-right {
    //     width: 50%;
    //     margin: auto;
    // }

    // .app-download .app-download-main .app-download-grid .app-download-grid-item-left {
    //     width: 50%;
    //     margin: auto;
    // }

    .app-download .app-download-main .app-download-grid {
        flex-direction: column;
    }

    .app-download .app-download-main .app-download-grid {
        grid-template-columns: 1fr;
    }

        .app-download .app-download-main .app-download-grid .app-download-grid-item-right {
            width: 100%;
            margin: auto;
        }

                .app-download-p {
                    margin-top: 1rem;
                    text-align: center !important;
                }



    .app-download .app-download-main .app-download-grid .app-download-grid-item-right .app-download-grid-item-right-grid {
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: center;
        width: 100%;
    }

    .app-download-grid-item-left {
        order: 2;
    }

    .app-download .app-download-main .app-download-p {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .app-download .app-download-main .app-download-grid .app-download-grid-item-left img {
            width: 570px;
            margin: auto;
        }
                .app-download .app-download-main .app-download-grid .app-download-grid-item-left {
                    width: 100%;
                    margin: auto;
                }
}

@media screen and (max-width:576px) {
    .app-download .app-download-main .app-download-grid .app-download-grid-item-left {
            width: 90%;
            margin: auto;
        }
.app-download .app-download-main .app-download-grid .app-download-grid-item-left img {
        width: 320px;
        margin: auto;
    }
}

@media screen and (max-width:320px) {
    .app-download .app-download-main .app-download-grid .app-download-grid-item-left img {
            width: 280px;
            margin: auto;
        }
}

.app-download-grid-item-right-grid-item-right {
    svg {
        width: 100%;
        height: auto;
        border: 5px solid #fff;
    }
}