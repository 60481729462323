.download-popups {
    position: relative;
    border-radius: 10px;
}

.login__main-div.edit-eqp {
    display: flex !important;
    border-radius: 10px !important;
}

.popup-cmmn.popup-white {
    background-color: #2d2d2d !important;
    border: 5px solid #faa61a;
    position: relative;
    width: 500px;
    // min-height: 400px;
    min-height: auto;
    max-width: 500px;
    max-height: 550px;
    overflow-y: scroll;
    border-radius: 10px !important;
}

.cross-btn {
    position: absolute !important;
    top: -17px !important;
    right: -17px !important;
    background-color: #faa61a !important;
    color: #fff !important;
    z-index: 9999;
}

.cross-btn-2 {
    position: absolute !important;
    top: -10px !important;
    right: -16px !important;
    background-color: #faa61a !important;
    color: #fff !important;
    z-index: 9999;
    width: 25px;
    height: 25px;
}

.owner-div {
    // display: grid;
    // grid-template-columns: 0.5fr 1.5fr;
    // grid-gap: 2rem;

    display: flex;
    align-items: center;

    gap: 1rem;
    margin: 1rem 0;
}

.rental-sec-item-top {
    margin-bottom: 1rem;
}

.MuiFormControl-root.MuiTextField-root.popup-input {
    background: #fff;
    border: 2px solid #faa61a;
    border-radius: 10px;
    color: #ffffff;
    // margin: 0.4rem 0;
}



label#demo-radio-buttons-group-label {
    color: #fff !important;
}

// input#outlined-disabled {
//     background: gray;
// }

// button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.signin-btn.css-1awc3ug-MuiButtonBase-root-MuiButton-root {
//     border-radius: 10px;
// }

// .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root {
//     background: #fff;
//     border: 2px solid #faa61a;
//     border-radius: 10px;
//     outline: none !important;
//     padding-top: 0.5rem !important;
// }

// span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
//     color: #2d2d2d !important;
// }

// .MuiFormLabel-root {
//     color: #2d2d2d !important;
// }

@media screen and (max-width:576px) {
    .popup-cmmn {
        width: 90% !important;
    }
}

.bookings-box {
    // height: 200px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.no-bookings {
    display: flex;
    justify-content: center;
    align-items: center;
}

.owner-img.booked-img {
    border: 3px solid #faa61a;
    border-radius: 0px;
    // height: 100%;
    // width: 100% !important;
    width: 160px !important;
    height: 120px;

    img {
        width: 100%;
        height: 100% !important;
        max-height: 100% !important;
        display: block;
        object-fit: cover;
    }
}

.year .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #646464 !important;
}

.login__main-div__form.popup-cmmn.popup-white.edit-eqp-inner.cal-pop {
    height: 100% !important;
    max-height: 550px !important;
    position: relative;
}

.updt-btn-div {
    position: fixed;
    bottom: 4px;
    width: 98%;
    z-index: 9999;
    transform: translate(-50%, 0%);
    justify-content: center;
    left: 50%;
    background: linear-gradient(0deg, rgba(46, 46, 46, 1) 0%, rgba(46, 46, 46, 0.835171568627451) 25%, rgba(46, 46, 46, 0.4598214285714286) 50%, rgba(46, 46, 46, 0.19371498599439774) 75%, rgba(46, 46, 46, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login__main .login__main-div__form .signin-btn.updt-btn {
    font-size: clamp(1em, 3vw, 1.2em);
    background-color: #169245;
    font-family: "GothamBold" !important;
    margin: 1rem 0;
    border-radius: 10px !important;
    width: 90%;
}

// .scroll-arrow-div {
//     position: fixed;
//     bottom: 80px;
//     right: 40px;
//     background: #2e2e2e9e;
//     padding: 0.4em;
//     -moz-transition: opacity 1s ease-out;
//         -o-transition: opacity 1s ease-out;
//         -webkit-transition: opacity 1s ease-out;
//         transition: opacity 1s ease-out;
// }

// .scroll-arrow-icon {
//     font-size: 100px;
//     width: 50px;
//     height: 50px;
// }

// svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.scroll-arrow-icon {
//     font-size: 60px;
// }

.scroll-prompt {
    position: fixed;
    bottom: 80px;
    right: 60px;
    // background: #2e2e2e9e;
    padding: 0.4em;
    // width: 30px;
    // height: 30px;
    -moz-transition: opacity 1s ease-out;
    -o-transition: opacity 1s ease-out;
    -webkit-transition: opacity 1s ease-out;
    transition: opacity 1s ease-out;
    z-index: 9999;
}

.scroll-prompt .scroll-prompt-shape {
    position: relative;
    -moz-animation: slidedown 2.25s infinite;
    -webkit-animation: slidedown 2.25s infinite;
    animation: slidedown 2.25s infinite;
}

.scroll-prompt .scroll-prompt-shape:before,
.scroll-prompt .scroll-prompt-shape:after {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 5px;
    top: -10px;
    left: 0;
    background: #faa61a;
    -moz-border-radius: 0 0.25em 0.25em 0;
    -webkit-border-radius: 0;
    border-radius: 0 0.25em 0.25em 0;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
}

.scroll-prompt .scroll-prompt-shape:before {
    -moz-transform: scaleX(-1) rotate(-45deg);
    -ms-transform: scaleX(-1) rotate(-45deg);
    -webkit-transform: scaleX(-1) rotate(-45deg);
    transform: scaleX(-1) rotate(-45deg);
}

.scroll-prompt .scroll-prompt-shape:after {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@-moz-keyframes slidedown {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 1;
        -moz-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }

    100% {
        opacity: 0;
        -moz-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }
}

@-webkit-keyframes slidedown {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 1;
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }
}

@keyframes slidedown {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 1;
        -moz-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }

    100% {
        opacity: 0;
        -moz-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }
}

@media screen and (max-width:768px) {
    .login__main-div__form.popup-cmmn.popup-white.edit-eqp-inner.cal-pop {
        padding: 1.2rem !important;
    }

        .popup-cmmn.popup-white {
            width: 480px !important;
        }
    }

@media screen and (max-width: 576px) {
    .popup-cmmn.popup-white {
        width: 320px !important;
    }
}

.active-rental-inner.booking-box-inner {
    max-height: 400px;
    width: 100%;
    margin: 1rem 0;
}

.owner-div p {
    color: #fff;
}

.rental-sec-item-top p {
    color: #fff;
}

.rental-sec-item-top {
    margin: 1rem 0;
}

// .MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
//     margin-top: 19px !important;
// }


/*******************/
.owner-div-new p {
    color: #fff;
}

.cmmn-rental-date-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 1rem 0;
    color: #ffffff !important;
}

.cmmn-o-r-detail {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cmmn-rental-date-innr {
    p {
        font-size: clamp(13px, 3vw ,16px);
    }
}

.cmmn-o-r-img {
    img {
        width: 60px;
        height: 60px;
        max-height: 60px;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid #faa61a;
    }

}

.equipment-rate-inner.cmmn-rate-list {
    margin-top: 1.5rem;
}

.cmmn-o-r-detail-text {

}


.list-epq-modal-form.radio-grp.list-epq-radio-grp {
    color: #ffffff !important;
    .MuiSvgIcon-root:not(.MuiSvgIcon-root~.MuiSvgIcon-root) {
            color: #fafafa !important;
        }
}

.login__main-div__form.popup-cmmn.popup-white.edit-eqp-inner {
    h6 {
        color: #ffffff !important;
    }
}

.characters-p {
    color: #fff !important;
}

.detail-span-p{
    color: #fff !important;
}

:before, :after, :hover:not(.Mui-disabled):before {
    borderBottom: "0 !important";
}


.MuiInputBase-root .MuiFilledInput-root .MuiFilledInput-underline .MuiInputBase-colorPrimary .MuiInputBase-formControl .MuiInputBase-adornedStart {
    &::after {
        borderBottom: "0 !important";
    }   
    &::before {
        borderBottom: "0 !important";
    }

    &:hover {
        borderBottom: "0 !important";
    }

    &:hover:not(.Mui-disabled)::before {
        borderBottom: "0 !important";
    }   
}


@media screen and (max-width: 1024px) {
    .popup-cmmn {
            background-color: #2d2d2d !important;
            border: 5px solid #faa61a;
            position: relative;
            max-width: 500px !important;
            width: 500px;
            max-height: 450px !important;
            border-radius: 10px !important;
        }
}

.photo-tips-main.c {
    margin-bottom: 1rem;
}


.myot-cal {
    .Calendar__monthArrowWrapper.-left {
        transform: rotate(90deg) !important;
        position: absolute !important;
        top: 50% !important;
        background-color: #fff !important;
    }
    .Calendar__monthArrowWrapper.-right {
        transform: rotate(-90deg);
        position: absolute !important;
        top: 50% !important;
        left: -18px !important;
        background-color: #fff !important;
    }
}
.delete-eqp-div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}
.delete-eqp {
    // border: 2px solid #ff0000;
    // padding: 0.5rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border-radius: 5px;
    font-family: 'GothamBold' !important;
    text-decoration-line: none !important;
    .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
        color: #ff0000 !important;
    }
}

// .myot-cal {

//     .rmdp-wrapper {
//         background-color: #ffffff00 !important;
//         background-image: url("../../assets/images/banner_img.png") !important;
//         border-radius: 0px !important;
//         border: 1px solid #fff;
//         direction: ltr;
//         text-align: center;
//         width: 100% !important;
//         box-shadow: none !important;
//     }
    
//     .twin-cal-custom-calendar {
    
//         .rmdp-day,
//         .rmdp-week-day {
//             color: #ffffff !important;
//             cursor: pointer;
//             height: 45px !important;
//             position: relative;
//             width: 45px !important;
//         }
    
//         .rmdp-calendar {
//             width: 100% !important;
//             // padding: 1rem 2rem 2rem 2rem !important;
//             padding: 1rem !important;
//             height: 450px !important;
//         }
    
//         .rmdp-day span {
//             border-radius: 50%;
//             bottom: 3px;
//             // font-size: 13px;
//             font-size: clamp(16px, 3vw, 20px) !important;
//             left: 3px;
//             position: absolute;
//             right: 3px;
//             top: 3px;
//             // width: 100% !important;
//             // height: 100% !important;
//         }
    
//         .rmdp-range {
//             background-color: #1976d2 !important;
//             box-shadow: none !important;
//             color: #fff;
//             border-radius: 5px !important;
//         }
    
//         .rmdp-arrow-container {
//             height: 40px !important;
//             justify-content: center;
//             margin: 0 !important;
//             width: 40px !important;
//             background-color: #1976d2 !important;
//             display: flex !important;
//             justify-content: center !important;
//             align-items: center !important;
//         }
    
//         .rmdp-arrow-container.disabled .rmdp-arrow,
//         .rmdp-arrow-container.disabled:hover .rmdp-arrow {
//             border: solid black;
//             border-width: 0 2px 2px 0;
//         }
    
//         .rmdp-arrow {
//             // border: solid #000 !important;
//             border-width: 0 2px 2px 0;
//             display: inline-block;
//             height: 8px !important;
//             margin-top: 0px !important;
//             padding: 0px !important;
//             width: 8px !important;
//             color: #000 !important;
//         }
    
//         .rmdp-calendar {
//             width: 100% !important;
//         }
    
//         .rmdp-header {
//             // font-size: 16px !important;
//             font-size: clamp(18px, 3vw, 22px) !important;
//             width: 100% !important;
//             max-width: 100% !important;
//             color: #fff !important;
//         }
    
//         .rmdp-header-values {
//             color: #fff !important;
//         }
    
//         .rmdp-day.rmdp-today span {
//             background-color: #1976d2 !important;
//             color: #fff;
//             box-shadow: none !important;
//         }
    
//         .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//             background-color: #1976d2 !important;
//             color: #fff;
//             box-shadow: none !important;
//         }
    
//         .rmdp-day,
//         .rmdp-week-day {
//             color: #fff !important;
//             font-weight: 300 !important;
//         }
    
//         .rmdp-day.rmdp-disabled {
//             color: rgb(99, 99, 99) !important;
//         }
    
//         .rmdp-week-day {
//             color: #ffffff !important;
//             font-size: 22px;
//             font-weight: 500;
//         }
//     }
// }





// new Changes

.radio-grp.list-eqp-page.disabled .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color:gray !important;
}