.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: 100% !important;
}

.swiper-slide img {
    display: block;
    width: auto !important;
    height: 100% !important;
    object-fit: contain;
}

.swiper {
    margin-left: auto;
    margin-right: auto;
}

.swiper,
swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
    height: 360px !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: 100% !important;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: #00000070 !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 900 !important;
}

@media screen and (max-width:768px) {
    .swiper {
        display: none !important;
    }
}