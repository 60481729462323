.tab-heading {
    font-size: 35px;
    color: #fff;
    font-family: 'GothamBold' !important;
    padding: 1rem 1rem 8rem 1rem;
    background-color: #47463E;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
    border-radius: 10px !important;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    font-family: 'Montserrat' !important;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 25px !important;
    }

    @media (max-width: 576px) {    
            font-size: 20px !important;
            font-family: 'Montserrat' !important;
            font-weight: 700;
    }

        @media (max-width: 450px) {
            font-size: 17px !important;
            font-family: 'Montserrat' !important;
            font-weight: 700;
        }

                @media (max-width: 375px) {
                    font-size: 17px !important;
                    font-family: 'Montserrat' !important;
                    font-weight: 700;
                }
}

.tab-heading-2 {
    font-size: 35px;
    color: #fff;
    font-family: 'GothamBold' !important;
    padding: 1rem 1rem 8rem 1rem;
    background-color: #47463E;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
    border-radius: 10px !important;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    font-family: 'Montserrat' !important;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 25px !important;
    }

    @media (max-width: 576px) {
        font-size: 14px !important;
        font-family: 'Montserrat' !important;
        font-weight: 700;
    }
}

.tablike-content {
    padding: 1rem 1.5rem;

    @media (max-width:768px) {
        padding: 1rem 0rem 1rem 1rem;
    }

    .tab-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        align-items: flex-start;
        padding: 1.5rem;
        background: #2D2D2D;
        border: 3px solid #faa61a;
        border-radius: 17px;
        margin-top: -8rem;
        z-index: 100;
        position: relative;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);

        @media (max-width: 992px) {
            grid-template-columns: repeat(1, 1fr) !important;

        }

        @media (max-width: 576px) {
            padding: 1rem 1.2rem !important;
        }


        .tab-grid-heading {
            display: flex;
            align-items: flex-start;
            gap: 0.7rem;
            height: 80px;
            flex-direction: column;

            @media (max-width:1210px) {
                height: 110px !important;

            }

            @media (max-width:992px) {
                height: auto !important;
                flex-direction: row !important;
                align-items: center !important;
            }

            img.tabicon {
                display: block;
                width: 35px;
                height: auto;
                object-fit: contain;

                @media (max-width: 576px) {
                    width: 22px !important;
                }

            }

                        img.jcbicon {
                            width: 55px;
                            display: block;

                            @media (max-width: 576px) {
                                    width: 37px !important;
                                }
                        }

            p {
                color: #faa61a;
                font-size: clamp(20px, 3vw,25px);
                font-family: 'GothamBold' !important;

            }
        }

        .tab-grid-text {
            font-family: 'Montserrat' !important;
            color: #fff;
            font-weight: 400;


            @media (max-width: 768px) {
                font-size: 13px !important;
            }
        }
    }
}