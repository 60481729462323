.rentyrd-main {
    margin: 2rem auto;
    width: 75%;

    @media (max-width:1650px){
        width: 90% !important;
    }

    @media (max-width:1600px){
        width: 100% !important;
    }

    @media (max-width:1500px){
        width: 100% !important;
    }
    @media (max-width:1400px){
        width: 100% !important;
    }

    @media (max-width:1300px){
        width: 100% !important;
    }

    @media (max-width:1200px){
        width: 100% !important;
    }


    .rentyrd-inner {

        .rentyrd-top-grid {
            margin: 1rem 0;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 1rem;

            .rentyrd-top-grid-item {
                &:first-child {
                    .rentyrd-renter-img {
                        img {
                            display: block;
                            width: 50px;
                            height: 50px;
                            max-height: 50px;
                            object-fit: cover;
                            border: 2px solid #fff;
                            border-radius: 50%;
                        }
                    }
                }

                &:last-child {
                    width: 100%;
                    height: 100%;

                    .rentyrd-menu {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        border-bottom: 2px solid gray;
                        height: 100%;
                        gap: 5px;

                        .rentyrd-menu-link {
                            position: relative;
                            text-decoration: none;
                            color: #fff;
                            padding: 0 1rem 0rem 1rem;
                            margin-bottom: 0.5rem;

                            cursor: pointer;


                            p {
                                font-size: clamp(12px, 3vw, 18px) !important;
                                font-family: 'GothamBold' !important;
                            }

                            &:first-child {
                                padding-left: 0;
                            }

                            &:hover {
                                &::after {
                                    position: absolute;
                                    content: "";
                                    width: 40%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: #faa61a;
                                    height: 4px;
                                    left: 50%;
                                    bottom: -7px;
                                    transform: translate(-50%, 0);
                                    margin-top: 10px;
                                }

                            }

                            &:nth-child(2),
                            &:nth-child(3),
                            &:nth-child(4),
                            &:nth-child(5),
                            &:nth-child(6) {
                                border-left: 2px solid gray;
                            }

                            .rentyrd-notification {
                                position: absolute;
    top: -7px;
    right: -5px;
    background-color: #ff0000;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding:2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .rentyrd-heading {
            margin: 1rem 0;
            display: flex;
            align-items: baseline;
            gap: 2rem;

            p {
                font-size: clamp(20px, 3vw, 40px);
                font-family: 'GothamBold' !important;
                color: #fff;
            }

            .rentyrd-share-link {
                color: #faa61a;
                text-decoration-color: #faa61a;
                font-size: clamp(18px, 3vw, 22px);
                font-family: 'GothamBold' !important;
            }
        }

        .rentyrd-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 4rem;
            position: relative;
            align-items: start;

            .rentyrd-grid-item.rentyrd-left {
                .rentyrd-card {
                    border: 2px solid #faa81a00;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0);
                    padding: 2rem 1rem 0.5rem 1rem;

                    &:hover {
                        border: 2px solid #faa61a;
                        border-radius: 10px;
                        box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9);
                        padding: 2rem 1rem 0.5rem 1rem;
                    }

                    .rentyrd-card-grid {

                        display: flex;
                        gap: 1rem;
                        align-items: center;

                        .rentyrd-card-item {
                            &:first-child {
                                .rentyrd-card-img {
                                    position: relative;
                                    height: 100%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        max-height: 170px !important;
                                        object-fit: cover;
                                        display: block;
                                    }

                                    p {
                                        border: 2px solid #000;
                                        width: auto;
                                        height: auto;
                                        position: absolute;
                                        background-color: #faa61a;
                                        color: #000;
                                        padding: 0px 8px;
                                        border-radius: 12px;
                                        top: -14px;
                                        right: -10px;
                                        font-family: 'GothamBold' !important;
                                    }
                                }
                            }

                            &:last-child {
                                width: 100%;

                                .rentyrd-right {
                                    .vehicle-model {
                                        color: #fff;
                                        font-size: clamp(18px, 3vw, 25px);
                                        font-family: 'GothamBold' !important;
                                    }

                                    .vehicle-type {
                                        color: #faa61a;
                                        font-size: clamp(14px, 3vw, 20px);
                                    }

                                    .rentyrd-address {
                                        display: grid;
                                        grid-template-columns: repeat(2, 1fr);
                                        grid-gap: 1rem;
                                        border-bottom: 2px solid gray;
                                        padding: 0.5rem 0;

                                        p {
                                            color: #fff;
                                            font-size: clamp(12px, 3vw, 16px);

                                            &:last-child {
                                                text-align: right;
                                            }
                                        }
                                    }

                                    .rentyrd-rates {
                                        display: grid;
                                        grid-template-columns: repeat(3, 1fr);
                                        grid-gap: 0;
                                        padding: 0.5rem 0;

                                        .rentyrd-rates-item {
                                            display: flex;
                                            justify-content: center;

                                            &:nth-child(2) {
                                                border-left: 2px solid gray;
                                                border-right: 2px solid gray;
                                            }

                                            p {
                                                color: #fff;
                                                font-size: clamp(14px, 3vw, 18px);
                                                font-family: 'GothamBold' !important;
                                                display: flex;
                                                align-items: baseline;

                                                .rentyrd-rates-span {
                                                    color: #faa61a;
                                                    font-size: clamp(9px, 3vw, 14px);
                                                    font-family: 'Gotham' !important;
                                                }
                                            }
                                        }


                                    }
                                }
                            }

                        }
                    }

                    .card-uid {
                        color: #fff;
                        font-size: clamp(14px, 3vw, 18px);
                    }
                }
            }

            .rentyrd-grid-item.rentyrd-right {
                position: -webkit-sticky;
                position: sticky;
                top: 7rem;

                .rentyrd-left-float {
                    .equipment-details-heading {
                        border-bottom: 5px solid #faa61a;
                        padding-bottom: 0.5rem;
                        margin-bottom: 1rem;

                        p {
                            font-size: clamp(20px, 3vw, 35px);
                            font-family: 'GothamBold' !important;
                            color: #fff;
                            text-align: center;
                        }
                    }

                    // .rentyrd-card {
                    //     border: 2px solid #faa81a00;
                    //     border-radius: 10px;
                    //     box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0);

                    //     .rentyrd-card-grid {
                    //         display: grid;
                    //         grid-template-columns: 1fr 2fr;
                    //         gap: 1rem;

                    //         .rentyrd-card-item {
                    //             &:first-child {
                    //                 .rentyrd-card-img {
                    //                     position: relative;
                    //                     height: 100%;

                    //                     img {

                    //                         width: 100%;
                    //                         height: 100%;
                    //                         max-height: 100%;
                    //                         object-fit: cover;
                    //                         display: block;
                    //                     }
                    //                 }
                    //             }

                    //             &:last-child {
                    //                 width: 100%;

                    //                 .rentyrd-right {
                    //                     .vehicle-model {
                    //                         color: #fff;
                    //                         font-size: clamp(20px, 3vw, 25px);
                    //                         font-family: 'GothamBold' !important;
                    //                     }

                    //                     .vehicle-type {
                    //                         color: #faa61a;
                    //                         font-size: clamp(17px, 3vw, 22px);
                    //                     }

                    //                     .rentyrd-address {
                    //                         display: grid;
                    //                         grid-template-columns: repeat(2, 1fr);
                    //                         grid-gap: 1rem;
                    //                         border-bottom: 2px solid gray;
                    //                         padding: 0.5rem 0;

                    //                         p {
                    //                             color: #fff;
                    //                             font-size: clamp(12px, 3vw, 16px);

                    //                             &:last-child {
                    //                                 text-align: right;
                    //                             }
                    //                         }
                    //                     }

                    //                     .rentyrd-rates {
                    //                         display: grid;
                    //                         grid-template-columns: repeat(3, 1fr);
                    //                         grid-gap: 0;
                    //                         padding: 0.5rem 0;

                    //                         .rentyrd-rates-item {
                    //                             display: flex;
                    //                             justify-content: center;

                    //                             &:nth-child(2) {
                    //                                 border-left: 2px solid gray;
                    //                                 border-right: 2px solid gray;
                    //                             }

                    //                             p {
                    //                                 color: #fff;
                    //                                 font-size: clamp(14px, 3vw, 18px);
                    //                                 font-family: "GothamBold" !important;
                    //                                 display: flex;
                    //                                 align-items: baseline;

                    //                                 .rentyrd-rates-span {
                    //                                     color: #faa61a;
                    //                                     font-size: clamp(9px, 3vw, 14px);
                    //                                     font-family: "Gotham" !important;
                    //                                 }
                    //                             }
                    //                         }


                    //                     }
                    //                 }
                    //             }

                    //         }
                    //     }
                    // }

                    .edit-listing {
                        margin: 1.5rem 0;

                        .edit-listing-btn {
                            color: #fff;
                            background-color: #149247;
                            width: 100%;
                            font-size: clamp(18px, 3vw, 22px);
                            font-weight: 700;
                            padding: 0;
                            border-radius: 8px;
                        }
                    }

                    .active-rental {
                        margin: 1rem 0 0 0;

                        .active-rental-heading {
                            color: #faa61a;
                            font-size: clamp(20px, 3vw, 35px);
                            font-family: "GothamBold" !important;
                        }
                    }

                    .rentyrd-order {
                        .rentyrd-order-id {
                            color: #faa61a;
                            text-decoration-color: #faa61a;
                        }

                        .rentyrd-dates-heading {
                            color: #fff;
                            font-size: clamp(17px, 3vw, 22px);
                            font-family: 'GothamBold' !important;
                            margin: 0.5rem 0;
                        }

                        .rentyrd-dates {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            align-items: center;

                            .rentyrd-dates-item.rental-dates {
                                p {
                                    display: grid;
                                    grid-template-columns: 0.3fr auto;
                                    // align-items: center;
                                    align-items: baseline;
                                    grid-gap: 10px;
                                    width: 100%;
                                    color: #fff;
                                    margin: 0.2rem 0;
                                }
                            }

                            .rentyrd-dates-item.renter-detail {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                gap: 1rem;

                                .ro-img {
                                    img {
                                        display: block;
                                        width: 55px;
                                        height: 55px;
                                        max-height: 55px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                        border: 2px solid #fff;
                                    }
                                }

                                .ro-name {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.5rem;

                                    p {
                                        display: flex;
                                        align-items: center;
                                        gap: 0.5rem;

                                        span {
                                            &:first-child {
                                                color: #fff;
                                            }

                                            &:last-child {
                                                color: #faa61a;
                                            }
                                        }
                                    }

                                    .message-renter {
                                        color: #faa61a;
                                        text-decoration-color: #faa61a;
                                        font-size: clamp(14px, 3vw, 18px);
                                    }
                                }
                            }
                        }
                    }

                    .rentyrd-order-status {
                        display: flex;
                        gap: 5px;
                        flex-direction: column;
                        align-items: center;

                    }

                    .rentyrd-order-links {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        align-items: center;
                        margin: 1rem 0;

                        a {
                            color: #fff;
                            text-decoration-color: #fff;
                            font-size: clamp(14px, 3vw, 18px);
                        }
                    }

                    .edit-listing-options {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 2rem;

                        .edit-listing-options-item {
                            display: flex;
                            flex-direction: column;

                            a {
                                color: #fff;
                                text-decoration-color: #fff;
                                font-size: clamp(16px, 3vw, 20px);
                                margin: 0.2rem 0;
                            }

                            // &:first-child {
                            // }

                            // &:last-child {}
                        }
                    }
                }

            }
        }
    }
}

.active-rental.edit-listing-heading {
    margin-top: 2.5rem !important;
}

@media screen and (max-width:1600px) {
    .rentyrd-main {
        width: 95% !important;
    }

        .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:first-child .rentyrd-card-img img {
            max-height: 170px;
        }
}

@media screen and (max-width:1400px) {
    // .rentyrd-grid-item.rentyrd-right.rentyrd-left-float.chat-box {
    //     position: relative;
    //     height: 490px !important;
    //     overflow: hidden;
    // }

    .rentyrd-main {
        width: 95% !important;
    }

    .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .chat-box {
        position: relative;
        height: calc(100vh - 15rem) !important;
        overflow: hidden;
    }
}

@media screen and (max-width:1200px) {
    .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .chat-box {
        position: relative;
        height: calc(100vh - 15rem) !important;
        overflow: hidden;
    }
        .rentyrd-main {
            // width: 80% !important;
                width: 100% !important;
            }
        
            .rentyrd-main .rentyrd-inner .rentyrd-grid {
                grid-template-columns: repeat(1, 1fr) !important;
                grid-gap: 2rem !important;
            }
    // .rentyrd-main {
    //     width: 100% !important;
    // }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p {
        font-size: clamp(14px, 3vw, 12px) !important;

    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-address p {
        color: #fff;
        font-size: clamp(12px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-type {
        font-size: clamp(17px, 3vw, 16px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-model {
        font-size: clamp(18px, 3vw, 18px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-order .rentyrd-dates .rentyrd-dates-item.rental-dates p {
        grid-template-columns: 0.4fr 1.6fr !important;
        font-size: 12px !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .active-rental .active-rental-heading {
        font-size: clamp(20px, 3vw, 29px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .edit-listing-options .edit-listing-options-item a {
        font-size: clamp(16px, 3vw, 16px) !important;
        margin: 0.3rem 0 !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-address p {
        font-size: clamp(12px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-model {
        font-size: clamp(18px, 3vw, 18px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-type {
        font-size: clamp(14px, 3vw, 16px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p .rentyrd-rates-span {
        font-size: clamp(9px, 3vw, 10px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p .rentyrd-rates-span {
        font-size: clamp(9px, 3vw, 10px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p {
        font-size: clamp(14px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .card-uid {
        font-size: clamp(14px, 3vw, 14px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:first-child .rentyrd-card-img p {
        border-radius: 9px !important;
        top: -11px !important;
        font-size: 12px !important;
    }

    .rentyrd-grid-item.rentyrd-left.notify-left .rentyrd-notify-card .rentyrd-notify-img img {
        width: 80px !important;
        height: 80px !important;
        max-height: 80px !important;
    }

    .rentyrd-grid-item.rentyrd-left.notify-left .rentyrd-notify-card .rentyrd-notify-grid .rentyrd-notify-grid-item:first-child .received-from {
        font-size: clamp(12px, 3vw, 14px) !important;
    }

    .rentyrd-grid-item.rentyrd-left.notify-left .rentyrd-notify-card .rentyrd-notify-grid .rentyrd-notify-grid-item p {
        font-size: clamp(12px, 3vw, 14px) !important;
    }

    .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .chat-box .chats .chat-not-me p {
        font-size: clamp(14px, 3vw, 14px) !important;
    }

    .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .chat-box .chats .chat-me p {
        font-size: clamp(14px, 3vw, 14px) !important;
    }

    .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .chat-box .chats .chat-not-me p span {
        font-size: clamp(10px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .equipment-details-heading p {
        font-size: clamp(20px, 3vw, 28px) !important;
    }
}

@media screen and (max-width:1024px) {
    .rentyrd-main {
            width: 100% !important;
        }
    .rentyrd-main .rentyrd-inner .rentyrd-grid {
            grid-template-columns: repeat(1, 1fr) !important;
            grid-gap: 2rem !important;
        }
    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p {
        font-size: clamp(12px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-address p {
        color: #fff;
        font-size: clamp(12px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-type {
        font-size: clamp(17px, 3vw, 16px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-model {
        font-size: clamp(18px, 3vw, 18px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-order .rentyrd-dates .rentyrd-dates-item.rental-dates p {
        grid-template-columns: 0.4fr 1.6fr !important;
        font-size: 12px !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .active-rental .active-rental-heading {
        font-size: clamp(20px, 3vw, 29px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .edit-listing-options .edit-listing-options-item a {
        font-size: clamp(16px, 3vw, 16px) !important;
        margin: 0.3rem 0 !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-address p {
        font-size: clamp(12px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-model {
        font-size: clamp(18px, 3vw, 18px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .vehicle-type {
        font-size: clamp(14px, 3vw, 16px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-right .rentyrd-left-float .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p .rentyrd-rates-span {
        font-size: clamp(9px, 3vw, 10px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p .rentyrd-rates-span {
        font-size: clamp(9px, 3vw, 10px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p {
        font-size: clamp(14px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .card-uid {
        font-size: clamp(14px, 3vw, 14px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:first-child .rentyrd-card-img p {
        border-radius: 9px !important;
        top: -11px !important;
        font-size: 12px !important;
    }

        .rentyrd-grid-item.rentyrd-left.notify-left .rentyrd-notify-card .rentyrd-notify-grid {
            grid-gap: 0.5rem !important;
            padding: 1rem;
        }

                .rentyrd-grid-item.rentyrd-left.notify-left .rentyrd-notify-card .rentyrd-notify-img img {
                    width: 70px !important;
                    height: 70px !important;
                    max-height: 80px !important;
                }
}


@media screen and (max-width:992px) {
    .rentyrd-main {
        // width: 80% !important;
        width: 100% !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 1rem !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-top-grid .rentyrd-top-grid-item:last-child .rentyrd-menu .rentyrd-menu-link p {
        font-size: clamp(12px, 3vw, 12px) !important;
    }
}

@media screen and (max-width:768px) {
    .rentyrd-main {
        width: 100% !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-top-grid .rentyrd-top-grid-item:last-child .rentyrd-menu .rentyrd-menu-link p {
        font-size: clamp(12px, 3vw, 12px) !important;
    }
}

@media screen and (max-width:576px) {
    .rentyrd-main {
        width: 100% !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-address p {
        font-size: clamp(10px, 3vw, 10px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:last-child .rentyrd-right .rentyrd-rates .rentyrd-rates-item p {
        font-size: clamp(12px, 3vw, 12px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .card-uid {
        font-size: clamp(12px, 3vw, 14px) !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card {
        padding: 1rem 0.5rem 0.5rem 0.5rem !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-top-grid .rentyrd-top-grid-item:last-child .rentyrd-menu .rentyrd-menu-link {
        padding: 0 0.25rem 0rem 0.25rem !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-top-grid .rentyrd-top-grid-item:first-child .rentyrd-renter-img img {
        width: 40px !important;
        height: 40px !important;
        max-height: 40px !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-top-grid {
        grid-gap: 0.5rem !important;
    }

    .rentyrd-main .rentyrd-inner .rentyrd-top-grid .rentyrd-top-grid-item:last-child .rentyrd-menu {
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media screen and (max-width:320px) {
    .rentyrd-main {
        width: 100% !important;
    }
}








// -------------------Notifications------------------//

.rentyrd-grid.notify-grid {
    margin-top: 2rem 0 !important;
}


.rentyrd-grid-item.rentyrd-left.notify-left {
    // background-image: url('../../assets/images/banner_img.png');

    .rentyrd-notify-card {
        display: grid;
        grid-template-columns: auto 3fr;
        grid-gap: 0rem;
        align-items: center;
        padding: 0.5rem;

        &:hover {
            outline: 2px solid #faa61a;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9);
            // padding: 2rem 1rem 0.5rem 1rem;
        }

        .rentyrd-notify-img {
            img {
                display: block;
                width: 100px;
                height: 100px;
                max-height: 100px;
                border-radius: 17px;
            }
        }

        .rentyrd-notify-grid {
            // display: flex;
            // justify-content: space-between;
            // gap: 1rem;
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 1rem;
            align-items: center;
            position: relative;
            padding: 1rem;

            .rentyrd-notify-grid-item {
                p {
                    color: #fff;
                }

                &:first-child {
                    .rentyrd-notify-grid-msg {
                        color: #faa61a;
                        display: flex;
                        flex-direction: column;
                        line-height: normal;
                    }

                    .received-from {
                        color: #149247;
                        font-size: clamp(16px, 3vw, 22px);
                        font-family: 'GothamBold' !important;
                    }

                    p {
                        font-size: clamp(15px, 3vw, 20px);
                        font-family: 'Gotham' !important;
                        flex-wrap: wrap;
                        // word-break: break-word;
                    }
                }
            }

            .notify-number {
                position: absolute;
                right: 10px;
                // top: 10px;
                top: 7px;
                background-color: red;
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 12px;
                color: #fff;
            }

        }
    }
}

.rentyrd-grid-item.rentyrd-right {
    .rentyrd-left-float {
        .chat-name {
                text-align: center;
                // position: absolute;
                // top: 0;
                width: 100%;
                margin-bottom: 1.5rem 0rem;
        
                p {
                    color: #faa61a;
                    font-size: clamp(16px, 3vw, 20px);
                }
            }
        .chat-box {
            position: relative;
            height: calc(100vh - 16rem) !important;
            overflow: hidden;
            // padding-bottom: 8rem;
            

            .chats {
                overflow-y: scroll;
                height: 83%;
                // height: 600px;
                // padding-bottom: 5rem;

                .chat-not-me {
                    display: flex;
                    justify-content: flex-start;
                    width: 100%;
                    margin: 1rem 0;
                    word-break: break-word;
                    white-space: pre-line;
                    p {
                        background-color: #B8B9BB;
                        padding: 0.5rem 1rem;
                        border-radius: 15px;
                        width: fit-content;
                        // font-size: clamp(16px, 3vw, 22px);
                        font-size: clamp(14px,3vw, 16px);
                        color: #111111;
                        display: flex;
                        flex-direction: column;
                        width: 65%;
                        flex-wrap: wrap;
                        // word-break: break-word;
                        span {
                            font-size: clamp(12px, 3vw, 14px) !important;
                        }

                    }
                }

                .chat-me {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    margin: 1rem 0;
                    word-break: break-word;
                    white-space: pre-line;
                    p {
                        background-color: #0AB720;
                        padding: 0.5rem 1rem;
                        border-radius: 15px;
                        // width: fit-content;
                        width: 65%;
                        // font-size: clamp(16px, 3vw, 22px);
                        font-size: clamp(14px,3vw, 16px);
                        color: #fff;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        // word-break: break-word;

                        span {
                            font-size: clamp(12px, 3vw, 14px) !important;
                        }
                    }
                }
            }



            .chat-msg-box {
                background-color: #808080;
                padding: 1rem;
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                // position: absolute;
                // width: 100%;
                width: 95%;
                bottom: 0 !important;
                position: absolute;

                .type-msg {
                    &::placeholder {
                        padding-left: 2rem !important;
                        font-weight: bold;
                        opacity: 0.5;
                        color: red !important;
                    }
                }

                .send-btn {
                    color: #faa61a;
                    width: fit-content;
                    font-size: clamp(14px, 3vw, 20px);
                    font-family: 'GothamBold';
                }
            }
        }

    }
}

.chats-inner {
    padding: 0 0.4rem;
}




.chat-btn-grp {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0.5rem 0 1rem 0;

    .accept-btn{
        background-color: #007e32 !important;
        padding: 5px 13px !important;
        border-radius: 5px !important;
    }   
    .reject-btn{
        background-color: rgb(161, 1, 1) !important;
        padding: 5px 13px !important;
        border-radius: 5px !important;
    }  

    .accepted-btn{
        background-color: #007e32;
        padding: 5px 13px !important;
        border-radius: 5px !important;
    }   
    .rejected-btn{
        background-color: rgb(161, 1, 1);
        padding: 5px 13px !important;
        border-radius: 5px !important;
    } 
}

.rentyrd-notify-grid-item.MuiBox-root.css-0 {
    word-break: break-word;
}

.notification-timer-div {
    margin-bottom: .5rem;
    padding: 2px 4px;
}
.notification-timer-div > :nth-child(1) {
    font-weight: 600;
}
.notification-timer-div > :nth-child(3) {
    font-weight: 600;
}
.notification-timer-div > :nth-child(5) {
    font-weight: 600;
}
