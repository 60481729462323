.hw {
    width: 60%;
    margin: -1rem auto 0 auto !important;
    padding: 0rem !important;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;

    @media (min-width: 2001px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 2000px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1800px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1700px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1600px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1500px) {
        width: 90% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1400px) {
        width: 90% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1300px) {
        width: 100% !important;
    }

    @media (max-width: 1024px) {
        width: 100% !important;
    }

    @media (max-width: 576px) {
        margin-top: -2rem !important;
    }
}

.hw-tab {
    margin: 0 0 2.5rem 0;
}

.tab-btn-grp {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    align-items: flex-start;
    margin: 1rem 0;
}

.tab-button {
    font-family: "GothamBold" !important;
    color: #707070 !important;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: clamp(20px, 3vw, 34px) !important;
    padding-bottom: 7rem !important;
    border-radius: 10px !important;

    &:hover {
        background-color: #47463E !important;
    }

    .tab-span-y {
        color: #707070;
        text-transform: lowercase;


    }
}

@media (max-width:992px) {

    span.tab-span-y {
        display: none !important;
    }

    .tab-span-y.tab-span-2 {
        display: none !important;
    }

    .tab-span-y.tab-span-3 {
        display: none !important;
    }
}

.tab-button.active {
    font-family: "GothamBold" !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: clamp(20px, 3vw, 34px) !important;
    background-color: #47463E;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
    padding-bottom: 7rem !important;
    border-radius: 10px !important;

    &:hover {
        background-color: #47463E;
    }

    .tab-span-y {
        color: #faa61a;
        text-transform: lowercase;
    }
}

.tab-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    align-items: flex-start;
    padding: 1rem 1rem 2rem 1rem;
    background: #2D2D2D;
    border: 3px solid #faa61a;
    border-radius: 17px;
    margin-top: -8.5rem;
    z-index: 100;
    position: relative;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);

    @media (max-width: 992px) {
        grid-template-columns: repeat(1, 1fr) !important;

    }


    .tab-grid-heading {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        height: 50px;

        @media (max-width: 768px) {
            height: 40px !important;
        }

        img.tabicon {
            display: block;
            width: 35px;
            height: auto;
            object-fit: contain;

            @media (max-width: 576px) {
                width: 22px !important;
            }

        }

        img.tabicon1 {
            display: block;
            width: 29px;
            height: auto;
            object-fit: contain;

            @media (max-width: 576px) {
                width: 22px !important;
            }

        }

        img.tabicon2 {
            display: block;
            width: 25px;
            height: auto;
            object-fit: contain;

            @media (max-width: 576px) {
                width: 22px !important;
            }

        }

        img.tabicon3 {
            display: block;
            width: 30px;
            height: auto;
            object-fit: contain;

            @media (max-width: 576px) {
                width: 22px !important;
            }

        }

        img.tabicon4 {
            display: block;
            width: 30px;
            height: auto;
            object-fit: contain;

            @media (max-width: 576px) {
                width: 22px !important;
            }

        }

        p {
            color: #faa61a;
            font-size: clamp(20px, 3vw, 25px);
            font-family: 'GothamBold' !important;

        }
    }

    .tab-grid-text {
        font-family: 'Montserrat' !important;
        color: #fff;
        font-weight: 400;
        font-size: clamp(14px, 3vw, 17.5px);
        margin-top: 0.5rem;
    }
}

.tab-cmn {
    padding: 1rem;
}

.hw-2-card {
    background-color: #47463E;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);
    // margin-top: 3rem;
}

.hw-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 4rem;
    padding: 1rem 1rem;

    @media (max-width: 992px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;
    }
}

.hw-card-heading {
    font-size: clamp(20px, 3vw, 35px) !important;
    font-family: 'GothamBold' !important;
    color: #fff;
    text-transform: uppercase;

    span {
        color: #faa61a;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 26px !important;
    }
}

.hw-card-text {
    color: #fff;
    font-size: clamp(16px, 3vw, 20px) !important;
    font-weight: 300 !important;
    font-family: 'Montserrat' !important;
}

.hw-card-link {
    color: #faa61a !important;
    text-decoration-color: #faa61a !important;
    font-size: clamp(16px, 3vw, 20px);
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-underline-offset: 2px;
    font-family: 'Montserrat';


}

.hw-card-contentL {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-end;
    text-align: right;
    padding-left: 1rem;

    @media (max-width: 992px) {
        align-items: center !important;
        text-align: center !important;
        padding-left: 0 !important;
    }

    @media (max-width: 768px) {
        gap: 1rem !important;
    }
}

.hw-card-contentR {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    text-align: left;
    padding-right: 1rem;

    @media (max-width: 992px) {
        align-items: center !important;
        text-align: center !important;
        padding-right: 0 !important;
    }
}

.hw-card-img {
    @media (max-width:768px) {
        order: 2;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 350px;
        object-fit: contain;

        @media (max-width: 576px) {
            width: 70% !important;
            margin: auto;
        }
    }
}

.hw-card-divider {
    background-color: #221F1F;
    height: 5px;
    width: 95%;
    border: none;
    box-sizing: border-box;
}

// .about {
//     display: flex;
//     align-items: center;
//     gap: 4rem;
//     justify-content: center;
//     margin: 5rem 0;

//     @media (max-width: 700px) {
//         gap: 1rem !important;
//     }


// }

.about {
    display: grid;
    align-items: center;
    gap: 4rem;
    justify-content: center;
    margin: 2rem 0;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media(min-width:993px) and (max-width:1200px) {
        gap: 4rem !important;
    }

    @media (max-width: 700px) {
        gap: 1rem !important;
        margin: 1.8rem 0 !important;
    }

    @media (max-width: 992px) {
        grid-template-columns: auto auto !important;
        gap: 0rem !important;
    }

    @media (max-width: 768px) {
        grid-template-columns: auto auto !important;
        gap: 0rem !important;
    }

    @media (max-width: 576px) {
        grid-template-columns: auto 0.8fr !important;
        gap: 10px !important;
    }

    .about-in {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.d-about-logo {
    display: block;
    width: 350px;
    height: auto;
    object-fit: contain;

    @media(min-width:993px) and (max-width:1200px) {
        width: 250px !important;
    }
}

.m-about-logo {
    display: none;
}

@media (max-width: 992px) {
    .m-about-logo {
        display: block !important;
        width: 120px;
        height: auto;
        object-fit: contain;
    }

    .d-about-logo {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .m-about-logo {
        display: block !important;
        width: 90px;
        height: auto;
        object-fit: contain;
    }

    .d-about-logo {
        display: none !important;
    }
}

.hw-card-link {
    cursor: pointer;
}