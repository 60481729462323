.testimonial-sec {
    position: relative;

    @media (max-width: 768px) {
        button.swiper-button-next {
            right: -23px !important;
        }

        button.swiper-button-prev {
            left: -23px !important;
        }
    }

    .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
        color: #faa61a !important;
    }


    .swiper {
        width: 100%;
        height: 300px !important;
        background-color: transparent;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;

        max-height: 300px !important;
        height: 300px !important;


    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 40px;
        height: 40px;
        background: #FAA61A;
        border-radius: 50%;

        @media (max-width:576px) {
            width: 25px !important;
            height: 25px !important;
        }

        &::after {
            color: #2D2D2D;
            font-size: 20px;
            font-weight: 700;
            display: none;

            @media (max-width:576px) {
                font-size: 14px !important;
            }
        }
    }

    .swiper-slide img {
        display: block;
        width: 150px !important;
        height: 150px !important;
        object-fit: cover;
        border-radius: 8px;
        border: 4px solid #faa61a;
    }

    .swiper-slide {
        width: 100% !important;
    }

    .mySwiper-mobile {
        display: none;
    }

    @media (max-width: 992px) {
        .mySwiper-desktop {
            display: none !important;
        }

        .mySwiper-mobile {
            display: flex !important;
            width: 100% !important;

            .swiper-wrapper {
                display: flex;
                // justify-content: center;
                // align-items: center;
                width: 100% !important;
            }


            .swiper-slide {
                width: 100% !important;
            }

            @media (max-width: 768px) {
                max-height: 400px !important;
                height: 200px !important;
                width: 100% !important;

                .swiper-slide {
                    height: 200px !important;
                    width: 100% !important;
                }
            }

            @media (max-width: 400px) {
                max-height: 400px !important;
                height: 200px !important;
                width: 100% !important;

                .swiper-slide {
                    height: 200px !important;
                    width: 100% !important;
                }
            }



            .cfeedback-name {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 0.5rem !important;

                @media (max-width: 576px) {
                    font-size: 14px !important;
                }
            }

            .cfeedback-content .cfeedback-text {
                font-size: 13px !important;
                text-align: left;

                @media (max-width: 992px) {
                    font-size: 14px !important;
                }

                @media (max-width: 576px) {
                    font-size: 11.5px !important;

                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .cfeedback-content .cfeedback-name .cfeedback-name-comp {
                font-size: 12px !important;
            }

            span.MuiRating-root.MuiRating-sizeMedium.MuiRating-readyOnly {
                font-size: 14px !important;
            }

            button.swiper-button-prev {
                left: -33px !important;
            }

            button.swiper-button-next {
                right: -33px !important;
            }
        }
    }

    .cfeedback-name-comp-m {
        font-size: 14px !important;
        color: #faa61a;
        font-style: italic;
    }

    .cfeedback-text-m {
        color: #fff;
        font-size: 13px !important;
        font-style: normal;
    }

    .cfeedback {
        // display: flex;
        align-items: center;
        gap: 2rem;
        // padding: 2rem 4rem;
        background-color: transparent;
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        box-sizing: border-box;

        width: 80%;

        @media (max-width: 768px) {
            width: 100% !important;
            gap: 1rem !important;
            align-items: flex-start !important;
        }

        @media (max-width: 768px) {
            padding: 1rem 2.5rem !important;
        }

        @media (max-width: 576px) {
            padding: 0rem 1rem 0rem 1rem !important;
        }

        .cfeedback-img {
            img {
                width: 150px;
                height: 150px;
                max-height: 150px;
                display: block;
                object-fit: cover;
                border: 2px solid #faa61a;
                border-radius: 5px;

                @media (max-width: 576px) {
                    width: 90px !important;
                    height: 90px !important;
                    max-height: 90px !important;
                }
            }
        }
    }

    .cfeedback-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;

        @media (max-width: 576px) {
            gap: 0.5rem !important;
        }

        .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
            color: #faa61a !important;
        }

        .cfeedback-text {
            color: #fff;
            font-size: clamp(14px, 3vw, 16px);
            font-family: 'Montserrat' !important;
            font-weight: 300;
            text-align: left;
        }

        .cfeedback-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            width: 100%;

            .cfeedback-name-comp {
                color: #faa61a;
                font-size: clamp(14px, 3vw, 16px);
                font-family: 'Montserrat';
                font-style: italic;
                text-align: left;
            }
        }
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
        background: #faa61a !important;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active-next {
        background: #fff !important;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active-next-next {
        background: #fff !important;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active-prev {
        background: #fff !important;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev {
        background: #fff !important;
    }

    .custom-navigation {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: space-between;
        width: 100%;
        z-index: 1001;

        @media (max-width: 992px) {
            display: none !important;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        background-color: #faa61a !important;
        border: none;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        font-size: 26px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'GothamBold' !important;

        @media (max-width: 576px) {
            font-size: 21px !important;
        }
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
        background-color: #faa61a !important;
    }

    .swiper-button-prev {
        margin-left: 10px;
    }

    .swiper-button-next {
        margin-right: 10px;
    }



}



.hiw-slider-div {
    .swiper {
        width: auto !important;
        max-width: 500px !important;
        max-height: 300px !important;
        height: max-content !important;
        background-color: transparent !important;

        @media (max-width:768px) {
            display: block !important;
            max-width: 230px !important;
        }
    }

    .swiper-slide {
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: transparent;

        width: 100% !important;
        height: 100px !important;

        @media (max-width: 992px) {
            height: 50px !important;
        }

        @media (max-width: 768px) {
            height: 50px !important;
        }

        p {
            font-size: clamp(18px, 3vw, 34px);
            color: #fff;
            font-family: "GothamBold" !important;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.bac-slider-div {
    .swiper {
        width: auto !important;
        max-width: 400px !important;
        max-height: 200px !important;
        height: max-content !important;
        background-color: transparent !important;

        @media (max-width:768px) {
            display: block !important;
        }
    }

    .swiper-slide {
        text-align: center;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent !important;

        @media (max-width: 768px) {
            justify-content: center !important;
        }

        width: 100% !important;
        height: auto !important;

        p {
            font-size: 25px;
            color: #faa61a;
            font-family: "GothamBold" !important;
            margin-top: 0;
            margin-bottom: 0;

            @media (max-width: 768px) {
                font-size: 18px !important;
            }
        }
    }
}