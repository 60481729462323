.rentyrd-dates.renter-modal {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.rentyrd-dates-item.rental-dates.renter-imag-box img {
    display: block;
    width: 70px;
    max-width: 70px;
    height: 70px;
    max-height: 70px;
    border-radius: 50%;
    border: 2px solid #fff;
    object-fit: cover;
}

.renter-text-p {
    display: flex;
    align-items: center;
    // gap: 1rem;

    // font-size: clamp(12px, 3vw , 18px) !important;
    font-size: 16px;
}

@media screen and (max-width:768px) {
    .renter-text-p {
        font-size: 14px !important;
    }
}

@media screen and (max-width:576px) {
    .renter-text-p {
        font-size: 13px !important;
    }

    .rentyrd-dates-item.rental-dates.renter-imag-box img {
        max-width: 60px !important;
        max-height: 60px !important;
    }

        // .login__main-div.edit-eqp .login__main-div__form.pupup-scroll.edit-eqp-inner {
        //     background-color: #2d2d2d;
        //     border: 5px solid #faa61a;
        //     border-radius: 10px;
        //     color: #fff;
        //     width: 280px !important;
        //     max-width: 300px !important;
        // }
}

@media screen and (max-width:320px) {
    .renter-text-p {
        font-size: 12px !important;
    }
}