.share-rental-yard-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .share-rental-yard-topleft {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      border: 4px solid #fff;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      max-height: 65px;
      max-width: 65px;
      object-fit: cover;
      display: block;
    }
    .share-rental-yard-leftp {
      color: #fff !important;
      font-size: clamp(20px, 3vw, 35px) !important;
      font-family: "GothamBold" !important;
      text-transform: capitalize;
    }
  }

  .share-rental-yard-topright {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;

    img {
      margin-bottom: 5px;
      // width: 30px;
      // height: 30px;
      max-height: 30px;
      max-width: 30px;
      object-fit: cover;
      display: block;
    }

    .share-rental-yard-rightp {
      color: #faa61a !important;
      font-size: clamp(18px, 3vw, 25px) !important;
      font-family: "GothamBold" !important;
      text-transform: capitalize;
    }
  }
}

.seemore-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
