.rentyrd-main {
  margin: 2rem auto;
  width: 75%;

  .rentyrd-inner {
    // width: 75%;
    // margin: 1rem auto;

    .rentyrd-top-grid {
      margin: 1rem 0;
      // display: flex;
      // align-items: center;
      // align-items: baseline;
      // gap: 1rem;
      display: grid;
      // grid-template-columns: 0.2fr 2.8fr;
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;

      .rentyrd-top-grid-item {
        &:first-child {
          .rentyrd-renter-img {
            img {
              display: block;
              width: 50px;
              height: 50px;
              max-height: 50px;
              object-fit: cover;
              border: 2px solid #fff;
              border-radius: 50%;
            }
          }
        }

        &:last-child {
          width: 100%;
          height: 100%;

          .rentyrd-menu {
            display: flex;
            align-items: center;
            border-bottom: 2px solid gray;
            height: 100%;

            .rentyrd-menu-link {
              position: relative;
              text-decoration: none;
              color: #fff;
              padding: 0 1rem 0rem 1rem;
              margin-bottom: 0.5rem;

              cursor: pointer;

              p {
                font-size: clamp(12px, 3vw, 18px) !important;
                font-family: "GothamBold" !important;
              }

              &:first-child {
                padding-left: 0;
              }

              &:hover {
                &::after {
                  position: absolute;
                  content: "";
                  width: 40%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #faa61a;
                  height: 4px;
                  left: 50%;
                  bottom: -7px;
                  transform: translate(-50%, 0);
                  margin-top: 10px;
                }
              }

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5),
              &:nth-child(6),
              &:nth-child(7),
              &:nth-child(8),
              &:nth-child(9) {
                border-left: 2px solid gray;
                // background: url() bottom right no-repeat;
              }

              .rentyrd-notification {
                position: absolute;
                top: 0;
                right: -15px;
                background-color: red;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .rentyrd-heading {
      margin: 1rem 0;
      display: flex;
      align-items: baseline;
      gap: 2rem;

      p {
        font-size: clamp(20px, 3vw, 40px);
        font-family: "GothamBold" !important;
        color: #fff;
      }

      .rentyrd-share-link {
        color: #faa61a;
        text-decoration-color: #faa61a;
        font-size: clamp(18px, 3vw, 22px);
        font-family: "Gotham" !important;
      }
    }

    .rentyrd-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4rem;
      position: relative;
      align-items: flex-start;

      .rentyrd-grid-item.rentyrd-left {
        .rentyrd-card {
          border: 2px solid #faa81a00;
          border-radius: 10px;
          box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0);
          padding: 2rem 1rem 0.5rem 1rem;

          &:hover {
            border: 2px solid #faa61a;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9);
            padding: 2rem 1rem 0.5rem 1rem;
          }

          .card-uid {
            color: #fff;
            font-size: clamp(14px, 3vw, 18px);
          }
        }
      }

      .rentyrd-grid-item.rentyrd-right {
        position: -webkit-sticky;
        position: sticky;
        top: 7rem;

        .rentyrd-left-float {
          .equipment-details-heading {
            border-bottom: 5px solid #faa61a;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;

            p {
              font-size: clamp(20px, 3vw, 35px);
              font-family: "GothamBold" !important;
              color: #fff;
              text-align: center;
            }
          }

          .edit-listing {
            margin: 1.5rem 0;

            .edit-listing-btn {
              color: #fff;
              background-color: #149247;
              width: 100%;
              font-size: clamp(18px, 3vw, 22px);
              font-weight: 700;
              padding: 0;
              border-radius: 8px;
            }
          }

          .active-rental {
            margin: 1rem 0 0 0;

            .active-rental-heading {
              color: #faa61a;
              font-size: clamp(20px, 3vw, 35px);
              font-family: "GothamBold" !important;
            }
          }

          .rentyrd-order {
            .rentyrd-order-id {
              color: #faa61a;
              text-decoration-color: #faa61a;
            }

            .rentyrd-dates-heading {
              color: #fff;
              font-size: clamp(17px, 3vw, 22px);
              font-family: "GothamBold" !important;
              margin: 0.5rem 0;
            }

            .rentyrd-dates {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              align-items: center;

              .rentyrd-dates-item.rental-dates {
                p {
                  display: grid;
                  grid-template-columns: 0.3fr auto;
                  align-items: center;
                  grid-gap: 10px;
                  width: 100%;
                  color: #fff;
                  margin: 0.2rem 0;
                }
              }

              .rentyrd-dates-item.renter-detail {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 1rem;

                .ro-img {
                  img {
                    display: block;
                    width: 55px;
                    height: 55px;
                    max-height: 55px;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 2px solid #fff;
                  }
                }

                .ro-name {
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;

                  p {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    span {
                      &:first-child {
                        color: #fff;
                      }

                      &:last-child {
                        color: #faa61a;
                      }
                    }
                  }

                  .message-renter {
                    color: #faa61a;
                    text-decoration-color: #faa61a;
                    font-size: clamp(14px, 3vw, 18px);
                  }
                }
              }
            }
          }

          .rentyrd-order-status {
            display: flex;
            gap: 5px;
            align-items: center;
            flex-direction: column;

            p {
              // &:first-child {
              //     color: #faa61a;
              //     font-family: 'GothamBold' !important;

              //     .rentyrd-order-status-alert {
              //         color: red;
              //     }
              // }

              // &:last-child {
              //     color: #fff;
              //     background-color: #149247;
              //     border-radius: 6.5px;
              //     font-size: clamp(10px, 3vw, 12px);
              //     padding: 2px 7px;
              //     font-family: 'GothamBold' !important;
              // }
            }
          }

          .rentyrd-order-links {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            margin: 1rem 0;

            a {
              color: #fff;
              text-decoration-color: #fff;
              font-size: clamp(14px, 3vw, 18px);
            }
          }

          .edit-listing-options {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;

            .edit-listing-options-item {
              display: flex;
              flex-direction: column;

              a {
                color: #fff;
                text-decoration-color: #fff;
                font-size: clamp(16px, 3vw, 20px);
                margin: 0.2rem 0;
              }

              &:first-child {
                // display: flex;
                // flex-direction: column;
              }

              &:last-child {
              }
            }
          }
        }
      }
    }
  }
}

.active-rental.edit-listing-heading {
  margin-top: 2.5rem !important;
}

@media screen and (max-width: 1600px) {
  .rentyrd-main {
    width: 95% !important;
  }

  // .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:first-child .rentyrd-card-img .eqppmnt-img {
  //     width: 100%;
  //     height: 100%;
  //     max-height: 170px !important;
  //     object-fit: cover;
  //     display: block;
  // }
}

@media screen and (max-width: 1400px) {
  .rentyrd-main {
    width: 95% !important;
  }

  // .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:first-child .rentyrd-card-img .eqppmnt-img {
  //     width: 100%;
  //     height: 100%;
  //     max-height: 170px !important;
  //     object-fit: cover;
  //     display: block;
  // }

  .rentyrd-card-grid .rentyrd-card-item-left .rentyrd-card-img .eqppmnt-img {
    width: 170px !important;
    height: 150px !important;
    max-height: 150px !important;
    object-fit: cover;
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .thumbs-up-grid {
    grid-template-columns: 1fr !important;
  }
  .thumbs-up-grid {
    // .rentyrd-card {
    //     height: 200px;
    // }
  }

  // .rentyrd-main .rentyrd-inner .rentyrd-grid .rentyrd-grid-item.rentyrd-left .rentyrd-card .rentyrd-card-grid .rentyrd-card-item:first-child .rentyrd-card-img {
  //     position: relative;
  //     height: 100%;
  //     max-height: 135px !important;
  // }

  .rentyrd-main {
    width: 95% !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:first-child
    .rentyrd-card-img
    img {
    // width: 150px !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p {
    font-size: clamp(14px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-address
    p {
    color: #fff;
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-type {
    font-size: clamp(17px, 3vw, 16px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-model {
    font-size: clamp(18px, 3vw, 18px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-order
    .rentyrd-dates
    .rentyrd-dates-item.rental-dates
    p {
    grid-template-columns: 0.4fr 1.6fr !important;
    font-size: 14px !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .active-rental
    .active-rental-heading {
    font-size: clamp(20px, 3vw, 29px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .edit-listing-options
    .edit-listing-options-item
    a {
    font-size: clamp(16px, 3vw, 16px) !important;
    margin: 0.3rem 0 !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-address
    p {
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-model {
    font-size: clamp(18px, 3vw, 18px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-type {
    font-size: clamp(14px, 3vw, 16px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p
    .rentyrd-rates-span {
    font-size: clamp(9px, 3vw, 10px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p
    .rentyrd-rates-span {
    font-size: clamp(9px, 3vw, 10px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p {
    font-size: clamp(14px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .card-uid {
    font-size: clamp(14px, 3vw, 14px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:first-child
    .rentyrd-card-img
    p {
    border-radius: 9px !important;
    top: -11px !important;
    font-size: 12px !important;
  }

  .rentyrd-grid-item.rentyrd-left.notify-left
    .rentyrd-notify-card
    .rentyrd-notify-img
    img {
    width: 80px !important;
    height: 80px !important;
    max-height: 80px !important;
  }

  .rentyrd-grid-item.rentyrd-left.notify-left
    .rentyrd-notify-card
    .rentyrd-notify-grid
    .rentyrd-notify-grid-item:first-child
    .received-from {
    font-size: clamp(12px, 3vw, 14px) !important;
  }

  .rentyrd-grid-item.rentyrd-left.notify-left
    .rentyrd-notify-card
    .rentyrd-notify-grid
    .rentyrd-notify-grid-item
    p {
    font-size: clamp(12px, 3vw, 14px) !important;
  }

  .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .chat-box
    .chats
    .chat-not-me
    p {
    font-size: clamp(14px, 3vw, 14px) !important;
  }

  .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .chat-box
    .chats
    .chat-me
    p {
    font-size: clamp(14px, 3vw, 14px) !important;
  }

  .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .chat-box
    .chats
    .chat-not-me
    p
    span {
    font-size: clamp(10px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .equipment-details-heading
    p {
    font-size: clamp(20px, 3vw, 28px) !important;
  }
}

@media screen and (max-width: 1024px) {
  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p {
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-address
    p {
    color: #fff;
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-type {
    font-size: clamp(17px, 3vw, 16px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-model {
    font-size: clamp(18px, 3vw, 18px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-order
    .rentyrd-dates
    .rentyrd-dates-item.rental-dates
    p {
    grid-template-columns: 0.4fr 1.6fr !important;
    font-size: 14px !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .active-rental
    .active-rental-heading {
    font-size: clamp(20px, 3vw, 29px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .edit-listing-options
    .edit-listing-options-item
    a {
    font-size: clamp(16px, 3vw, 16px) !important;
    margin: 0.3rem 0 !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-address
    p {
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-model {
    font-size: clamp(18px, 3vw, 18px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .vehicle-type {
    font-size: clamp(14px, 3vw, 16px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-right
    .rentyrd-left-float
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p
    .rentyrd-rates-span {
    font-size: clamp(9px, 3vw, 10px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p
    .rentyrd-rates-span {
    font-size: clamp(9px, 3vw, 10px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p {
    font-size: clamp(14px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .card-uid {
    font-size: clamp(14px, 3vw, 14px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:first-child
    .rentyrd-card-img
    p {
    border-radius: 9px !important;
    top: -11px !important;
    font-size: 12px !important;
  }

  .rentyrd-grid-item.rentyrd-left.notify-left
    .rentyrd-notify-card
    .rentyrd-notify-grid {
    grid-gap: 0.5rem !important;
    padding: 1rem;
  }

  .rentyrd-grid-item.rentyrd-left.notify-left
    .rentyrd-notify-card
    .rentyrd-notify-img
    img {
    width: 70px !important;
    height: 70px !important;
    max-height: 80px !important;
  }
}

@media screen and (max-width: 992px) {
  .thumbs-up-grid {
    grid-template-columns: repeat(1, 1fr) !important;

    // .rentyrd-card-grid {
    //     height: 160px;
    //     max-height: 160px;
    // }
  }

  .rentyrd-main {
    width: 70% !important;
  }

  .rentyrd-main .rentyrd-inner .rentyrd-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 1rem !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-top-grid
    .rentyrd-top-grid-item:last-child
    .rentyrd-menu
    .rentyrd-menu-link
    p {
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-card-grid .rentyrd-card-item-left .rentyrd-card-img .eqppmnt-img {
    width: 200px !important;
    height: 160px !important;
    max-height: 160px !important;
    object-fit: cover;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .login__main-div.edit-eqp
    .login__main-div__form.pupup-scroll.edit-eqp-inner
    .vehicle-div {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 1rem;
  }

  .login__main-div.edit-eqp
    .login__main-div__form.pupup-scroll.edit-eqp-inner
    .modal-dates-grid {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr !important;
    grid-gap: 0.5rem;
    align-items: center;
    font-size: clamp(10px, 3vw, 12px) !important;
  }

  .login__main-div.edit-eqp
    .login__main-div__form.pupup-scroll.edit-eqp-inner
    .owner-img-grid {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 1rem;
    align-items: center;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:first-child
    .rentyrd-card-img
    img {
    max-height: 140px !important;
    object-fit: cover;
    display: block;
  }

  .rentyrd-main {
    width: 80% !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-top-grid
    .rentyrd-top-grid-item:last-child
    .rentyrd-menu
    .rentyrd-menu-link
    p {
    font-size: clamp(12px, 3vw, 12px) !important;
  }
  .rentyrd-card-grid .rentyrd-card-item-left .rentyrd-card-img .eqppmnt-img {
    width: 160px !important;
    height: 140px !important;
    max-height: 140px !important;
    object-fit: cover;
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .login__main-div__form.edit-eqp-inner.chat-popup-inner {
    background-color: #2d2d2d;
    width: 90% !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:first-child
    .rentyrd-card-img
    img {
    max-height: 120px !important;
  }

  .rentyrd-main {
    width: 95% !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-address
    p {
    font-size: clamp(10px, 3vw, 10px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid
    .rentyrd-card-item:last-child
    .rentyrd-right
    .rentyrd-rates
    .rentyrd-rates-item
    p {
    font-size: clamp(12px, 3vw, 12px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .card-uid {
    font-size: clamp(12px, 3vw, 14px) !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card {
    padding: 1rem 0.5rem 0.5rem 0.5rem !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-top-grid
    .rentyrd-top-grid-item:last-child
    .rentyrd-menu
    .rentyrd-menu-link {
    padding: 0 0.25rem 0rem 0.25rem !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-top-grid
    .rentyrd-top-grid-item:first-child
    .rentyrd-renter-img
    img {
    width: 40px !important;
    height: 40px !important;
    max-height: 40px !important;
  }

  .rentyrd-main .rentyrd-inner .rentyrd-top-grid {
    grid-gap: 0.5rem !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-top-grid
    .rentyrd-top-grid-item:last-child
    .rentyrd-menu {
    flex-wrap: wrap;
  }

  .drp-off-btn {
    background-image: url("../../assets/images/placeholder.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    // width: 150px !important;
    height: 100px !important;
    max-height: 100px !important;
    background-color: gray;
  }
}

@media screen and (max-width: 320px) {
  .login__main-div__form.edit-eqp-inner.chat-popup-inner {
    background-color: #2d2d2d;
    width: 90% !important;
  }

  .rentyrd-main {
    width: 95% !important;
  }

  .rentyrd-main
    .rentyrd-inner
    .rentyrd-grid
    .rentyrd-grid-item.rentyrd-left
    .rentyrd-card
    .rentyrd-card-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0.5rem !important;
  }
}

// -------------------Notifications------------------//

.rentyrd-grid.notify-grid {
  margin-top: 2rem 0 !important;
}

.rentyrd-grid-item.rentyrd-left.notify-left {
  // background-image: url('../../assets/images/banner_img.png');

  .rentyrd-notify-card {
    display: grid;
    grid-template-columns: auto 3fr;
    grid-gap: 0rem;
    align-items: center;
    padding: 0.5rem;

    &:hover {
      outline: 2px solid #faa61a;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9);
      // padding: 2rem 1rem 0.5rem 1rem;
    }

    .rentyrd-notify-img {
      img {
        display: block;
        width: 100px;
        height: 100px;
        max-height: 100px;
        border-radius: 17px;
      }
    }

    .rentyrd-notify-grid {
      // display: flex;
      // justify-content: space-between;
      // gap: 1rem;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1rem;
      align-items: center;
      position: relative;
      padding: 1rem;

      .rentyrd-notify-grid-item {
        p {
          color: #fff;
        }

        &:first-child {
          .rentyrd-notify-grid-msg {
            color: #faa61a;
            display: flex;
            flex-direction: column;
            line-height: normal;
          }

          .received-from {
            color: #149247;
            font-size: clamp(16px, 3vw, 22px);
            font-family: "GothamBold" !important;
          }

          p {
            font-size: clamp(15px, 3vw, 20px);
            font-family: "Gotham" !important;
          }
        }
      }

      .notify-number {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: red;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

// .rentyrd-grid-item.rentyrd-right {
//     .rentyrd-left-float {
//         .chat-box {
//             position: relative;
//             height: 600px;
//             overflow: hidden;
//             padding-bottom: 8rem;

//             .chat-name {
//                 text-align: center;
//                 // position: absolute;
//                 // top: 0;
//                 width: 100%;
//                 margin-bottom: 1.5rem 0rem;

//                 p {
//                     color: #faa61a;
//                     font-size: clamp(16px, 3vw, 20px);
//                 }
//             }

//             .chats {
//                 overflow-y: scroll;
//                 height: 85%;
//                 // height: 600px;
//                 // padding-bottom: 5rem;

//                 .chat-not-me {
//                     display: flex;
//                     justify-content: flex-start;
//                     width: 100%;
//                     margin: 1rem 0;

//                     p {
//                         background-color: #B8B9BB;
//                         padding: 0.5rem 1rem;
//                         border-radius: 15px;
//                         width: fit-content;
//                         font-size: clamp(16px, 3vw, 22px);
//                         color: #111111;
//                         display: flex;
//                         flex-direction: column;
//                         width: 55%;

//                         span {
//                             font-size: clamp(12px, 3vw, 14px) !important;
//                         }

//                     }
//                 }

//                 .chat-me {
//                     display: flex;
//                     justify-content: flex-end;
//                     width: 100%;
//                     margin: 1rem 0;

//                     p {
//                         background-color: #0AB720;
//                         padding: 0.5rem 1rem;
//                         border-radius: 15px;
//                         // width: fit-content;
//                         width: 55%;
//                         font-size: clamp(16px, 3vw, 22px);
//                         color: #fff;
//                         display: flex;
//                         flex-direction: column;

//                         span {
//                             font-size: clamp(12px, 3vw, 14px) !important;
//                         }
//                     }
//                 }
//             }

//             .chat-msg-box {
//                 background-color: #808080;
//                 padding: 1rem;
//                 display: grid;
//                 grid-template-columns: 1fr auto;
//                 align-items: center;
//                 // position: absolute;
//                 // width: 100%;
//                 width: 95%;
//                 bottom: 0;

//                 .type-msg {
//                     &::placeholder {
//                         padding-left: 2rem !important;
//                         font-weight: bold;
//                         opacity: 0.5;
//                         color: red !important;
//                     }
//                 }

//                 .send-btn {
//                     color: #faa61a;
//                     width: fit-content;
//                     font-size: clamp(14px, 3vw, 20px);
//                     font-family: 'GothamBold';
//                 }
//             }
//         }

//     }
// }

.empty-rent {
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: clamp(20px, 3vw, 25px) !important;

  span {
    margin: 0.5rem 0;

    &:first-child {
    }

    &:last-child {
      .rentyrd-menu-link {
        color: #faa61a;
        text-decoration-color: #faa61a;
      }
    }
  }
}

// -----modal
.login__main-div.edit-eqp {
  display: flex !important;
  border-radius: 10px !important;

  .login__main-div__form.pupup-scroll.edit-eqp-inner {
    background-color: #2d2d2d;
    border: 5px solid #faa61a;
    border-radius: 10px;
    color: #fff;

    .rate-list-grid {
      align-items: baseline !important;
    }

    h5 {
      margin-bottom: 1rem;
      color: #fff;
    }

    .vehicle-div {
      display: grid;
      grid-template-columns: 1fr 1.5fr !important;
      grid-gap: 2rem;
      margin-bottom: 1rem;

      // align-items: center;
      .vehicle-img {
        width: 100%;
        border: 3px solid white;
        display: block;

        img {
          display: block;
          width: 100%;
          height: 120px;
          max-height: 120px;
          object-fit: cover;
        }
      }
    }

    .owner-div.edit-eq-owner {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
      grid-gap: 0.5rem !important;
      margin-bottom: 1rem;
    }

    .owner-img-grid {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 0.5rem;
      align-items: center;

      .owner-img {
        width: 60px !important;
        height: 60px;
        border-radius: 1000px;
        border: 3px solid white;
        display: block;

        .img {
          width: 100% !important;
          height: 60px !important;
          max-height: 60px !important;
          max-width: 100% !important;
        }
      }
    }

    .owner-name {
      p {
        &:first-child {
          font-size: clamp(13px, 3vw, 18px) !important;
        }

        &:last-child {
        }
      }
    }

    .modal-dates-grid {
      display: grid;
      grid-template-columns: 0.3fr 1.7fr;
      grid-gap: 1rem;
      align-items: center;
      font-size: 15px;
    }

    .dash-black-border {
      border-bottom: 2px dotted #fff !important;
    }

    .equipment-rate-div {
      margin-top: 1rem;
    }

    .g-total {
      p {
        font-family: "GothamBold" !important;
        font-size: clamp(18px, 3vw, 22px);
      }
    }
  }
}

.owner-img img {
  display: block;
  width: 100% !important;
  border-radius: 1000px;
  // max-height: 100% !important;
  max-height: 100px !important;
  object-fit: cover;
  // height: 100px !important;
  height: 100%;
}

.rentyrd-left {
  cursor: pointer;

  .rental-box-active {
    outline: 2px solid #faa61a !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 10px 0px rgb(233 147 35 / 90%) !important;
    // padding: 1.2rem 1rem 0.5rem 1rem !important;
  }
}

/****************************/

// .chat-popup {
//     .rentyrd-grid-item {
//         width: 550px;
//         // width: 400px;
//         // width: auto;
//     }
// }

.login__main-div__form.edit-eqp-inner.chat-popup-inner {
  background-color: #2d2d2d;
  width: 500px;
}

.new-img-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.popup-cmmn {
  background-color: #2d2d2d !important;
  border: 5px solid #faa61a;
  position: relative;
  max-width: 500px !important;
  width: 500px;
  max-height: 650px !important;
  border-radius: 10px !important;
}

@media screen and (max-width: 576px) {
  .popup-cmmn {
    width: 90% !important;
  }
}

.cross-btn {
  position: absolute !important;
  top: -17px !important;
  right: -17px !important;
  background-color: #faa61a !important;
  color: #fff !important;
  z-index: 9999;
}

.popup-white {
  background-color: #ffffff !important;
  border: 5px solid #faa61a;
  position: relative;
  width: 500px;
  max-width: 500px;
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px !important;
}

.img-box-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  margin: 1rem 0;
}

.drp-off-img {
  // background-image: url('../../assets/images/placeholder.png');
  // background-position: center;
  // background-size: 100%;
  // width: 150px;
  // height: 150px;
  // max-height: 150px;
}

.drp-off-item-inner {
  // background-image: url('../../assets/images/placeholder.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  // width: 150px !important;
  height: 150px;
  max-height: 150px;
  box-shadow: none !important;
  position: relative;
}

.drp-off-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100%;
}

.drp-off-btn {
  // background-image: url('../../assets/images/placeholder.png');
  background-position: center;
  background-repeat: no-repeat;
  // background-size: 100%;
  // height: 100%;
  // max-height: 100%;
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}

.drp-off-item-heading {
  font-size: clamp(14px, 3vw, 18px);
  text-align: center;
  margin-bottom: 0.3rem !important;
  color: #ffffff !important;
}

.drp-off-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 1rem 0;
}

label.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.drp-off-btn {
  width: 100%;
}

.add-more-upload-btn {
  font-family: "Gotham" !important;
  color: #ffffff !important;
  text-transform: inherit !important;
}

.drp-off-reading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: #ffffff !important;
}

.checkbox-label {
  span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    color: #ffffff !important;
  }

  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #ffffff !important;
  }
}

.addmore-img-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 0.5rem 0 1rem 0;
}

.addmore-img-grid-item-box {
  position: relative;
  border: 2px solid #faa61a;
}

img.addmore-img-grid-item {
  display: block;
  width: 100%;
  height: 100px;
  object-fit: contain;
  max-height: 100px;
}

.cross-btn.cross-btn-3 {
  width: 20px;
  height: 20px;
  top: -10px !important;
  right: -10px !important;

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    font-size: 14px;
  }
}

.checbox-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #2d2d2d !important;
}

.dash-white-border {
  border-bottom: 2px dotted #fff;
}

.rate-list-grid {
  display: flex;
  align-items: baseline !important;
}

.new-rate-box {
  margin-top: 1rem;
}

.popup-cmmn.popup-cmmn-modal {
  .rentyrd-card-img img {
    display: block;
    width: 100%;
    max-height: 100px;
    // object-fit: contain;
    object-fit: cover;
    border: 1px solid white;
  }

  .rentyrd-card .rentyrd-card-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
  }

  .rentyrd-order .rentyrd-dates {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 1.4fr 1fr;
    align-items: center;
    margin-bottom: 1rem;
  }

  .ro-img {
    img {
      display: block;
      width: 55px;
      height: 55px;
      max-height: 55px;
      border-radius: 50%;
      border: 3px solid #fff;
    }
  }

  .rentyrd-dates-item.renter-detail {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0.5rem;
    align-items: center;
  }

  .new-modal-heading {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }

  .rentyrd-dates-item.rental-dates {
    p {
      display: grid;
      grid-template-columns: 0.5fr 1.5fr !important;
      grid-gap: 0.5rem;
      align-items: center;
      font-size: clamp(10px, 3vw, 14px) !important;
    }
  }

  .rentyrd-order {
    margin-top: 1rem;
  }
}

.gem-ro-name {
  color: #faa61a !important;
}

.rentyrd-order {
  margin-bottom: 1rem;
}

.thumbs-up-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  .rentyrd-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .rentyrd-card {
    border: 2px solid #faa81a00;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0);
    padding: 1rem 1rem 0.5rem 1rem;
    // height: 200px;
    cursor: pointer;

    &:hover {
      border: 2px solid #faa61a;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9);
      padding: 1rem 1rem 0.5rem 1rem;
    }

    .rentyrd-card-grid {
      // display: flex;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
      // height: 160px;
      // max-height: 160px;

      .rentyrd-card-item {
        &:first-child {
          .rentyrd-card-img {
            position: relative;
            height: 100%;

            .equp-img {
              // width: 180px;
              width: 100%;
              height: 100%;
              // max-height: 100%;
              // height: 160px;
              max-height: 160px !important;
              object-fit: cover;

              // width: 220px;
              // height: 150px;
              // max-height: 150px;
              display: block;
            }

            p {
              border: 2px solid #000;
              width: auto;
              height: auto;
              position: absolute;
              background-color: #faa61a;
              color: #000;
              padding: 0px 8px;
              border-radius: 12px;
              top: -14px;
              right: -10px;
              font-family: "GothamBold" !important;
            }
          }
        }

        &:last-child {
          width: 100%;

          .rentyrd-right {
            .vehicle-model {
              color: #fff;
              font-size: clamp(18px, 3vw, 25px);
              font-family: "GothamBold" !important;
            }

            .vehicle-type {
              color: #faa61a;
              font-size: clamp(14px, 3vw, 20px);
              // font-family: 'GothamBold' !important;
            }

            .rentyrd-address {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 1rem;
              border-bottom: 2px solid gray;
              padding: 0.5rem 0;

              p {
                color: #fff;
                font-size: clamp(12px, 3vw, 14px);

                &:last-child {
                  text-align: right;
                }
              }
            }

            .rentyrd-rates {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 0;
              padding: 0.5rem 0;

              .rentyrd-rates-item {
                display: flex;
                justify-content: center;

                &:nth-child(2) {
                  border-left: 2px solid gray;
                  border-right: 2px solid gray;
                }

                p {
                  color: #fff;
                  font-size: clamp(14px, 3vw, 22px);
                  font-family: "GothamBold" !important;
                  display: flex;
                  align-items: baseline;

                  .rentyrd-rates-span {
                    color: #faa61a;
                    font-size: clamp(9px, 3vw, 14px);
                    font-family: "Gotham" !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .card-uid {
      color: #fff;
      font-size: clamp(14px, 3vw, 18px);
    }
  }
}

.thumbs-img {
  position: absolute;
  width: 30px;
  height: 30px;
  max-height: 20px;
  top: -10px;
  left: -10px;
  z-index: 1050;

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.thumbs-up-grid .rental-box-active {
  border: 2px solid #faa61a !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9) !important;
}

.rentyrd-renter-detail {
  margin: 1rem 0;

  .renter-detail {
    display: flex;
    align-items: center;
    gap: 1rem;

    .ro-img {
      img {
        display: block;
        width: 55px;
        height: 55px;
        max-height: 55px;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }

    .ro-name {
      width: 100%;

      p {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .message-renter {
        color: #faa61a;
        text-decoration-color: #faa61a;
        font-size: clamp(14px, 3vw, 18px);
      }
    }
  }
}

.update-status-link {
  font-size: clamp(10px, 3vw, 12px);
  font-family: "Gotham" !important;
  color: #faa61a;
  text-decoration-line: underline;
  text-decoration-color: #faa61a;
  text-transform: capitalize;
}

.od-status-green {
  padding: 2px 8px;
  background-color: #149247;
  color: #fff;
  border-radius: 6.5px;
  font-size: clamp(12px, 3vw, 16px);
  font-family: "GothamBold" !important;
  text-transform: uppercase;
}

.od-status-red {
  padding: 2px 8px;
  background-color: red;
  color: #fff;
  border-radius: 6.5px;
  font-size: clamp(12px, 3vw, 16px);
  font-family: "GothamBold" !important;
  text-transform: uppercase;
}

.od-status-yellow {
  padding: 2px 8px;
  background-color: #faa61a;
  color: #fff;
  border-radius: 6.5px;
  font-size: clamp(12px, 3vw, 16px);
  font-family: "GothamBold" !important;
  text-transform: uppercase;
}

.rentyrd-card-grid {
  display: flex;
  align-items: center;
  gap: 1rem;

  .rentyrd-card-item-left {
    .rentyrd-card-img {
      position: relative;
      width: 100%;
      height: 100%;

      .eqppmnt-img {
        width: 240px;
        height: 160px;
        max-height: 160px;
        object-fit: cover;

        display: block;
      }

      p {
        border: 2px solid #000;
        width: auto;
        height: auto;
        position: absolute;
        background-color: #faa61a;
        color: #000;
        padding: 0px 8px;
        border-radius: 12px;
        top: -14px;
        right: -10px;
        font-family: "GothamBold" !important;
      }
    }
  }

  .rentyrd-card-item-right {
    width: 100%;

    .rentyrd-right {
      .vehicle-model {
        color: #fff;
        font-size: clamp(18px, 3vw, 25px);
        font-family: "GothamBold" !important;
      }

      .vehicle-type {
        color: #faa61a;
        font-size: clamp(14px, 3vw, 20px);
      }

      .rentyrd-address {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        border-bottom: 2px solid gray;
        padding: 0.5rem 0;

        p {
          color: #fff;
          font-size: clamp(12px, 3vw, 14px);

          &:last-child {
            text-align: right;
          }
        }
      }

      .rentyrd-rates {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;
        padding: 0.5rem 0;

        .rentyrd-rates-item {
          display: flex;
          justify-content: center;

          &:nth-child(2) {
            border-left: 2px solid gray;
            border-right: 2px solid gray;
          }

          p {
            color: #fff;
            font-size: clamp(14px, 3vw, 22px);
            font-family: "GothamBold" !important;
            display: flex;
            align-items: baseline;

            .rentyrd-rates-span {
              color: #faa61a;
              font-size: clamp(9px, 3vw, 14px);
              font-family: "Gotham" !important;
            }
          }
        }
      }
    }
  }
}

// GPS
.rentyrd-card-bottom {
  display: flex;
  justify-content: space-between;
}

.rentyrd-card-bottom-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.gps-imei-id {
  font-family: "GothamBold" !important;
  color: #fff !important;
}

.gps-status {
  background-color: #d2322d;
  color: #fff !important;
  font-size: 12px !important;
  padding: 3px;
  border-radius: 3.5px;
  // border: 2px double #000;
  box-shadow: 2px 2px 0 0 #000, -2px -2px 0 0 gray;
}

.gps-status:hover {
  background-color: #962420;
}

.gps-modal-heading {
  display: flex;
  align-items: center;
  gap: 0rem;
  margin-bottom: 0.5rem;

  img.satelite-icon {
    width: 70px;
    height: auto;
    display: block;
    /* filter: grayscale(10%) !important; */
  }

  h5 {
    font-family: "GothamBold" !important;
    font-size: 32px !important;
  }
}

.gps-grid-left-heading {
  display: flex;
  flex-direction: column;
  text-align: right;

  p {
    font-size: 12px !important;
    color: #faa61a !important;
  }
}

.gps-heading {
  display: flex;
  // align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  align-items: center;
}

.gps-heading-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.gps-h {
  font-size: 30px !important;
  color: #fff !important;
  font-family: "GothamBold" !important;
}

.connected-link {
  color: #fff !important;
  border: 1px solid #faa61a;
  border-radius: 5px;
  font-size: 12px !important;
  font-family: "GothamBold" !important;
  text-transform: uppercase;
  padding: 5px 10px;
  width: fit-content;
}

.connected-assign-link {
  color: #faa61a !important;
  border: 1px solid #faa61a;
  border-radius: 5px;
  font-size: 12px !important;
  font-family: "GothamBold" !important;
  text-transform: uppercase;
  padding: 5px 10px;
  width: fit-content;
  text-align: center;
}

// .gps-card-top-content {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }

.gps-card-top-content {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr 0.5fr;
}

.request-more-gps-btn {
  background-color: #178a1d !important;
  font-family: "GothamBold" !important;
  padding: 5px 8px !important;
  font-size: 17px !important;
  line-height: 25px !important;
}

.request-more-gps-btn:hover {
  background-color: #01682c !important;
}

.request-more-gps-btn:focus {
  background-color: #01682c !important;
}

.gps-request-modal-btn {
  font-size: clamp(1em, 3vw, 1.2em) !important;
  background-color: #169245;
  font-family: "GothamBold" !important;
  margin: 1rem 0;
  border-radius: 10px !important;
  border: 2px solid #faa61a !important;
}

.gps-request-modal-btn:hover {
  background-color: #01682c !important;
}

.gps-request-modal-btn:focus {
  background-color: #01682c !important;
}

.select-dropdwn.gps-modal-select {
  background-color: #fff !important;
  color: #2e2e2e;
  border-radius: 10px;
  border: 2px solid #faa61a;
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #2d2d2d !important;
  }

  &:hover {
    background-color: #fff !important;
  }
}

.rentyrd-card-grayscale {
  &:hover {
    border: 2px solid gray !important;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px gray !important;
    padding: 2rem 1rem 0.5rem 1rem;
  }
}

.signin-btn.common-yellow-btn {
  &:hover {
    background-color: #c28214 !important;
  }
}

.login__main-div__form.popup-cmmn.popup-cmmn-modal.pupup-scroll.edit-eqp-inner.extend-rental {
  max-height: 100% !important;
}

.extend-rental-od {
  overflow-y: scroll;
  padding-right: 5px;
}

.rate-list-btn {
  margin: 1rem 0 0;
}

.equipment-details-heading.no-gps {
  position: relative;

  img.nogps-img {
    display: block;
    width: 100%;
    object-fit: contain;
    height: 550px;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rental-detail-div {
  position: sticky;
  top: 7rem;
}

.range-pick {
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #faa61a !important;
  }
}
.gps-range-model-heading {
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #faa61a !important;
  }
}
.gps-calender-popup {
  .popup-cmmn.popup-white {
    width: auto;
  }
  // .rmdp-wrapper {
  //   background-color: #2d2d2d;
  // }
  .signin-btn {
    margin: 8px 24px 0 0 !important;
  }
  .typography {
    margin-left: 24px;
    color: #fff !important;
  }
}

// .date-cal.filtercalendar {
//   .rmdp-shadow {
//     box-shadow: none !important;
//   }
// }

.bookedDate {
  background-color: #faa61a !important;
  color: #fff;
  border: 1px solid green;
}
