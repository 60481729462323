.login__main-div {
    display: grid !important;
    grid-template-columns: 1fr 2fr;
    width: 100%;
    align-items: center;
    // border-radius: 10px;
    background: #2e2e2e;
    // box-shadow: 0px 33px 35px 0px #2e2e2e70;
    box-shadow: 0px 33px 35px 0px rgb(0, 0, 0);
}

.float.social__login-link_btn {
    width: 100%;
}

.left-logo {
    // background-color: #2e2e2e;
    // background-color: #ffffff;
    background-color: #faa61a;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-copyright {
    text-align: center;
    margin: 2rem 0 0 0;
}

.left-logo img {
    width: 140px;
    margin: auto;
    background-color: #2e2e2e;
    padding: 1rem;
}

.login__main {
    // background-color: #2e2e2e;

    // background-color: #faa61a;
    display: flex;
    justify-content: center;
    // height: 100vh;
    align-items: center;
    outline: none;
    border: none;

    .login__main-div__form {
        height: auto;
        // width: 40%;
        padding: 2rem;
        background-color: #fff;
        // background-color: #faa61a;
        // background-color: #2e2e2e;
        // margin: 5rem 0;
        display: flex;
        flex-direction: column;
        // border: 5px solid #faa61a;
        // border-radius: 10px;
        // box-shadow: 0px 29px 37px 0px #2e2e2e;

        .login__text-fields {
            padding: 0.6em 1em;
            margin: 1rem 0;
            border: 1px solid #2e2e2e;
            // border-radius: 5px;
            // color: #faa61a !important;
        }

        .signin-btn {
            font-size: clamp(1em, 3vw, 1.2em);
            background-color: #169245;
            font-family: "GothamBold" !important;
            margin: 1rem 0;
            border-radius: 10px !important;

            &:hover {
                background-color: #01682c;
            }
        }

        .signup-btn {
            color: #2e2e2e;
            text-decoration-color: #2e2e2e;
        }

        .social__login {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 2rem 0 0 0;

            .social__login-link {
                text-decoration: none;
                // color: #2e2e2e;
                color: #fff;
                // background: #ffffff;
                background-color: #2e2e2e;
                padding: 0.7em 1.5em;
                // font-family: "GothamBold" !important;
                border-radius: 5px;
                // border: 1px solid #2e2e2e;
                // border: 1px solid #faa61a;

                // &:first-child {
                //     background-color: #ffffff;
                //     color: #2e2e2e;
                //     border: 1px solid #2e2e2e;
                // } 

                .social__login-link_btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .social__login-icons {
                        width: 25px;
                        height: auto;
                        margin-right: 0.5em;
                    }
                }



            }

        }



    }
}

.social__login .App {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .fb-btn,
    .google-btn {
        text-align: center !important;
        font-size: clamp(12px, 3vw, 14px) !important;
    }
}

.download-popups {
    width: -moz-fit-content !important;
    width: fit-content !important;
    outline: none !important;
    border: none !important;
}

@media screen and (max-width:1400px) {
    .left-logo img {
        width: 140px;
    }

    .login__main-div {
        width: 100%;
    }
}

@media screen and (max-width:1200px) {
    .login__main-div {
        width: 100%;
    }

    .left-logo img {
        width: 150px;
    }

    .login__main .login__main-div__form .social__login {
        margin: 1rem 0 0 0;
        flex-direction: column;
    }

    .login__main .login__main-div__form .social__login .social__login-link {
        margin: 0.5rem 0;
        font-size: 12px;
        width: 70%;
    }
}


@media screen and (max-width: 992px) {
    .login__main .login__main-div__form {
        padding: 1rem;
    }

    .login__main-div__form {
        width: 100%;
    }

    .login__main-div {
        display: flex !important;
        width: 100%;
    }

    .left-logo {
        display: none;
    }

    .login__main .login__main-div__form .social__login {
        flex-direction: column;
    }

    .login__main .login__main-div__form .social__login .social__login-link {
        width: 70%;
        margin: 0.5rem 0;
    }

    .login__main .login__main-div__form .social__login .social__login-link {
        color: #fff;
        font-size: 12px;
    }

    .login__main .login__main-div__form .social__login {
        margin: 0rem 0 0 0;
    }
}

@media screen and (max-width: 576px) {
    .login__main-div__form {
        width: 100%;
    }
}

@media screen and (max-width:320px) {
        .download-popups {
                width: 90% !important;
            }
                        .login__main-div {
                            display: flex !important;
                            width: 90% !important;
                        }
}

.pwd-field {
    position: relative;

    .pwd-eye {
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translate(-2%, -40%);
    }

    cursor: pointer;
}

.MuiFormLabel-asterisk,
.css-wgai2y-MuiFormLabel-asterisk {
    color: red !important;
}

.float {
    float: left;
}



// ---------------------------------------------------------
.login-main-div {
    border: 5px solid #faa61a;
    // background-color: #2d2d2d;
    background-color: #231F20;
    padding: 1rem 4rem;
    border-radius: 10px;
    width: 370px;
}

.welcome-heading {
    font-size: clamp(36px, 5vw, 56px) !important;
}

@media screen and (max-width: 576px) {
    .login-main-div {
            border: 5px solid #faa61a;
            // background-color: #2d2d2d;
            background-color: #231F20;
            padding: 1rem;
            border-radius: 10px;
            width: 300px;
        }
}


.login-with-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 2rem 0;
    gap: 1rem;

    .login-social-icons {
        display: flex;
        align-items: center;
        gap: 1rem;

        .login-icon-img {
            
            img {
                display: block;
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
        }
    }

}

.input-div {
    margin: 0.8rem 0;
    .eye-icon .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
        color: #505050 !important;
    }
}


.signin-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;

    .signin-btn {
        border-radius: 10px !important;
        background-color: #139245 !important;
        box-shadow: none !important;
    }
}

.signin-signup {
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    .signup-btn-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;

        .signup-btn {
            border-radius: 10px !important;
            background-color: #FBA618 !important;
            box-shadow: none !important;
            color: #505050;
        }
    }
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-gaabkq-MuiButtonBase-root-MuiIconButton-root {
    display: none !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-pebyq6 {
    display: none !important;
}

// ----------------------------------------------

.after-msg {
    border: 5px solid #faa61a;
    // background-color: #2d2d2d;
    background-color: #231F20;
    padding: 2rem;
    border-radius: 10px;
    width: 400px;
    text-align: center;
    color: #fff;

    .after-msg-inner {

        // font-weight: 400;
        

        .first-para {
            padding: 0 2rem;
            margin-top: 1rem;
            font-family: 'Gotham' !important;
        }

        .second-para {
            margin: 1rem 0;
            font-family: 'Gotham' !important;
        }

        .clickhere-link {
            color: #faa61a;
            text-decoration: underline;
            text-decoration-color: #faa61a;
            font-family: 'Gotham' !important;
        }

        .live-help-sec {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin: 1rem 0;

            img {
                display: block;
                width: 55px;
                height: 55px;
                max-height: 55px;
            }

            p {
                color: #fff;
                text-align: left;
                font-family: 'Gotham' !important;
            }
        }

        .close {
            display: flex;
            // gap: 0.5rem;
            align-items: center;
            justify-content: center;
            .close-content {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    cursor: pointer;
                    img {
                            display: block;
                            width: 20px;
                            height: 20px;
                        }
                    
                        p {
                            color: #faa61a;
                        }
                }
            
        }
    }
}

@media screen and (max-width:576px) {
    .after-msg {
            width: 90vw !important;
            padding: 1rem;
    }

                .after-msg-inner {
        
                    // font-weight: 400;
        
        
                    .first-para {
                        font-size: 12px !important;
                    }
        
                    .second-para {
                        font-size: 12px !important;
                    }
        
                    .clickhere-link {
                        font-size: 12px !important;
                    }
        
                    .live-help-sec {
                        p {
                            font-size: 12px !important;
                        }
                    }
        
                    .close {
        
                        p {
                            font-size: 12px !important;
                        }
                    }
                }
                }