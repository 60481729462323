.tn-popup {
    border-radius: 10px;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
        background: #2d2d2d;
        border: 4px solid #faa61a;
        // padding: 1rem;
    }


}

.tn-btngrp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    margin-bottom: 1rem;

    .decline-btn {
        color: #fff;
        font-size: 20px;
        font-family: 'GothamBold' !important;
        background-color: #c54144;
        line-height: 25px;
        padding: 0.5rem 1.5rem;
        text-transform: capitalize;

        &:hover {
            background-color: #a54344;
        }
    }

    .accept-btn {
        color: #fff;
        font-size: 20px;
        font-family: 'GothamBold' !important;
        background-color: #149248;
        line-height: 25px;
        padding: 0.5rem 1.5rem;
        text-transform: capitalize;

        &:hover {
            background-color: #0f7539;
        }

    }
}

.text-notification-p {
    color: #fff !important;
    font-size: 16px !important;
    text-align: justify;
    padding: 0 0.5rem;
}

.text-notification-btn {
    color: #fff;
    font-size: 20px;
    font-family: 'GothamBold' !important;
    background-color: #149248 !important;
    line-height: 25px;
    padding: 0.5rem 1.5rem;
    text-transform: capitalize;
    &:hover {
            background-color: #0f7539 !important;
        }
}