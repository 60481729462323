@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: "MontserratItalic";
  src: url("./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "GothamBold";
  src: url("./assets/fonts/Gotham-Bold.otf");
}

@font-face {
  font-family: "GothamBook";
  src: url("./assets/fonts/GothamBook.ttf");
}

@font-face {
  font-family: "GothamLight";
  src: url("./assets/fonts/GothamLight.ttf");
}

@font-face {
  font-family: "GothamThin";
  src: url("./assets/fonts/GothamThin.otf");
}

@font-face {
  font-family: "GothamXLight";
  src: url("./assets/fonts/GothamXLight.otf");
}

body.dark-theme {
  background-color: #2E2E2E;
}

// * {
//   box-sizing: border-box;
// }

body {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(#2E2E2E, 0.8);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #149247;
    outline: 1px solid #149247;
    border-radius: 2px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


