.new-img-gallery {
  width: 100%;
  box-sizing: border-box;
  display: block;

  .image-gallery-image {
    width: 100% !important;
    // height: 300px !important;
    max-height: 300px !important;
    background-color: #202020;
  }

}
