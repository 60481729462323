.req-sum-main {
    width: 50%;
    margin: auto;
}

@media (max-width:1300px) {
    .req-sum-main {
        width: 60% !important;
        margin: auto;
    }
}

@media (max-width:1200px) {
    .req-sum-main {
        width: 70% !important;
        margin: auto;
    }
}

@media (max-width:1100px) {
    .req-sum-main {
        width: 70% !important;
        margin: auto;
    }
}

@media (max-width:992px) {
    .req-sum-main {
        width: 80% !important;
        margin: auto;
    }
}

@media (max-width:768px) {
    .req-sum-main {
        width: 100% !important;
        margin: auto;
    }



    .req-msg {
        padding: 0rem !important;
    }
}

@media (max-width:576px) {
    .req-sum-grid {
        display: grid;
        grid-template-columns: 1fr !important;
        gap: 2rem;
        margin: 1rem 0;
    }
}

.req-sum-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 1rem 0;

    .req-sum-left {
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    .req-sum-right {
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }
}

.req-sum-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.req-sum-total {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.req-msg {
    padding: 1rem 0 1rem 0;
    // background-color: #3d3d3d;
    display: flex;
    flex-direction: column;
    gap: 1rem;


    .req-msg-box {
        background: #2e2e2e;
        padding: 1rem 0 1rem 0;

        p {
            color: #ffffff !important;
            text-align: left;

        }
    }

    .req-msg-btngrp {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 4rem;
        width: 90%;
        margin: 0.5rem 0;
    }

    .req-accpt-btn {
        background: #007e32;
        font-family: 'GothamBold' !important;
        font-size: 18px !important;
        color: #fff !important;
        width: 100%;
    }
    .req-accpt-btn:hover {
        background: #03933c;
    }

    .req-reject-btn {
        background: rgb(161, 1, 1);
        font-family: 'GothamBold' !important;
        font-size: 18px !important;
        color: #fff !important;
        width: 100%;
    }
    .req-reject-btn:hover {
        background: rgb(175, 26, 26);
    }

    .single-btn {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}