.item-container {
  width: 100%;  
}

.carousel-img {
  width: calc(100% - 4px);
  height: 195px;
  object-fit: cover;
  border: 2px solid black;
}

.carousel-item {
  width: 100% !important;
  height: auto !important;
  button {
    background-color: rgba($color: #E99323, $alpha: 0.7);
    svg {
      color: #fff !important;
    }
  }
}

.carousel-wrapper {
  position: relative;
}
.span-delivery-available {
  position: absolute;
  bottom: 10px;
  z-index: 1;
  left: 10px;
  color: white;
  background: black;
  border-radius: 5px;
  padding: 0 5px;
}

.item-make {
  font-size: 25px!important;
  font-weight: bold!important;
  color: white;
}

.item-model, .item-city {
  font-size: 18px!important;
  font-weight: bold!important;
  color: #e99323;
}

.item-city-wrapper {
  padding-bottom: 10px;
  border-bottom: 3px solid gray;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.span-item-details {
  color: #faa61a;
  background: black;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px 10px;
  height: 24px;
}

.item-value-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: baseline;
  // border-right: 3px solid gray;
  margin: 5px 0 5px 0;
  height: 100%;
  &:last-child {
    border: none;
  }
}
.item-price {
  color: white;
  span {
    font-size: 20px;
  }
}
.item-price-unit {
  color: #faa61a;
  margin-top: 4px!important;
}

.equipment-grid{
  margin-top: 0px !important
}

.item-value-content.item-value-content-numbers {
  border-right: 2px solid gray;
}

.item-value-content.item-value-content-numbers:last-child {
  border-right: none !important;
}

