.service-section {
    background-color: #faa61a;

    &__row {
        padding-top: 35px;
        display: flex;
    }
}

.img-fluid {
    // width: 80%;
}

.service-section__row,
.testimonial {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.service-item-link {
    &:hover {
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 1) !important;
    }
}

.service-item {
    height: 200px;
    border: 3px solid white;
    border-radius: 5px;
    background-color: #2E2E2E;
    display: flex;
    align-items: center;
    // margin: 1rem;
    padding: 1rem;



    &__content {
        &-title {
            color: #f9a519;
            text-transform: uppercase;
        }

        &-description {
            color: white;
        }
    }

    &__img {
        width: 75%;
        height: auto;
    }
}

.testimonial {
    display: flex;
    margin-top: 20px;
    align-items: center;

    &-img {
        width: 280px;
    }

    &-content {
        margin-left: 30px;
        text-align: left;
    }

    &-title {
        color: white;
        text-transform: uppercase;
        //   padding-bottom: 10px;
        border-bottom: 5px dashed #2E2E2E;
        margin-bottom: 10px !important;
    }

    &-description {
        color: white;
        font-size: 22px !important;
        white-space: break-spaces;
    }
}

@media only screen and (max-width: 1024px) {
    .equipments-wrapper {
        max-width: 960px;
    }

    .service-item {}

}

@media only screen and (max-width: 768px) {
    .service-item {
        height: 120px;
    }

    .testimonial {
        flex-direction: column-reverse;

        &-content {
            margin: 30px;
        }
    }

    .testimonial-img-div img {
        width: 70%;
    }

    .testimonial-title {
        font-size: 1.5rem !important;
    }

    .testimonial-description {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 600px) {

    .service-item {
        height: 100px;

        &__content-title {
            font-size: 1.2rem !important;
        }
    }

    .testimonial-content {
        margin: 20px;
    }

    .testimonial-title {
        font-size: 1.5rem !important;
    }

    .testimonial-description {
        font-size: 1rem !important;
    }

    .service-item {
        &__content-title {
            font-size: 1.1rem !important;
        }

        &__content-description {
            font-size: 0.8rem !important;
        }
    }

    .testimonial-img-div img {
        width: 100%;
    }
}