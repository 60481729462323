.renter_img {
    display: block !important;
}

.main-header h2 {
    color: #FFA700;
    font-size: clamp(1.3rem, 3vw, 3rem) !important;
    text-align: center;
    margin-bottom: 10px;
}


.owners h3 {
    font-size: clamp(1.3rem, 3vw, 3rem) !important;
    margin: 1rem 0;
    text-align: center;
    color: #FFA700;
    font-weight: 100;
    padding: 1rem;
}

.owners ul {
    text-align: left !important;
    width: fit-content;
    margin: auto;
}

.owners ul li {
    color: #fff;
    font-size: 21px;
    line-height: 29px;
}

.find h2 {
    color: #FFA700 !important;
    font-size: clamp(1.3rem, 3vw, 3rem) !important;
    margin-bottom: 1rem !important;
    margin-top: 0px;
    font-weight: 700;
    margin: auto;
    width: 100%;
    line-height: initial;
}

.footer-line {
    background: #FFA700 !important;
    margin-top: 0px !important;
    padding: 32pt 92px !important;
    text-align: justify !important;
    column-gap: 126px !important;
    width: auto !important;
}

.power h3 {
    font-size: 21pt;
    line-height: 18pt;
    margin: 0px;
    font-weight: 400 !important;
}

.power p {
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 300;
    line-height: 33px;
    hyphens: auto;
    margin-top: 0px;
}


.find-section ul li {
    font-weight: 300;
    font-size: 21px;
    line-height: 29px;
    color: #fff;
}



.find {
    font-size: 25px !important;
    line-height: 40px !important;
    color: #ffa700 !important;
    text-align: center !important;
}

.find-section {
    width: 100%;
    color: #fff;
    font-size: 15pt;
    line-height: 24pt;
    display: flex;
    justify-content: center;
    column-gap: 3rem;
}

.footer-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffa700;
    margin-top: 24pt;
    padding-top: 32pt;
    padding-bottom: 32pt;
    padding-left: 32pt;
    padding-right: 32pt;
    color: #fff;
    text-align: justify;
}

.power h3 {
    font-size: 21pt;
    line-height: 32pt;
    margin-top: 16pt;
}

.power-protection {
    padding: 1rem !important;
}

@media screen and (max-width: 1024px) {
    .find-section {
        column-gap: 1rem;
    }
}

@media screen and (max-width: 600px) {
    .owners ul li {}

    .owners ul li {
        text-align: left;
        padding: 0rem 1rem;
        font-size: 17px;
        line-height: 27px;
    }

    .find-section {
        flex-direction: column;
        column-gap: 0;
    }

    .find-section ul {
        margin: 0;
    }

    .find-section ul li {
        font-size: 17px;
        line-height: 23px;
    }
}