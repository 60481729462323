.main-container {
    min-height: calc(100vh - 228px);
    margin: 50px 80px;
    text-align: center;
}

.layout-container {
    background-color: #2d2d2d;
    // overflow-x: hidden;
    margin-top: 5rem;
    padding-top: 1rem;

    &.scroll-disable {
        overflow-y: hidden;
        height: 100vh;
    }
}

.download-yellow-text {
    color: #faa61a;
    font-size: clamp(16px, 3vw, 32px);
}

.download-app {
    font-size: clamp(1rem, 3vw, 1.2rem) !important;
    display: none !important;
}

.download-popup {
    border: 5px solid #faa61a !important;
    outline: none !important;

    :focus {
        outline: none !important;
    }

    .popup-headr-image {
        text-align: center;

        .download-popup-logo {
            width: 100%;
            max-width: 200px;
            padding-bottom: 15px;
        }
    }

    .download-text {
        color: #fff;
        font-size: 17px;
        font-weight: 300 !important;
        line-height: 27px !important;
        width: 100% !important;
        padding-bottom: 15px !important;
        margin-bottom: 1rem;
        /* text-align: justify; */
    }

    .download-logo-parent {
        text-align: center;
        padding-bottom: 15px;

        .download-logo {
            width: 25%;
            margin-top: 15px;
        }
    }

    .gem-arrow-parent {
        text-align: center;
        padding-bottom: 5px;

        .gem-arrow {
            max-width: 70px;
        }
    }

    .download-parent {
        text-align: center;
        border: unset !important;

        a button {
            width: 90%;
            background: #149346;
            line-height: 30px;
            font-size: 16px;
            font-weight: bold;
            border-radius: 10px;

        }
    }

    .download-buttons {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        margin-top: 0 !important;

        .download-store {
            padding: 0px !important;

            img {
                height: 43px;
            }
        }
    }

    .app_store_code {
        text-align: center;
        margin-top: 15px;

        .app_store_qr {
            max-width: 100px;
        }
    }
}

@media screen and (max-width:900px) {
    .main-container {
        min-height: calc(100vh - 228px);
        margin: 0;
        text-align: center;
    }

    .download-app {
        display: unset !important;
    }

    .download-yellow-text {
        display: none !important;
    }

    .download-popup .app_store_code {
        display: none !important;
    }

    .download-popup .download-buttons {
        margin-top: 34px !important;
        flex-direction: row !important;
    }
}

@media screen and (min-width:901px) {
    .download-popup .download-text {
        color: #fff;
        font-size: 17px;
        font-weight: 300 !important;
        line-height: 27px !important;
        width: 100% !important;
        padding-bottom: 15px !important;
        margin-bottom: 0rem;
        /* text-align: justify; */
    }
}

@media screen and (max-width:320px) {
    .download-buttons {
            column-gap: 10px;
            .download-store {
                padding: 0px !important;
                img {
                    height: 30px !important;
                }
            }
        }
}
@media (prefers-color-scheme: dark) {
    .layout-container {
            background-color: #2e2e2e !important;
    }
    .download-yellow-text {
        color: #faa61a !important;
    }
    .download-popup {
        border: 5px solid #faa61a !important;
    }
}