.my-off-tr-sec {
    margin: 5rem 0;

    .my-off-tr-sec-inner {
        width: 100%;
        margin: auto;

        .my-off-tr-heading {
            // display: grid;
            display: flex;
            align-items: center;
            // grid-template-columns: 0.1fr auto 0.1fr;
            grid-gap: 1rem !important;
            justify-content: center;

            .my-off-tr-heading-text {
                font-size: clamp(15px, 3vw, 38px);
                text-align: center;
                color: #fff;
            }

            .arrow-icons {
                img {
                    width: 60px;
                    margin: auto;
                    display: block;
                }
            }
        }

        .my-off-tr-item-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;
            margin: 2rem 0;

            .my-off-tr-item {
                padding: 1.5rem;
                border: 2px solid #faa81a00;
                border-radius: 10px;

                &:hover {
                    border: 2px solid #faa61a;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px 0px rgba(233, 147, 35, 0.9);
                }

                .my-off-tr-item-content {
                    >p {
                        font-size: 25px !important;
                        font-weight: bold !important;
                        color: white;
                    }

                    .my-off-tr-item-tgrid {
                        // display: grid;
                        display: flex;
                        // grid-template-columns: repeat(2, 1fr);
                        grid-gap: 0.5rem;
                    }

                    .my-off-tr-item-bgrid {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 0rem;

                        p:nth-child(2) {
                            border-left: 2px solid #eee;
                            border-right: 2px solid #eee;
                            text-align: center;
                        }

                        p {
                            text-align: center;

                            .item-price {
                                font-size: 20px;
                            }
                        }
                    }
                }


                .my-off-tr-item-img {

                    border: 2px solid black;
                    margin-bottom: 1rem;

                    img {
                        display: block;
                        width: calc(100% - 4px);
                        height: 195px;
                        max-height: 195px;
                        object-fit: cover;
                    }
                }



            }
        }



    }
}



@media screen and (max-width:768px) {
    .arrow-icons {
        img {
            width: 35px !important;
            margin: auto;
            display: block;
        }
    }

    .my-off-tr-sec .my-off-tr-sec-inner {
        width: 100% !important;
        margin: auto;
    }

    .my-off-tr-item-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 2rem;
        margin: 2rem 0;
    }

    // .tslider-main {
    //         display: block !important;
    //         visibility: visible;

    //     }
    .my-off-tr-heading {
        display: grid;
        // display: flex;
        align-items: center;
        grid-template-columns: 0.3fr auto 0.3fr !important;
        grid-gap: 1rem !important;
    }
}

@media screen and (max-width:576px) {
    .my-off-tr-item-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 1rem;
        margin: 2rem 0;
    }

    .my-off-tr-sec .my-off-tr-sec-inner {
        width: 100% !important;
        margin: auto;
    }

    // .tslider-main {
    //         display: block !important;
    //         visibility: visible;

    //     }
    .my-off-tr-heading {
        // display: grid;
        display: flex;
        align-items: center;
        // grid-template-columns: 0.1fr auto 0.1fr;
        grid-gap: 1rem !important;
        justify-content: center;

        .my-off-tr-heading-text {
            font-size: clamp(15px, 3vw, 38px);
            text-align: center;
            color: #fff;
        }

        .arrow-icons {
            img {
                width: 32px !important;
                margin: auto;
                display: block;
            }
        }
    }


}

@media screen and (max-width:320px) {
    .arrow-icons {
        img {
            width: 32px !important;
            margin: auto;
            display: block;
        }
    }

    .my-off-tr-item-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 1rem;
        margin: 2rem 0;
    }

    .my-off-tr-heading {
        display: grid;
        // display: flex;
        align-items: center;
        grid-template-columns: 0.4fr auto 0.4fr !important;
        grid-gap: 0.2rem !important;

        .my-off-tr-heading-text {
            font-size: clamp(15px, 3vw, 30px);
            text-align: center;
            color: #fff;
        }

        .arrow-icons {
            img {
                width: 100%;
                margin: auto;
                display: block;
            }
        }
    }

    .my-off-tr-sec .my-off-tr-sec-inner {
        width: 100% !important;
        margin: auto;
    }

    .my-off-tr-heading {
        display: grid;
        // display: flex;
        align-items: center;
        grid-template-columns: 0.3fr auto 0.3fr;
        grid-gap: 1rem !important;

        .my-off-tr-heading-text {
            font-size: clamp(14px, 3vw, 30px);
            text-align: center;
            color: #fff;
        }

        .arrow-icons {
            img {
                width: 100%;
                margin: auto;
                display: block;
            }
        }
    }
}

// My Rental Yard Equipme Detail Section start
// .carousel-item {
//     min-height: 300px !important;
// }

equipment-mobile-grid {
    display: none !important;
}


.main-location-address {
    align-items: center;

    .location-address {
        color: #e99323;
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        margin: 0px;

        div {
            justify-content: left;
            // padding: 10px 0px;
            border: none;
            // padding: 4px 0;
            // padding: 0px 0px 5px 10px;
            border: none;
            padding: 0px 0px 0px 0px;
            // display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 10px;
            margin: 5px 0;
            align-items: center !important;
        }

        .item-location-grid-2 {
            align-items: center !important;
        }

    }
}

.address {
    padding-left: 5px !important;
}

.equipment-location-section {
    border: none !important;

    p {
        color: #fff;
        // padding-top: 15px;
        font-weight: 900;
    }
}

.equipment-rate {
    border-top: 2px solid gray !important;
    border-bottom: 2px solid gray !important;
    display: flex !important;
    align-items: center !important;
    margin-top: 1rem !important;
}

.address {
    font-size: clamp(14px, 3vw, 20px) !important;
    color: #faa61a;
    padding-left: 10px !important;
}

.item-price,
.item-price-unit,
.item-price span {
    // font-size: clamp(12px, 3vw, 26px) !important;
}

.item-price-unit {
    font-size: clamp(12px, 3vw, 16px) !important;
}

.item-price,
.item-price span {
    font-weight: 900;
    font-size: clamp(14px, 3vw, 20px) !important;
}

.item-value-content.item-location-grid {
    margin: 0 0;
}

.item-value-content.equipment-details-para {
    display: flex;
    align-items: center !important;
}

.rent-now-parent.rent-now-btn-hide {
    display: none !important;
}

.location-address {
    display: flex !important;
    flex-direction: column !important;
}

.item-value-content.item-location-grid {
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
}

.item-value-content.item-location-grid-img {
    width: 60px !important;
    height: auto !important;
}

.rent-now-parent {
    text-align: flex-end;
}


.rent-now {
    background: #149247;
    color: #fff;
    border: none;
    /* font-weight: pa; */
    padding: 7px 14px;
    font-size: clamp(10px, 3vw, 20px);
    font-weight: bold;
    border-radius: 5px;
    line-height: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 900 !important;
}

.carousel-item.equipment-details-item-img {
    width: 75% !important;
    height: auto !important;
}

.grid-rent-now-btn {}

.item-value-content.item-grid-1 {}

.item-value-content.item-grid-2 {
    border-left: 2px solid gray !important;
    // border-right: 2px solid gray;
}

.item-value-content.item-grid-3 {
    border-left: 2px solid gray !important;
}


.yellow-header {
    background-color: #faa61a;
    // height: 95px;
    display: flex;
    align-items: center;
    position: relative;
    top: 87px !important;
    padding: 0.4rem 1rem 0.4rem 1rem;

    .yellow-header-inner {
        display: flex;
        align-items: baseline;
        width: 70%;
        margin: auto;
        z-index: 11;
        flex-wrap: wrap;

        .yellow-header-inner-big {
            padding-right: 0.5rem;
            font-size: clamp(0.8rem, 4vw, 2.8rem);
            font-weight: 900;
        }

        .yellow-header-inner-sm {
            font-size: clamp(0.6rem, 4vw, 2.2rem);
            font-weight: 400;
        }
    }

    .yellow-header-abs {
        position: absolute;
        bottom: -25px;
        left: 30%;
        width: 50px;
        height: 50px;
        background-color: #faa61a;
        transform: rotate(45deg);
        z-index: 10;
    }
}

.carousel-img-thumbnal {
    width: 70px;
    height: 50px;
    margin: 5px;
}



.main-section {
    a {
        color: #e99323;
    }

    hr {
        border: 1px solid #938f8f;
    }

    .owner-info {
        text-align: left;
        color: #e99323;

        .owner-name {
            color: #fff;
        }
    }

    .main-owner-img-parent {
        padding-top: 15px;
        align-items: center;

        .owner-img-parent {
            padding: 0px !important;

            .owner-img {
                width: 50%;
                border-radius: 50%;
            }
        }

        .owner-info {
            padding: 0px !important;
            text-align: left;
        }
    }

    .link-redirect {
        text-align: right;
        color: #e99323;

        div {
            justify-content: flex-end;
            border: none;
            ;
        }
    }

    .equipment-description {
        color: #fff;
        text-align: left;
        padding: 25px 0px 6px 0px;
        border-bottom: 2px solid #d69c51;
    }

    .description-content {
        color: #fff;
        text-align: left;
        justify-content: left;
        border: none;
        display: contents;

        p {
            padding-top: 6px;
        }
    }

    .last-rent-now {
        justify-content: left;
        margin-top: 25px;
    }
}

.owner-name.equipment-details-para {
    font-size: clamp(12px, 3vw, 22px) !important;
}

.service-section__row {
    .equipment-details-main {
        .equipment-details-main-inner {
            // display: flex;
            // flex-wrap: wrap;
            // align-items: flex-start;
            // align-content: flex-start;
            // justify-content: center;

            // columns: 2;
            // column-gap: 1rem;

            .equipment-details-main-inner-div {
                // width: 45%;
                width: 100%;
                height: fit-content;
                // padding: 1rem;
                margin: 0.5rem;
            }

            .equipment-details-main-inner-div:nth-child(1) {
                // flex: 1 auto;
            }

            .equipment-details-main-inner-div:nth-child(2) {
                // flex: 1 auto;
            }

            .equipment-details-main-inner-div:nth-child(3) {
                // flex: 1 auto;
            }

            .equipment-details-main-inner-div:nth-child(4) {
                // flex: 1 auto;
                // margin-top: -13%;
            }
        }
    }
}

.owner-img {
    width: 100%;
    border-radius: 1000px;
    border: 3px solid white;
}

.item-value-content.description-content.equipment-details-para {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    word-wrap: break-word;
    width: 100%;
}

.equipment-details-para .equipment-owner-heading {
    color: #e99323 !important;
    text-align: left !important;
}

.equipment-details-para {
    font-size: clamp(12px, 3vw, 20px) !important;
}


.equipment-description.equipment-details-heading {
    font-size: clamp(12px, 3vw, 24px) !important;
}

.owner-info {
    text-align: left !important;
}

.owner-name.equipment-details-para {
    text-align: left !important;
    color: #fff !important;
}

.owner-name.equipment-details-para:first-child {
    color: #f5a419 !important;
}

.item-value-content .item-value-content-delivery,
.item-value-content .item-value-content-address {
    margin: 0 !important;
}

.equipment-details-sub-div {
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    .equipment-description.equipment-details-heading {
        // font-weight: 800;
        // font-size: clamp(16px, 3vw, 22px);
        margin: 2px 0;
    }

    .equipment-details-para,
    .equipment-details-heading {
        text-align: justify !important;
        color: #fff !important;
    }

    .item-value-content.last-rent-now.equipment-details-rent {
        display: flex;
        justify-content: flex-start;
    }

    .yellow-line {
        width: 100%;
        height: 3px;
        background-color: #faa61a;
        border: unset;
        margin: 0 !important;
    }
}

.item-value-content-icons {
    // width: 60% !important;
    width: 20px !important;
    height: auto !important;
}

.item-grid {
    display: flex !important;
    align-items: center !important;
}

.item-value-content.last-rent-now.equipment-details-rent.calendar-rent-now {
    margin-top: 2rem !important;
}

.item-value-content.equipment-calendar-img {
    margin-top: 1rem;
}

.equipment-details-sub-div.owner-renter {
    margin: 2rem 0;
}

.equipment-mobile-grid {
    display: none;
}

.image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
    margin: 1rem 0 0 0;
}

.item-value-content.equipment-location-section {
    margin: 1.5rem 0 0rem 0;
}



@media screen and (max-width:1400px) {
    .yellow-header {
        .yellow-header-inner {
            width: 75%;
        }
    }
}

@media screen and (max-width: 1200px) {}


@media screen and (max-width:1100px) {


    .yellow-header {
        display: flex;
        align-items: center;
        position: relative;
        top: 90px !important;

        .yellow-header-inner {
            display: flex;
            align-items: baseline;
            width: 90%;
            margin: auto;
            z-index: 11;
            flex-wrap: wrap;

        }

        .yellow-header-abs {
            position: absolute;
            bottom: -15px;
            left: 15%;
            width: 40px;
            height: 40px;
            background-color: #faa61a;
            transform: rotate(45deg);
            z-index: 10;
        }
    }
}

@media screen and (max-width:1024px) {
    .address {
        font-size: clamp(12px, 3vw, 16px) !important;
    }

    .item-price,
    .item-price span {
        font-weight: 900;
        font-size: clamp(15px, 3vw, 22px) !important;
    }

    .msg-owner-text {
        font-size: clamp(12px, 3vw, 15px) !important;
    }

    .yellow-header .yellow-header-inner {
        width: 90%;
    }

    .image-gallery-content {
        width: 86% !important;
    }

    .owner-name.equipment-details-para {
        font-size: 16px !important;
    }

    .rent-now {
        font-size: 14px !important;
    }

    .msgg-text {
        font-size: clamp(12px, 3vw, 15px) !important;
    }

    .visit-text {
        font-size: clamp(12px, 3vw, 15px) !important;
    }
}

@media screen and (max-width:900px) {
    .main-owner-img-parent.owner-img-parent-mobile {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .owner-img {
        width: 50% !important;
        border-radius: 1000px;
        border: 3px solid white;
    }

    .item-value-content.last-rent-now.equipment-details-rent.calendar-rent-now.rent-now {
        font-size: clamp(20px, 3vw, 20px) !important;
    }

    .image-gallery-content {
        width: 90% !important;
    }

    .equipment-desktop-grid {
        display: none;
    }

    .equipment-mobile-grid {
        display: unset !important;
    }

    .equipment-carousel-main-div {
        margin-top: 1.5rem;
    }

    .rent-now-parent.rent-now-btn-hide {
        display: unset !important;
    }

    .equipment-details-sub-div .item-value-content.last-rent-now.equipment-details-rent {
        display: none !important;
    }

    .rent-now-parent {}

    .rent-now-parent.address-grid-rent-now {
        display: none !important;
    }

    .service-section__row {
        .equipment-details-main {
            .equipment-details-main-inner {

                .equipment-details-main-inner-div {
                    width: auto;
                    height: fit-content;
                    margin: 0 1.5rem;
                }
            }
        }
    }

    .equipment-details-main-inner {
        margin-bottom: 2rem;
    }

    .visit {
        width: 59% !important;
        height: auto !important;
    }

    .msgg {
        width: 70% !important;
        height: auto !important;
    }
}


@media screen and (max-width:600px) {
    .rent-now.rent-now-last {
        font-size: 10px !important;
    }

    .owner-name.equipment-details-para {
        font-size: 14px !important;
    }

    .visit {
        width: 95% !important;
        height: auto !important;
    }

    .msgg {
        width: 100% !important;
        height: auto !important;
    }

    .equipment-details-main-inner {
        margin-bottom: 2rem;
    }

    .image-gallery-slide-wrapper {
        position: relative;
        width: 100% !important;
        height: 222px !important;
    }

    .image-gallery-slide .image-gallery-image {
        width: 100% !important;
        object-fit: contain !important;
        height: 222px !important;
    }

    .owner-img {
        width: 70% !important;
        border-radius: 1000px;
        border: 3px solid white;
    }

    .item-value-content.last-rent-now.equipment-details-rent.calendar-rent-now {
        .rent-now {
            font-size: clamp(20px, 3vw, 20px) !important;
        }
    }

    .image-gallery-content {
        width: 90% !important;
    }

    .equipment-desktop-grid {
        display: none;
    }

    .item-value-content.last-rent-now.equipment-details-rent.calendar-rent-now {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .yellow-header .yellow-header-abs {
        bottom: -15px;
        left: 15%;
        width: 30px;
        height: 30px;
    }

    .yellow-header {
        display: flex;
        align-items: center;
        position: relative;
        top: 83px !important;
    }

    .carousel-item.equipment-details-item-img {
        width: 100% !important;
        height: auto !important;
    }

    .rent-now.rent-now-last {
        padding: 5px !important;
    }

    .equipment-details-sub-div .item-value-content.last-rent-now.equipment-details-rent {
        display: none !important;
    }

    .rent-now-parent.address-grid-rent-now {
        display: none !important;
    }


    .service-section__row {
        .equipment-details-main {
            .equipment-details-main-inner {

                .equipment-details-main-inner-div {
                    width: auto;
                    height: fit-content;
                    margin: 0 1.5rem;
                }
            }
        }
    }

}

@media screen and (max-width:320px) {
    .msgg-text {
        font-size: clamp(10px, 3vw, 15px) !important;
    }

    .visit-text {
        font-size: clamp(10px, 3vw, 15px) !important;
        padding: 7px 10px 0px !important;
    }

    .image-gallery-slide-wrapper {
        height: 186px !important;
    }

    .image-gallery-slide .image-gallery-image {
        height: 186px !important;
    }
}

.equipment-details-item-img div:nth-child(4) {
    display: none !important;
}

.image-gallery-slide.center {
    position: relative;
    background-color: #202020;
    height: 100%;
}

.image-gallery-slide-wrapper {
    position: relative;
    width: 100% !important;
    height: 280px;
}

.image-gallery-slide .image-gallery-image {
    width: 100% !important;
    object-fit: contain !important;
    height: 280px;
}

.image-gallery-left-nav {
    left: -25px !important;
}

.image-gallery-right-nav {
    right: -25px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 35px !important;
    width: 35px !important;
    background-color: #faa81afa !important;
    border-radius: 50% !important;
    transition: 0.2s;
}

.image-gallery-left-nav:hover .image-gallery-svg,
.image-gallery-right-nav:hover .image-gallery-svg {
    height: 35px !important;
    width: 35px !important;
    background-color: #faa81afa !important;
    border-radius: 50% !important;
    // transform: translateY(-5px);
}

.image-gallery-icon {
    color: #000000;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: none !important;
}

.image-gallery-icon:hover {
    color: #000000 !important;
}

.image-gallery-icon:hover .image-gallery-svg {
    transform: unset !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: flex !important;
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    touch-action: manipulation !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    flex-wrap: wrap;
    align-items: center;
}

.image-gallery {
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
}

.image-gallery-play-button {
    left: 0;
    display: none !important;
}

.image-gallery-fullscreen-button {
    right: 0;
    display: none !important;
}

.image-gallery-icon {
    color: #000000 !important;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
}

.image-gallery-left-nav {
    left: -30px !important;
}

.image-gallery-right-nav {
    right: -30px !important;
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    width: 100%;
}

.image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #FAA61A !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #faa61a !important;
}

.image-gallery-left-nav {
    left: -25px !important;
}

.image-gallery-right-nav {
    right: -25px !important;
}


.underline-text {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;

    .msgg {
        width: 22% !important;
        height: auto !important;
    }

    .visit {
        width: 18% !important;
        height: auto !important;
    }

    .msgg-text {
        font-size: clamp(12px, 3vw, 18px);
    }

    .visit-text {
        font-size: clamp(12px, 3vw, 18px);
    }
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    object-fit: contain !important;
    background-color: #202020 !important;
}








// NEW CSS
//------------------------------------------------------------------ 
.rental-icons-grid {
    display: grid;
    // display: flex;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem 0;

    .rental-icons-item {
        width: 70% !important;
        text-decoration: none !important;

        p {
            text-align: center;
            color: #fff;
            font-family: "GothamBold" !important;
            margin: 0.5rem 0;
            font-size: clamp(10px, 3vw, 12px);
            text-decoration: none;
        }

        .rental-icon {
            img {
                display: block;
                width: 100%;
                cursor: pointer;
            }
        }
    }
}

.rental-sec-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.location-sec-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    // margin: 2rem 0;
}

.owner-div {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    grid-gap: 2rem;
    align-items: center;
}

.location-sec-details,
.delivery-avail {
    display: grid;
    grid-template-columns: 0.2fr 1.8fr;
    grid-gap: 0.5rem;
    align-items: center;
    margin: 1rem 0;

    p {
        color: #fff !important;
        font-size: clamp(13px, 3vw, 19px);
        font-family: "GothamBold" !important;
    }

    img {
        display: block;
        width: 100%;
    }
}

.rental-heading {
    p {
        &:first-child {
            font-size: clamp(20px, 3vw, 30px);
            color: #fff;
            font-family: "GothamBold" !important;
            font-weight: 600;
        }

        &:last-child {
            font-size: clamp(15px, 3vw, 20px);
            color: #FAA61A;
            font-family: "GothamBold" !important;
        }
    }
}

.rental-sec-item {
    padding: 1rem;

    .rental-sec-item-top {
        margin-bottom: 1rem;
    }
}

.rental-headings,
.active-rentals-heading {
    p {
        font-size: clamp(20px, 3vw, 30px) !important;
        color: #fff !important;
        font-family: "GothamBold" !important;
        font-weight: bold;
    }

}


.rental-dates-inner {
    p {
        &:first-child {
            font-size: clamp(13px, 3vw, 24px) !important;
            font-family: "GothamBold" !important;
        }

        color: #fff;

        &:nth-child(2),
        &:last-child {
            font-size: clamp(8px, 3vw, 14px) !important;
        }
    }
}

.active-rental-item {
    >a {
        color: #faa61a;
        text-decoration-color: #faa61a;
    }
}

.active-rental-main {
    margin-top: 2rem;
}

.owner-text {
    p {
        color: #fff !important;
    }

    a {
        color: #faa61a !important;
        text-decoration-color: #faa61a !important;
    }


}

.owner-title-text {
    p {
        color: #fff;
    }

    a {
        color: #faa61a !important;
        text-decoration-color: #faa61a !important;
    }
}

.owner-img {
    width: 60px;
    height: 60px;
    max-height: 60px;
    // width: 100%;
    border-radius: 1000px;
    border: 3px solid white;
    display: block;

    img {
        display: block;
        width: 100%;
        border-radius: 1000px;
    }
}

.owner-img-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0.5rem;
    align-items: center;
}

.active-rental-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 2rem;
    margin: 2rem 0;

    .active-rental-grid-item {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        align-items: center;
        grid-gap: 0.5rem;

        img {
            display: block;
            width: 100%;
        }

    }

    &:last-child {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
    }


    .owner-renter-photo {
        img {
            display: block;
            width: 100%;
        }
    }
}


.active-rental-steps {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    align-items: center;
    margin: 1rem 0;

    .active-rental-img {
        img {
            display: flex;
            width: 100%;
        }
    }

    .active-rental-steps-link {

        background-color: #fff;
        text-align: center;
        padding: 5px 0;
        border: 2px solid #eee;
        text-decoration: none;
        color: #2d2d2d !important;

        p {
            font-size: clamp(8px, 3vw, 10px) !important;
        }

        &:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        &:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
}

@media screen and (max-width:1024px) {
    .rental-icons-grid {
        grid-gap: 1rem !important;
    }

    .location-sec-grid {
        grid-gap: 1rem !important;
    }

    .owner-div {
        grid-gap: 1rem !important;
    }

    .active-rental-grid {
        grid-gap: 1rem !important;
    }

    .rental-dates-inner p:first-child {
        font-size: 16px !important;
        font-family: "GothamBold" !important;
    }

    .rental-dates-inner p:nth-child(2),
    .rental-dates-inner p:last-child {
        font-size: 9px !important;
    }
}

@media screen and (max-width:992px) {
    .rental-sec-item {
        padding: 0rem !important;
    }

    .rental-sec-grid {
        grid-gap: 2rem !important;
    }

    .rental-icons-grid {
        grid-gap: 0.5rem !important;
    }

    .location-sec-grid {
        grid-gap: 2rem !important;
    }

    .rental-dates-inner p:nth-child(2),
    .rental-dates-inner p:last-child {
        font-size: 9px !important;
    }

    .rental-dates-inner p:first-child {
        font-size: 16px !important;
        font-family: "GothamBold" !important;
    }
}

@media screen and (max-width:768px) {
    .rental-sec-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
    }

    .rental-icons-grid {
        grid-gap: 1rem;
    }

    .location-sec-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem !important;
        margin: 2rem 0;
    }

    .owner-div {
        display: grid;
        grid-template-columns: 0.6fr 1.5fr !important;
        grid-gap: 0.5rem !important;
        align-items: center;
    }

    .location-sec-details,
    .delivery-avail {
        display: grid;
        grid-template-columns: 0.2fr 1.8fr !important;
        grid-gap: 0.5rem;
        align-items: center;
        margin: 1rem 0;
    }
}

@media screen and (max-width:576px) {
    .active-rental-grid .active-rental-grid-item {
        display: grid;
        grid-template-columns: 0.4fr 1fr !important;
        align-items: center;
        grid-gap: 0.5rem;
    }

    .location-sec-details img,
    .delivery-avail img {
        display: block;
        width: 70% !important;
    }

    .location-sec-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem !important;
        margin: 2rem 0;
    }

    .rental-icons-grid .rental-icons-item {
        width: 90% !important;
    }

    .rental-sec-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
    }

    .rental-icons-grid {
        grid-gap: 1rem;
    }

    .rental-icons-grid .rental-icons-item .rental-icon img {
        display: block;
        width: 70% !important;
        margin: auto;
    }

    .location-sec-details,
    .delivery-avail {
        display: grid;
        grid-template-columns: 0.5fr 1.8fr !important;
        grid-gap: 0.5rem;
        align-items: center;
        margin: 1rem 0;
    }

    .rental-sec-item {
        padding: 0rem !important;
    }

    .owner-div {
        display: grid;
        grid-template-columns: 1fr 1.5fr !important;
        grid-gap: 0.2rem !important;
        align-items: center;
    }

    .active-rental-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        grid-gap: 1rem !important;
        margin: 1.2rem 0 !important;
    }

    // .rental-dates-inner p:first-child {
    //     font-size: clamp(16px, 3vw, 24px) !important;
    //     font-family: "GothamBold" !important;
    // }

    .rental-dates-inner p:nth-child(2),
    .rental-dates-inner p:last-child {
        /* font-size: clamp(8px, 3vw, 14px) !important; */
        font-size: 9px !important;
    }
}

@media screen and (max-width:320px) {

    .rental-dates-inner p:nth-child(2),
    .rental-dates-inner p:last-child {
        /* font-size: clamp(8px, 3vw, 14px) !important; */
        font-size: 7px !important;
    }

    .location-sec-details img,
    .delivery-avail img {
        display: block;
        width: 70% !important;
    }

    .active-rental-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        grid-gap: 0rem !important;
        margin: 1.2rem 0 !important;
    }

    .location-sec-details,
    .delivery-avail {
        display: grid;
        grid-template-columns: 0.5fr 1.8fr !important;
        grid-gap: 0.5rem;
        align-items: center;
        margin: 1rem 0;
    }

    .rental-icons-grid .rental-icons-item .rental-icon img {
        display: block;
        width: 70% !important;
        margin: auto;
    }

    .rental-icons-grid .rental-icons-item {
        width: 100% !important;
    }

    .rental-sec-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
    }

    .rental-icons-grid {
        grid-gap: 0rem !important;
    }

    .location-sec-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5rem !important;
        margin: 2rem 0;
    }

    .rental-sec-item {
        padding: 0rem !important;
    }
}


.Calendar {
    background-image: url('../../assets/images/banner_img.png') !important;
}

.dash-black-border {
    border-bottom: 2px dotted #ffffff !important;
}

// .popup-input input {
//     margin: 12px 0 0 0;
// }

// .pupup-scroll {
//     max-height: 550px;
//     overflow-y: scroll;
// }