.office-trailer-tabs { 
    .rentyrd-menu-link.active-tab {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FAA61A;
            height: 4px;
            left: 50%;
            bottom: -7px;
            transform: translate(-50%, 0);
            margin-top: 10px;
        }
    }
}