.hw {
    width: 60%;
    margin: auto;
    padding: 0rem !important;
    box-sizing: border-box;
    z-index: 2;
    position: relative;

    @media (min-width: 2001px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }
    @media (max-width: 2000px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1800px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1700px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1600px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1500px) {
        width: 90% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1400px) {
        width: 90% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1300px) {
        width: 100% !important;
    }

    @media (max-width: 1024px) {
        width: 100% !important;
    }
}


.bac-top-main {
    margin: 0 0 1rem 0;

    p.bac-subheading {
        color: #fff;
        font-family: 'Montserrat';
        width: 90%;
        font-size: clamp(14px, 3vw, 18px) !important;
        margin-top: 0.3rem;
        margin-bottom: 1rem;
    
        @media (max-width: 768px) {
            font-size: 14px !important;
            margin: 0.3rem 0 0 0;
            width: 100% !important;
        }
    }
    
    .bac-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0rem 0;
    
        p.bac-heading {
            font-size: 45px;
            font-family: 'GothamBold' !important;
            color: #fff;
    
            @media (max-width: 992px) {
                font-size: 30px !important;
            }
    
            @media (max-width: 768px) {
                font-size: 27px !important;
            }
    
            span {
                color: #faa61a;
            }
        }
    
    
    
        .bac-btn {
            font-size: 16px !important;
            font-family: 'GothamBold' !important;
            color: #fff !important;
            background: #149247 !important;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
            padding: 0.3rem 1rem !important;
    
            @media (max-width: 992px) {
                border-radius: 8px !important;
                font-size: 14px;
                width: max-content;
            }
        }
    }
}

.listeqp-bg-img {
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 1;

    @media (max-width: 992px) {
        display: none !important;
    }

    img {
        width: 900px;
        height: 450px;
        display: block;
        object-fit: cover;

        @media (max-width: 1300px) {

        }
    }

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        box-shadow: inset 154px -118px 59px 0px rgb(45 45 45), inset 325px 508px 64px 0px rgb(45 45 45 / 83%);
    }
}

.lgt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: #47463E;
    border-radius: 20px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);
    margin: 5rem 0;

    @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;
    }

    .lgt-content {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width: 768px) {
            text-align: center !important;
        }

        .lgt-heading {
            font-size: clamp(20px, 3vw, 35px) !important;
            font-family: 'GothamBold' !important;
            color: #faa61a;
            text-transform: capitalize;
            line-height: clamp(30px, 3vw, 55px);
        }

        .lgt-text {
            color: #fff;
            font-size: 20px;
            font-weight: 400;
        }
    }

    .lgt-img {
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 300px;
            object-fit: contain;
        }
    }
}

.lgt-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: #47463E;
    border-radius: 20px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);
    margin: 5rem 0;

    @media (max-width: 992px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;

        .lgt-2 .lgt-content-2 {
            text-align: center !important;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .lgt-content-2 {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .lgt-heading {
        font-size: clamp(20px, 3vw, 35px) !important;
        font-family: 'GothamBold' !important;
        color: #faa61a;
        text-transform: capitalize;
        line-height: clamp(30px, 3vw, 55px);
        margin: 0.5rem 0;
    }

    .lgt-text {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        margin: 0.5rem 0;
    }

    .lgt-img {
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 300px;
            object-fit: contain;
        }
    }
}

.edp {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin: 5rem 0;

    @media (max-width: 768px) {
        gap: 1rem !important;
    }

    .edp-img {
        img {
            width: 150px;
            height: auto;
            display: block;
            object-fit: contain;

            @media (max-width: 992px) {
                width: 100px !important;
            }

            @media (max-width: 768px) {
                width: 70px !important;
            }

            @media (max-width: 576px) {
                width: 50px !important;
            }
        }
    }

    .edp-content {
        display: flex;
        // gap: 1rem;
        gap: 0;
        flex-direction: column;
    }

    .edp-heading {
        color: #fff;
        font-size: clamp(18px, 3vw, 40px);
        font-family: 'GothamBold' !important;
        margin: 0.5rem 0;
    }

    .edp-text {
        color: #fff;
        font-size: clamp(16px, 3vw, 20px);
        font-family: 'Montserrat' !important;
        font-weight: 300;

        line-height: clamp(20px, 3vw, 25px);
        
        margin: 0.5rem 0;

        span {
            color: #faa61a;
            font-weight: 600;
        }
    }
}

.mipt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10rem;
    margin: 4rem 0;

    @media (max-width: 992px) {
        gap: 2rem !important;
        grid-template-columns: 1fr !important;
    }

    .mipt-item {
        border-radius: 13px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background-color: #47463E;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);

        p.mipt-item-heading {
            color: #faa61a;
            font-size: 20px;
            font-family: 'GothamBold' !important;
            text-align: center;
            margin-bottom: 0 !important;
        }

        p.mipt-item-heading-2 {
            color: #faa61a;
            font-size: 24px;
            font-family: 'GothamBold' !important;
            text-align: center;
            margin-bottom: 0 !important;
        }

        p.mipt-item-text {
            color: #ffffff;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Montserrat' !important;
            text-align: center;
            margin-top: 0;

            a {
                color: #faa61a;
                text-decoration-color: #faa61a;
            }
        }

        p.mipt-item-text-2 {
            color: #ffffff;
            font-size: clamp(16px, 3vw, 19px);
            line-height: 23px;
            font-family: 'Montserrat' !important;
            text-align: center;
            margin-top: 0;

            a {
                color: #faa61a;
                text-decoration-color: #faa61a;
            }

            span {
                color: #faa61a;
                text-decoration-color: #faa61a;
            }
        }

        .mipt-item-img {
            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100px;
            }

            // @media (max-width: 768px) {
            //     order: 3;
            // }
        }

        .mipt-item-img-2 {
            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 150px;
            }
        }
    }
}

.mipt-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    margin: 0rem 0rem 3rem 0rem;

    @media (max-width: 992px) {
        gap: 2rem !important;
        grid-template-columns: 1fr !important;
    }

         @media (max-width: 768px) {
            margin: 0rem 0 !important;
         }

    .mipt-item {
        border-radius: 13px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background-color: #47463E;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);

        p.mipt-item-heading {
            color: #faa61a;
            font-size: 20px;
            font-family: 'GothamBold' !important;
            text-align: center;
            margin-bottom: 0 !important;
            margin-top: 0;
        }

        p.mipt-item-heading-2 {
            color: #faa61a;
            font-size: 24px;
            font-family: 'GothamBold' !important;
            text-align: center;
            margin-bottom: 0 !important;
        }

        p.mipt-item-text {
            color: #ffffff;
            font-size: 14px;
            line-height: 22px;
            font-family: 'Montserrat' !important;
            text-align: center;
            margin-top: 0;
            margin-bottom: 0;

            a {
                color: #faa61a;
                text-decoration-color: #faa61a;
                font-weight: 700;
            }
        }

        p.mipt-item-text-2 {
            color: #ffffff;
            font-size: clamp(16px, 3vw, 19px);
            line-height: 23px;
            font-family: 'Montserrat' !important;
            text-align: center;
            margin-top: 0;

            a {
                color: #faa61a;
                text-decoration-color: #faa61a;
            }
        }

        .mipt-item-img {
            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100px;
            }

            @media (max-width: 768px) {
                order: 3;
            }
        }

        .mipt-item-img-2 {
            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 150px;
            }
        }
    }
}

.get-ot {
    margin: 5rem 0;
    background-color: #47463E;
    border-radius: 15px;
    padding: 2rem;

    @media (max-width:768px) {
        padding: 1.2rem !important;
        margin: 2rem 0 !important;
    }

    .get-ot-img {
        margin: 2rem 0;
        padding: 2rem;

        @media (max-width:768px) {
            padding: 0rem !important;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .get-ot-btm {
        display: flex;
        align-items: center;
        gap: 2rem;

        @media (max-width:768px) {
            gap: 1rem !important;
        }

        .get-ot-btm-img {
            img {
                display: block;
                object-fit: contain;
                width: 150px;
                height: auto;

                @media (max-width: 768px) {
                    width: 90px !important;
                }

                @media (max-width: 576px) {
                    width: 50px !important;
                }
            }
        }

        .get-ot-btm-text {
            color: #fff;
            font-family: 'GothamBold' !important;
            font-size: 24px;
            line-height: 30px;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 18px !important;
                line-height: 24px !important;
            }
        }
    }

    .get-ot-head {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p.get-ot-heading {
            color: #faa61a;
            font-family: 'GothamBold' !important;
            text-align: center;
            font-size: 40px;
            margin: 0.5rem 0;

            @media (max-width: 768px) {
                font-size: 27px !important;
            }
        }

        p.get-ot-subheading {
            color: #ffffff;
            font-family: 'Montserrat' !important;
            text-align: center;
            font-size: 22px;
        }
    }

}

.hk {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 5rem 0;

    .hk-img {
        img {
            width: 230px;
            height: auto;
            display: block;
            object-fit: contain;
        }
    }

    .hk-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .hk-heading {
        color: #fff;
        font-size: 37px;
        font-family: 'GothamBold' !important;
        margin: 0.5rem 0;
    }

    .hk-subheading {
        color: #fff;
        font-size: 22px;
        font-family: 'Montserrat' !important;
        margin: 0.5rem 0;
    }

    .hk-list {
        color: #fff;
        font-size: 20px;
        font-family: 'Montserrat' !important;
        list-style: square;
        padding-top: 0;
        margin-top: 0;

        li {
            &::marker {
                color: #faa61a;
            }

            margin: 0.5rem 0;
        }

    }
}

.help {
    display: flex;
    align-items: center;
    gap: 2rem;
    background: #47463e;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);

    .help-img {
        padding-left: 1rem;

        img {
            display: block;
            object-fit: contain;
            width: 100px;
            height: auto;
        }
    }

    p.help-heading {
        font-size: 28px;
        font-family: 'GothamBold' !important;
        color: #faa61a;
        text-align: center;
        margin: 0;
    }

    p.help-text {
        font-size: 20px;
        line-height: 28px;
        font-family: 'Montserrat' !important;
        color: #ffffff;
        text-align: center;
        margin-top: 0;

        a {
            color: #faa61a;
            text-decoration-color: #faa61a;
            font-family: 'GothamBold' !important;
        }
    }

    .help-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.bac-L {
    width: 100%;
}