.sd-filter {
    background-color: #2E2E2E;
    padding: 0.5rem 1rem;
    margin-top: -30px;

    display: flex;
    align-items: center;
    // align-items: baseline;
    justify-content: center;
    gap: 0.5rem;
    // width: 100%;

    position: sticky;
    top: 6.5rem;
    z-index: 100;

    @media (max-width:576px) {
        top: 5.4rem !important;
    }

    p {
        font-size: clamp(20px, 3vw, 24px);
        color: #fff !important;
        font-family: 'GothamBold' !important;
        text-transform: uppercase;
    }

     .MuiInputBase-input.MuiOutlinedInput-input {
        background: #fff !important;
        border-radius: 10px;
        font-family: "Gotham" !important;
        border: 3px solid #faa61a;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        width: 300px;
        outline: 2px solid #2d2d2d !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused.MuiInputBase-formControl {
        // border: 2px solid #2d2d2d !important;
        outline: 1.5px solid #2d2d2d !important;
    }
}

.filter-grid {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // align-items: center;
    align-items: flex-start;
    gap: 1rem;
}

.sd-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    p {
        font-size: 40px;
        color: #fff;
    }

    img {
        width: 50px;
        height: auto;
        display: block;
        object-fit: contain;
    }
}

.sd-cards-main {
    margin-bottom: 2rem;
    margin-top: 2rem;

    .sd-cards {
        border-top: 2px solid gray;
        padding: 1rem 0;

        &:last-child {
            border-bottom: none;
        }

        .sd-card-profile {
            display: block;
            border-radius: 50%;
            border: 2px solid gray;
            width: 70px;
            height: 70px;
            object-fit: contain;
        }
    }
}

.icons-grp {
    display: flex;
    align-items: center;
    gap: 0.9rem;
    flex-wrap: wrap;
    margin-top: 0.4rem;

    .sd-cards-icon {
        display: block;
        object-fit: contain;
        width: 40px;
        height: auto;
    }
}

.btn-grp {
    margin-top: 0.4rem;
    display: flex;
    align-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.hide-checkbox {
    span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
        color: #fff !important;
        font-family: 'GothamBold' !important;
    }
}

.sd-card-grid {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.sd-name-div {
    display: flex;
    align-items: center;
    gap: 7px;

    p {
        font-size: 16px;
        color: #faa61a !important;
        font-family: 'Gotham' !important;
    }
}

.sd-location-div {
    display: flex;
    align-items: flex-start;
    gap: 7px;

    p {
        font-size: 16px;
        color: #faa61a !important;
        font-family: 'Gotham' !important;
    }
}

.sd-card-msg {
    color: #fff !important;
    font-size: 14px;
    font-family: 'Gotham' !important;
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;
    width: 100%;
}

.sd-name-icon {
    display: block;
    object-fit: contain;
    width: 15px;
    height: auto;
}

.sd-location-icon {
    display: block;
    object-fit: contain;
    width: 15px;
    height: auto;
}

.filter-reload {
    padding: 0 !important;
    margin: 0 !important;
    min-width: fit-content;
    font-size: 10px;

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.reload-icon {
        color: #FAA61A !important;
    }
}

@media (max-width:1300px) {
    .filter-grid {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .sd-filter {
        flex-direction: row;
    }

    .sd-filter .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        width: 100%;
        outline: 2px solid #2d2d2d !important;
    }
}

@media (max-width:992px) {
    .filter-grid {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .sd-filter {
        flex-direction: column;
    }

    .sd-filter .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        width: 100%;
        outline: 2px solid #2d2d2d !important;
    }
}


@media (max-width:576px) {
    // .service-directory {
    //     padding: 1rem;
    // }

    .get-started {
        margin-bottom: -3.5rem !important;
    }

    .sd-filter {
        margin-top: -10px !important;
    }

    .sd-cards-main {
        padding: 0 1rem !important;
    }

    .sd-cards-icon {
        width: 35px !important;
        height: auto;
    }

    .sd-card-profile {
        width: 50px !important;
        height: 50px !important;
        object-fit: contain;
    }

    .sd-location-div p {
        font-size: 14px !important;
    }

    .sd-name-div p {
        font-size: 14px !important;
    }

    .sd-card-msg {
        font-size: 14px !important;
    }

    .sd-header {

        p {
            font-size: 24px;
            color: #fff;
        }

        img {
            width: 30px !important;
        }
    }

}

.sd-more-btn {
    width: fit-content;
    width: -moz-fit-content;
    padding: 0 !important;
    color: #faa61a !important;
    font-family: 'Gotham' !important;
    text-transform: capitalize !important;

    &:hover {
        background-color: transparent;
        padding: 0;
    }
}

.get-started {
    border-top: 5px solid #faa61a;
    border-left: 5px solid #faa61a;
    border-right: 5px solid #faa61a;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: #47463E;
    position: fixed;
    padding: 1rem 1rem;
    bottom: 0;
    width: 55%;
    left: 0;
    right: 0;
    z-index: 999;
    margin: auto;
    margin-bottom: 0rem;
    box-sizing: border-box;
  
    
    @media (max-width:2000px) {
        width: 65% !important;
    }

    @media (max-width:1900px) {
        width: 65% !important;
    }

    @media (max-width:1800px) {
        width: 70% !important;
    }

    @media (max-width:1700px) {
        width: 73% !important;
    }

    @media (max-width:1600px) {
        width: 77% !important;
    }

    @media (max-width:1400px) {
        width: 83% !important;
    }

    @media (max-width:1400px) {
        width: 85% !important;
    }

    @media (max-width:1300px) {
        width: 85% !important;
    }

    @media (max-width:1200px) {
            width: 80% !important;
        }

    @media (max-width:1100px) {
            width: 80% !important;
        }

    @media (max-width:1024px) {
            width: 80% !important;
        }

    @media (max-width:992px) {
            width: 80% !important;
        }

    @media (max-width:768px) {
            width: 80% !important;
        }

    @media (max-width:576px) {
        width: 90% !important;
    }


    .close-get-started {
        position: absolute;
        top: -30px;
        right: 9px;
        background-color: #060606;
        // border: 2px solid #fff;
        border-top-right-radius: 10px;
        cursor: pointer;
        border-radius: 0;
        // width: 25px;
        height: 25px;

        display: flex;
        align-items: center;
        justify-content: center;
    

    p {
        color: #fff;
        font-family: 'Gotham' !important;
        padding: 1px 7px;
        font-size: 13px !important;
    }

}

.get-started-content {
    p {
        color: #ffffff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'GothamBold' !important;
        font-size: 20px;

        @media (max-width: 992px) {
                font-size: 16px !important;
            }

        @media (max-width: 576px) {
            font-size: 14px !important;
        }
    }
}

.get-started-btn {
    border-radius: 10px;
    // border: 3px solid #2e2e2e;
    background-color: rgba(20, 146, 72, 1);
    color: #fff;
    font-family: 'GothamBold' !important;
    font-size: 20px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    gap: 0.1rem;

    @media (max-width:992px) {
            font-size: 17px !important;
        }

    @media (max-width:576px) {
        font-size: 14px !important;
    }

    .btn-arrow {
        display: block;
        object-fit: contain;
        width: 35px;
        height: auto;
    }

    &:hover {
        text-decoration-line: none;
        background-color: rgb(11, 116, 55);
        box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
    }

    &:active {
        text-decoration-line: none;
    }

    &:focus {
        text-decoration-line: none;
    }
    &:focus-visible {
        text-decoration-line: none;
    }

    &:focus-within {
        text-decoration-line: none;
    }
}
}

// .get-started {
//     border: 5px solid #fff;
//     border-top-right-radius: 30px;
//     border-top-left-radius: 30px;
//     background-color: #faa61a;
//     // position: fixed;
//     position: relative;
//     padding: 1rem;
//     bottom: 0;
//     width: 100%;
//     left: 0;
//     right: 0;
//     // z-index: 1100;
//     margin: auto;
//     margin-bottom: -4.15rem ;
//     box-sizing: border-box;


//     .close-get-started {
//         position: absolute;
//         top: -28px;
//         right: 12px;
//         background-color: #050505;
//         cursor: pointer;

//         p {
//             color: #fff;
//             font-family: 'Gotham' !important;
//             padding: 1px 7px;
//             font-size: 14px !important;
//         }

//     }

//     .get-started-content {
//         p {
//             color: #141414;
//             text-align: center;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-family: 'GothamBold' !important;
//             font-size: 20px;
//         }
//     }

//     .get-started-btn {
//         border-radius: 13px;
//         border: 3px solid #2e2e2e;
//         background-color: rgba(20, 146, 72, 1);
//         color: #fff;
//         font-family: 'GothamBold' !important;
//         font-size: 20px;
//         padding: 5px 15px;
//         display: flex;
//         align-items: center;
//         gap: 0.1rem;

//         .btn-arrow {
//             display: block;
//             object-fit: contain;
//             width: 35px;
//             height: auto;
//         }
//     }
// }

.reload-icon {
    font-size: 10px;

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.reload-icon {
        color: #faa61a !important;
    }
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.reload-icon {
    font-size: 35px;
}

.filter-map {
    .google-map-section {
        display: none !important;
    }

    .address-suggesion {
        color: #ffffff !important;
        font-size: 16px;
        cursor: pointer;
        padding: 10px;
        position: absolute !important;
        bottom: -50px !important;
        background: #2d2d2d !important;
        width: 91% !important;
        border-radius: 10px;
        border: 2px solid #faa61a;
    }


}



.service-list-grid {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    position: relative;
    align-items: start;
    margin: 2rem auto !important;

    .list-eqp-left-list-item {
        font-size: clamp(16px, 3vw, 20px);
        font-family: "Gotham" !important;
        margin: 1rem 0;
        font-weight: 300;
        color: #fff !important;
    }

    .step-bars {
        display: grid;
        justify-content: space-between;
        width: 100%;
        margin-top: 0px;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        margin-bottom: 1rem;
    }

    .list-eqp-steps-heading {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0rem;
    }

    .list-eqp-steps-heading p {
        color: #fff;
        font-size: clamp(26px, 3vw, 45px);
        font-family: "GothamBold" !important;
        text-transform: uppercase;
    }

    .list-eqp-steps-subheading p {
        color: #faa61a;
        font-size: clamp(18px, 3vw, 25px);
        font-family: "GothamBold" !important;
    }

    .hide-checkbox {
        span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
            color: #fff;
            font-family: 'GothamBold' !important;
        }
    }

    .list-eqp-white-p {
        color: #fff;
        margin: 1rem 0 1rem 0;
        font-size: clamp(16px, 3vw, 20px);
        font-family: "Gotham" !important;
    }

    .list-eqp-input-label {
        text-transform: capitalize !important;
    }

    .cmmn-green-btn {
        width: auto;
        line-height: normal;
        padding-top: 0.7rem !important;
        padding-bottom: 0.7rem !important;
    }
}

@media (max-width: 1360px) {
    .service-list-grid {
        width: 100% !important;
    }
}

@media (max-width:992px) {
    .service-list-grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.new-textarea {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline {
        border-radius: 10px;
    }
}

@media (max-width:1400px) {
    .get-started {
        width: 85% !important;
    }

}

@media (max-width:768px) {
    .get-started {
        margin-bottom: 0rem !important;

        width: 90% !important;
    }


}

