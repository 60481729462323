.gq {
    width: 100%;
    margin: 2rem auto;
    box-sizing: border-box;

    .gq-in {
        margin: 2rem 0;
    }

    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters p {
        font-size: clamp(14px, 3vw, 18px) !important;
    }

    .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
        margin: 0 !important;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters {
        margin: 0 !important;
    }

    .MuiAccordionSummary-expandIconWrapper {
        color: #faa61a;
        padding-right: 10px;
    }

    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
        margin: 0;
        padding: 0;
    }

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: unset !important;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
        background: transparent !important;
        box-shadow: none !important;
        color: #fff;
        border-bottom: 1px solid #fff;
        border-radius: 0px !important;
        font-family: 'Montserrat' !important;

    }

    .MuiButtonBase-root.MuiAccordionSummary-root {
        padding: 0 !important;
    }

    .MuiAccordionDetails-root {
        padding: 0px 0px 10px 0px !important;

        p {
            font-size: 14px !important;
            font-family: "Montserrat" !important;
            font-weight: 400;
        }

        ul {
            margin: 0;

            li {
                font-size: 14px !important;
                font-family: 'Montserrat' !important;
                font-weight: 400;
            }
        }
    }
}

.gq-heading {
    font-size: 45px;
    font-family: 'GothamBold' !important;
    color: #fff;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    @media (max-width: 992px) {
        font-size: 30px !important;
    }

    @media (max-width: 768px) {
        font-size: 24px !important;
    }
}

.gq-subheading {
    font-size: 35px;
    font-family: 'GothamBold' !important;
    color: #faa61a;
    margin-top: 0.5rem !important;
    margin-bottom: 0 !important;

    @media (max-width: 992px) {
        font-size: 22px !important;
    }

    @media (max-width: 768px) {
        font-size: 15px !important;
    }
}

.gq-content {
    margin: 0.5rem 0;
    width: 50%;

    @media (max-width: 1024px) {
        width: 100% !important;
    }

    .gq-content-heading {
        font-size: 25px;
        font-family: 'Montserrat' !important;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 1rem;

        @media (max-width: 992px) {
            font-size: 20px !important;
        }

        @media (max-width: 768px) {
            font-size: 16px !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
        background: transparent;
        box-shadow: none;
        color: #fff;
        border-bottom: 1px solid gray;

        &::before {
            display: none;
        }
    }

    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
        padding: 0;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.plus-icon {
        color: #faa61a !important;
    }
}

@media (max-width: 576px) {
    .MuiAccordionDetails-root p {
        font-size: 13px !important;
    }
}