.buildcrew {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
    background-color: #47463E;
    border-radius: 20px;
    padding: 1rem 1rem 2rem 1rem;
    margin: 0rem 0;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);

    @media (max-width:992px) {
        grid-template-columns: 1fr !important;
    }

    .hw-card-img {
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 350px;
            object-fit: contain;

            @media (max-width:768px) {
                max-height: 200px !important;
            }
        }
    }
}