.section-top-border {
  height: 55px;
  width: 100%;
  background: #faa61a;
}

.img-hand-cellphone {
  height: 380px;
  object-fit: cover;
  margin-top: -30px;
}

.section-content {
  height: 270px;
  width: 100%;
  padding: 40px 0 40px 65px;
}

.section-title {
  font-size: 25px !important;
  color: #f9a519;
}

.img-check-mark {
  width: 16px;
  height: 18px;
}

.section-item-text {
  color: white;
  margin-left: 20px !important;
  margin-top: 5px !important;
  font-size: 16px !important;
}

.section-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.section-lists {
  display: flex;
  margin-top: 10px;
}

.list-right {
  margin-left: 70px;
}

.section-content-wrapper {
  display: flex;
  max-width: 1100px;
  left: 50%;
  position: relative;
  transform: translateX(calc(-50% - 100px));
}

@media screen and (max-width: 1024px) {
  .section-content {
      padding: 20px 0 40px 65px;
  }

  .testimonial-content {
      margin-right: 30px;
  }
}

@media screen and (max-width: 900px) {
  .section-content {
      margin-right: -80px;
      padding: 10px 0 10px 30px;
  }

  .section-lists {
      flex-direction: column;
  }

  .list-right {
      margin-left: unset;
  }

  .section-item {
      margin-top: unset;
  }
}

@media screen and (max-width: 600px) {
  .img-hand-cellphone {
      display: none;
  }

  .section-content-wrapper {
      left: unset;
      transform: unset;
  }

  .section-content {
      margin-right: unset;
      padding: 15px;
      height: unset;
  }

  .section-title {
      font-size: 24px !important;
  }
}