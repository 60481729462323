.main-contaner {
  padding-bottom: 0px;
  margin-bottom: 2rem;
}

.white-color {
  color: #fff;
}

.equipment-step-bar {
  display: flex;
  width: 19%;
  height: 7px;
  background-color: #e6e7e8;
  cursor: pointer;
}

.equipment-step-bar.active {
  background-color: #faa61a;
}

.form-fields {
  margin: 1rem 0 !important;
}

.contact-form-div {
  border: 5px solid #faa61a;
  border-radius: 25px;
  background-color: white;
  padding: 2rem;

  .white-color {
    color: #faa61a;
    text-decoration: underline;
    margin: 0rem 0 1rem 0;
  }

  .service-item__content-title {
    color: #faa61a;
    font-weight: 900;
  }

  .service-item__content-para {
    color: #faa61a;
    margin-top: 1rem;
  }

  .signin-btn {
    margin-top: 20px;
    padding: 5px 30px;
    border-radius: 15px;
    /* height: 50px; */
    background-color: #149247;
    text-transform: capitalize;
    font-size: clamp(18px, 3vw, 20px);
    border: 3px solid #faa61a;
    font-weight: 900;
    font-family: 'GothamBold';

    &:hover {
      background-color: #2d2d2d !important;
      color: #faa61a !important;
    }
  }
}

.upload-btn {
  font-weight: bold;
  background-color: #faa61a !important;
  padding: 0.5rem 1rem;
  border-radius: 7px !important;
  color: white;
  border: none;
  outline: none;
  box-shadow: 0 0 0 0 rgb(0, 0, 0) !important;
  height: 50px !important;
}

// input:hover::file-selector-button {
//     font-weight: bold;
//     background-color: #58544d !important;
//     padding: 0.5rem 1rem;
//     border-radius: 10px;
//     color: white;
//     border: none;
//     outline: none;
//     text-transform: capitalize;
// }

.btns-grp {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.heading-div {
  display: flex;
  align-items: center;

  h5 {
    font-size: clamp(1.25rem, 3vw, 2rem) !important;
  }
}

.step-icon {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

.contact-form-inner {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-gap: 2rem;
  width: 100%;
}

.image_picker {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 2rem 0 1rem 0;
}

.contact-table-div {
  width: 100%;
  margin-top: 3rem;
  border-radius: 20px !important;

  .contact-table {
    border-radius: 20px !important;
  }
}

.contact-table-container {
  border-radius: 20px !important;
  border: 5px solid #faa61a;
}


@media screen and (max-width: 768px) {
  .contact-form-inner {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 1rem;
  }

  .contact-form-div {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 320px) {
  .contact-form-inner {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 1rem;
    margin: 5px;
  }

  .contact-form-div {
    padding: 2rem 1rem;
  }
}

.MuiList-root-MuiMenu-list {
  height: auto !important;
  max-height: 200px !important;
}

.photo-tips {
  width: 100%;
  display: block;
}

.photo-tips-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.yellow-line {
  border: none;
  background-color: #faa61a;
  height: 2px;
}

.radio-grp.list-epq-radio-grp {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #fff !important;
}

.form-fields.form-fields-radio {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 1rem;

  .radio-grp {
    display: flex;
    flex-direction: row;
    align-items: center;
    // color: #2d2d2d !important;
  }
}

.radio-grp.list-eqp-page {
  color: #fff !important;
}

.img-box-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  .img-box-item {
    position: relative;
    max-height: 200px;
    height: 150px;

    img.main-img-box-item {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      border: 4px solid #faa61a;
    }

    img.cancel-img {
      position: absolute;
      top: -7px;
      right: -15px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      z-index: 1000;
    }

    img.img-check {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: 7px;
      left: 7px;
      z-index: 1000;
    }
  }
}


.address-suggesion {
  color: #faa61a;
  //margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
  // background-image: url('../../assets/images/banner_img.png') !important;
  padding: 10px;
}




// ---------------------------------------//
// --------------New Design---------------//


.list-eqp-sec.list-eqp-sec-page {
  // width: 70%;
  margin: 2rem auto;

  .list-eqp-inner {
    .list-eqp-heading {
      p {
        font-size: clamp(30px, 3vw, 50px) !important;
        color: #fff;
        font-family: "GothamBold" !important;
        text-align: center;
      }
    }


    .list-eqp-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      position: relative;
      align-items: start;
      margin: 2rem 0;

      .list-eqp-grid-item {
        &:first-child {
          .list-eqp-white-p {
            color: #fff;
            margin: 1rem 0;
            font-size: clamp(16px, 3vw, 20px);
            font-family: 'GothamBold' !important;
          }

          .list-eqp-yellow-p {
            color: #faa61a;
            font-size: clamp(18px, 3vw, 30px);
            font-family: 'GothamBold' !important;
          }

          .list-eqp-left-list {
            color: #fff;
            list-style: disc !important;
            padding-left: 1rem;

            .list-eqp-left-list-item {
              font-size: clamp(16px, 3vw, 20px);
              font-family: 'Gotham' !important;
              margin: 1rem 0;
              font-weight: 300;
            }
          }
        }

        &:last-child {
          .list-eqp-white-p {
            color: #fff;
            margin: 1rem 0 1rem 0;
            font-size: clamp(16px, 3vw, 20px);
            font-family: 'Gotham' !important;
          }

          .list-eqp-steps-heading {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 0rem;

            img {
              display: block;
              height: 35px;
              width: 35px;
              max-height: 35px;
              object-fit: contain;
            }

            p {
              color: #fff;
              font-size: clamp(26px, 3vw, 45px);
              font-family: 'GothamBold' !important;
              text-transform: uppercase;
            }
          }

          .list-eqp-steps-subheading {
            p {
              color: #faa61a;
              font-size: clamp(18px, 3vw, 25px);
              font-family: 'GothamBold' !important;
            }
          }
        }
      }
    }
  }
}

.step-bar-container {
  display: grid;
  justify-content: space-between;
  width: 100%;
  margin-top: 0px;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
}

.equipment-step-bar {
  display: flex;
  width: 100%;
  height: 4px;
  background-color: #e6e7e8;
  cursor: pointer;
  margin: 0.5rem 0;
}

.list-eqp-input-label {
  color: #fff;
  margin: 0rem 0 0.5rem 0;
  font-size: clamp(16px, 3vw, 20px);
  font-family: 'Gotham' !important;
  text-transform: capitalize;
}

.cmmn-green-btn {
  background-color: #149247 !important;
  font-family: 'GothamBold' !important;
  font-size: clamp(18px, 3vw, 25px) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  padding: 0rem 2rem !important;
  width: 40%;
}

.btns-grp.list-eqp-btn-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 0 0;
}

.form-fields.list-eqp-form-fields {
  margin: 0.7rem 0 !important;
}

// .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
//   padding-right: 32px;
//   background-color: white;
//   border: 2px solid #faa61a;
//   border-radius: 10px;
// }

.list-eqp-steps-label {
  color: #fff !important;
  // font-family: 'Gotham' !important;
}

.radio-label.list-eqp-steps-label {
  color: #fff !important;
  font-family: 'Gotham' !important;
}


.form-ctrl-label {
  color: #fff !important;
  font-family: 'Gotham' !important;
  .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    padding-left: 0.5rem;
  }
}

.form-label-yellow {
  color: #faa61a;
  font-family: 'GothamBold' !important;

  span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
      font-family: 'GothamBold' !important;
      color: #faa61a;
      width: max-content;
    }
}

span.radio-span-yellow {
  color: #faa61a !important;
  font-family: 'GothamBold' !important;
}

span.radio-span-white {
  color: #fff !important;
  margin-left: 8px;
  
  a {
    color: #fff;
    text-decoration-color: #fff;
  }
}

.radio-grp {
  display: flex;
  align-items: flex-start !important;
  gap: 0.5rem;
  justify-content: flex-start !important;
  margin: 0.4rem 0;

  span.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.MuiRadio-root.MuiRadio-colorPrimary.MuiRadio-root.MuiRadio-colorPrimary {
    padding: 0;
    margin: 0;
  }

    label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.radio-grp {
      margin-left: 0;
      margin-right: 0;
    }
}



// .form-ctrl-label-1 {
//   color: #fff !important;
//   font-family: 'GothamBold' !important;
// }

.new-radio-grp {
  span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    font-family: 'Gotham' !important;
  }
}

// span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
//   color: #ffffff !important;
//   font-family: 'Gotham' !important;
// }

// span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
//   color: #2d2d2d !important;
//   font-family: 'Gotham' !important;
// }

.MuiSelect-select .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {}

.MuiInputBase-input.MuiFilledInput-input.MuiInputBase-root-MuiFilledInput-roott:after {
  border-bottom: 2px solid #1976d200;
}

.MuiInputBase-input.MuiFilledInput-input.MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: 2px solid #1976d200;
}

// .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
//   color: #faa61a !important;
// }

// .Mui-checked {
//   color: #faa61a !important;
// }

// svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1hbvpl3-MuiSvgIcon-root {
//   color: white !important;
// }

// svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root {
//   color: white !important;
// }

// .MuiSvgIcon-fontSizeMedium {
//   color: white !important;
// }

// .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
//   color: #fff !important;
// }

// .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
//   color: #fff !important;
// }

.select-field {
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #2d2d2d !important;
  }
}

.MuiSvgIcon-root+.MuiSvgIcon-root {
  color: #faa61a !important;
}

.img-box.list-eqp-img-box {
  margin: 1rem 0;
}

.list-eqp-cross {
  position: absolute;
  width: 25px !important;
  height: 25px !important;
  width: 30px;
  height: 30px;
  background-color: #faa61a;
}

.cross-btn.list-eqp-cross {
  top: -12px !important;
  right: -13px !important;
}

.img-box-item.list-eqp-img-item {
  position: relative;
}

.list-eqp-suggestion-p {
  font-size: clamp(11px, 3.5vw, 18px) !important;
  color: #fff;
  font-family: 'Gotham';
}

.list-eqp-photo-tips {
  margin: 1rem 0;
}

.img-box-item.list-eqp-img-box-item {
  border: 4px solid #faa61a;
}

img.main-img-box-item.list-eqp {
  border: none !important;
}

.img-box-grid.list-eqp-img-box-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
}

.set-rental-price-p {
  font-size: clamp(18px, 3vw, 25px) !important;
  color: #fff;
  font-family: 'GothamBold' !important;
}

#demo-simple-select-label {
  font-family: 'GothamBold' !important;
  padding-top: 2px;
  color: #2d2d2d;
}

.list-eqp-select-options {
  font-family: 'GothamBold' !important;
}


// .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  // max-height: 230px;
  
// }


@media screen and (max-width:992px) {
  .list-eqp-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem !important;
    position: relative;
    align-items: start;
    margin: 2rem 0;
  }

  .list-eqp-suggestion-p {
    font-size: clamp(11px, 3.5vw, 15px) !important;
  }

  .img-box-grid.list-eqp-img-box-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1.5rem;
  }
}

@media screen and (max-width:768px) {
  .list-eqp-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-gap: 2rem;
    position: relative;
    align-items: start;
    margin: 2rem 0;
    padding: 0 1rem;
  }

  .img-box-item.list-eqp-img-box-item {
    max-height: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width:576px) {
  .img-box-item.list-eqp-img-box-item {
    max-height: 150px !important;
    height: 120px !important;
  }
}

.radio-grp.list-eqp-page {
  color: #fff !important;

  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #ffffff !important;
  }
}

.select-dropdwn {
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #2d2d2d !important;
  }
}

.select-dropdwn {
  &:hover {
    border: #faa61a;
  }
}

.MuiAutocomplete-endAdornment .MuiSvgIcon-root:not(.MuiSvgIcon-root~.MuiSvgIcon-root) {
  color: #2d2d2d !important;
}