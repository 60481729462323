.popup-input-strp {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #faa61a;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  display: block;
  margin: 0.5rem 0;
  font-family: "Gotham" !important;
  font-size: 14px !important;
}

.popup-input-strp-2 {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #faa61a;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  display: block;
  margin: 0.5rem 0 0 0;
  font-family: "Gotham" !important;
  font-size: 14px !important;
}

.wallet-accordion {
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: rgb(0, 0, 0) !important;
}
  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

  .Mui-expanded {
    margin: 0 !important;
    min-height: auto !important;
  }

  .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
    margin: 0 !important;
    padding: 0 12px;
    background: #faa61a;
    border-radius: 10px;
  }

  .wallet-accordion-footer {
    // margin-top: 1rem;
      .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
          color: #faa61a !important;
        }
  }

  .MuiFormControlLabel-label {
    //font-family: "GothamBold" !important;
    color: #000;
  }
}