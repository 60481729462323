header {
  // background-color: #a56927f0!important;
  background-color: #2e2e2e !important;
  box-shadow: none !important;
  position: fixed !important;
  border-bottom: 10px solid #faa61a;
  z-index: 1400;
}

.menu_class div {
  margin-top: 5px !important;
}

.menu_class div ul {
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
  position: relative !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  outline: 0 !important;
  background: #231f20 !important;
  // border: 2px solid #fff !important;
}

.menu_class div ul li a {
  text-decoration: none !important;
}

.menu_class div ul li p {
  color: #ffffff !important;
  text-decoration: none !important;
}

.menu_class div ul li p:hover {
  text-decoration: none !important;
}

.sub-menus-custom a {
  border: 2px solid #fff !important;
}

.sub-menus-custom:hover {
  background: none !important;
}


.google-auto-complate {
  border: 3px solid rgb(250, 166, 26);
  border-radius: 3px;
  background: white;
  height: 37px;
  width: 92.5%;
  margin-bottom: 8px;
  // margin-left: -6px;
}

.menu-img {
  width: 40%;
  margin-top: 3px;
}

.no-data {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  color: #fff;
}

.menu_img_link {
  max-height: 35px;
  margin: 0px 20px;
}

.menu_img_link_dart {
  max-height: 50px;
  // margin: 0px 10px;
  margin-right: 15px;
}

.menu_img_link_download {
  max-height: 40px;
  margin: 0px 10px;
  margin-right: 25px;
  //cursor: pointer;
}


.header {
  &-logo {
    height: 50px;
  }

  &-mobile-icon {
    height: 40px;
  }

  a {
    text-decoration: none;
    color: white;
    border: none;
    background: none;

    &:hover {
      color: #faa61a;
    }
  }
}

.nav-item-wrapper {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.nav-item {
  margin-right: 24px !important;
  text-decoration: none;
  cursor: pointer;
  background-color: #231f20;
  padding: 0 10px;
  border-radius: 8px;
  border: 2px solid #faa61a;
}

.nav-items {
  display: none;
}

// Hamburger Button
.hamburger {
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  // z-index: 101;
  z-index: 1300;
  display: none;

  span {
    position: relative;
    display: block;
    width: 20px;
    height: 3px;
    margin-bottom: 5px;
    background: #faa61a;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }

  &.active span {
    opacity: 1;
    transform: rotate(45deg) translate(-4px, -9px);

    &:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-last-child(2) {
      transform: rotate(-45deg) translate(-3px, 10px);
    }
  }
}

.nav-items {
  position: absolute;
  display: flex;
  padding: 2rem 1rem 8rem 1rem;
  margin-bottom: 5rem;
  margin-top: -10px;
  top: 0;
  left: -100%;
  width: 200px;
  height: 100vh;
  // height: calc(100vh - 3rem);
  // overflow-y: auto;
  background-color: #231f20;
  align-items: flex-start;
  flex-direction: column;
  transition: left 0.3s ease-in-out;
  z-index: 1300;

  div {
    overflow-y: auto;
    width: 100%;
    padding-bottom: 0rem;
    height: calc(100% - 5rem);

    .nav-item {
      height: auto !important;
      display: block;
    }
  }

  &.active {
    left: 0;
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#13161A, 0.8);
  z-index: 1000;
  animation: fadeIn .3s ease-in-out;

  &.active {
    display: block;
    animation: fadeIn .3s ease-in-out;
  }
}

#more-btn {
  color: #fff;
  font-family: 'GothamBold';
  display: none;
  font-size: clamp(14px, 3vw, 16px);

}

.nav-item-wrapper.nav-box-wrapper {
  width: 100% !important;
}

// @media screen and (min-width:1401px) {
//   .submenu-list {
//       display: none;
//       background: #ffffff00;
//       // display: flex;
//       flex-direction: column;
//       position: absolute;
//       // top: 40px;
//       border-left: 2px solid #faa81a00;
//       border-bottom: 2px solid #faa61a00;
//       border-right: 2px solid #faa61a00;
//       color: black;
//       padding: 0 1rem 0.5rem 1rem;
//       border-bottom-left-radius: 10px;
//       border-bottom-right-radius: 10px;
//       top: 50px !important;
//     }
// }

@media screen and (max-width:1400px) {
    .submenu-list {
        display: none;
        // background: #ffffff00;
        // display: flex;
        flex-direction: column;
        position: absolute;
        // top: 40px;
        border-left: 2px solid #faa81a00;
        border-bottom: 2px solid #faa61a00;
        border-right: 2px solid #faa61a00;
        color: black;
        padding: 0 1rem 0.5rem 1rem;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        top: 55px !important;
      }
  .drpdown-btn-box {
    display: none !important;
}

  .nav-link {
    width: max-content !important;
  }

  #more-btn {
    // display: block !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px;

    img {
      width: 12px;
      height: auto;
      display: block;
    }
  }

  .nav-box {
    &:first-child {
      .nav-link.desktop-visible:nth-child(7) {
        display: none !important;
      }
      .nav-link.desktop-visible:nth-child(6) {
        display: none !important;
      }

      // .nav-link.desktop-visible:nth-child(5) {
      //   display: none !important;
      // }

      //  .nav-link.desktop-visible:nth-child(4) {
      //    display: none !important;
      //  }

      //  .nav-link.desktop-visible:nth-child(3) {
      //    display: none !important;
      //  }
    }
  }
}

@media screen and (max-width:1200px) {
  .drpdown-btn-box {
    display: none !important;
}
  .nav-link {
    width: max-content !important;
  }

  #more-btn {
    // display: block !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px;

    img {
      width: 12px;
      height: auto;
      display: block;
    }
  }

  .nav-box {
    &:first-child {
      .nav-link.desktop-visible:nth-child(7) {
        display: none !important;
      }
      .nav-link.desktop-visible:nth-child(6) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(5) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(4) {
        display: none !important;
      }

      // .nav-link.desktop-visible:nth-child(3) {
      //   display: none !important;
      // }
    }
  }
}

@media screen and (max-width:1024px) {
  .drpdown-btn-box {
    display: none !important;
}
  .nav-link {
    width: max-content !important;
  }

  #more-btn {
    // display: block !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px;

    img {
      width: 12px;
      height: auto;
      display: block;
    }
  }

  .nav-box {
    &:first-child {
      .nav-link.desktop-visible:nth-child(7) {
        display: none !important;
      }
      .nav-link.desktop-visible:nth-child(6) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(5) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(4) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(3) {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width:992px) {
  .drpdown-btn-box {
    display: none !important;
}

  .nav-link {
    width: max-content !important;
  }

  #more-btn {
    // display: block !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px;

    img {
      width: 12px;
      height: auto;
      display: block;
    }
  }

  .nav-box {
    &:first-child {
      .nav-link.desktop-visible:nth-child(7) {
        display: none !important;
      }
      .nav-link.desktop-visible:nth-child(6) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(5) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(4) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(3) {
        display: none !important;
      }

      .nav-link.desktop-visible:nth-child(2) {
        display: none !important;
      }
    }
  }

  // .nav-box {
  //   &:first-child {
  //     display: none;
  //   }

  //   .nav-link {
  //     &:last-child {
  //       display: none;
  //     }
  //   }
  // }

}

@media screen and (max-width:768px) {

  .modal-popup {
      top: -20px !important;
      right: -11px !important;
      
      width: 17px !important;
      height: 17px !important;
      color: #fff !important;
    }
    
  // .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  //   display: none !important;
  // }

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-s4oae-MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item-wrapper.nav-box-wrapper {
    width: 50px !important;
  }

  .more-btn {
    display: none !important;
  }

  .nav-link {
    width: max-content !important;
  }


  .nav-box-wrapper {
    // overflow-x: scroll;

    .nav-box {
      align-items: center;
    }
  }
}


@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-item-wrapper {
    flex-direction: column;

    a {
      margin-right: 0;
    }
  }

  .desktop-visible {
    display: none;
  }

  .nav-item {
    margin-right: 0 !important;
    margin-bottom: 10px !important;

    &:last-child {
      margin-bottom: unset;
    }
  }
}

.session-expire {
  border: 7px solid #faa61a;
  padding: 15px 50px;
  background: #2e2c2c;
}

// .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  // width: 100px !important;
  // height: fit-content !important;
  // padding: 0.5rem 2rem 1rem 2rem !important;
  // border-left: 2px solid #faa61a !important;
  // border-right: 2px solid #faa61a !important;
  // border-bottom: 2px solid #faa61a !important;
  // top: 90px !important;
  // border-bottom-left-radius: 10px !important;
  // border-bottom-right-radius: 10px !important;
  // overflow: hidden !important;
// }



.notifications-number {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 11px;
}

.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 100% !important;
  height: 100% !important;
  // overflow: hidden !important;
  // padding: 1rem 2rem !important;
  // border-left: 2px solid #faa61a;
  // border-right: 2px solid #faa61a;
  // border-bottom: 2px solid #faa61a;
}

.nav-drpdn {
  color: #faa61a !important;
  font-size: clamp(14px, 3vw, 20px) !important;
  text-transform: capitalize !important;
  font-family: "Gotham" !important;
  display: flex;
  // gap: 10px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: right;
  padding: 0 !important;

  .top-part {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 17px !important;
    // span {
    //   font-size: 17px !important;
    // }


    .welcome-notify-numbers {
      background-color: red;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 10px;
      position: absolute;
      top: 0px;
      left: -20px;
    }
  }

  .btm-part {
    width: 100%;

    p {
      font-size: 14px !important;
    }

    .wallet-bal {
      color: #fff;
    }
  }

}

.drpdn-icon {
  img {
    width: 15px;
    height: auto;
    display: block;
  }
}

.flag-icon {
  img {
    width: 35px;
    height: auto;
    display: block;
  }
}

.submenu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin: 0 !important;
  padding: 2px 0 !important;
  text-align: center !important;
  display: flex;
  justify-content: center !important;
  font-family: "GothamBold" !important;
  color: #757575 !important;
}

.gray-line {
  border: 1px solid #757575 !important;
  background-color: #757575 !important;
}

.gray-line.gray-lineee {
  opacity: 0.3;
}

button#toolbox-btn {
  color: white;
  font-family: 'GothamBold' !important;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 12px;
    height: auto;
    display: block;
  }
}

.nav-link {
  margin: 0 0.8rem !important;
  position: relative;
  cursor: pointer;



  // border-bottom: 2px solid #faa81a00;
  p {
    font-family: 'GothamBold' !important;
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 12px;
      height: auto;
      display: block;
    }
  }


  &:hover {
    p {
      color: #fff !important;
    }

    :after {
      position: absolute;
      content: "";
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #faa61a;
      height: 4px;
      left: 50%;
      bottom: -7px;
      transform: translate(-50%, 0);
      margin-top: 10px;
    }
  }
}

.nav-box-wrapper {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  .nav-box {
    display: flex !important;
    align-items: center !important;

    &:first-child {
      margin-left: 1rem;
    }
  }
}

.nav-box.nav-right-sec {
  a {
    margin: 2px !important;
    padding: 0 !important;
    color: #faa61a;
    font-family: 'Gotham' !important;
  }
}

.menu-active {
  p {
    color: #fff !important;
  }

  :after {
    position: absolute;
    content: "";
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faa61a;
    height: 4px;
    left: 50%;
    bottom: -7px;
    transform: translate(-50%, 0);
    margin-top: 10px;
  }
}

.dropdwn-pop {
  div {
    &:nth-child(2) {
      background-color: red !important;
    }

    &:nth-child(3) {
      // background-color: rgb(255, 255, 255) !important;
      background-color: #2d2d2d !important;
      width: auto !important;
      height: auto !important;
      padding: 0.5rem 1rem 1rem 1rem !important;
      border-left: 2px solid #faa61a !important;
      border-right: 2px solid #faa61a !important;
      border-bottom: 2px solid #faa61a !important;
      top: 96px !important;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }
}

.dropdwn-pop.welcome-drp {
  div {
    &:nth-child(2) {
      background-color: red !important;
    }

    &:nth-child(3) {
      background-color: #2e2e2e !important;
      // background-color: rgb(255, 255, 255) !important;
      width: 210px !important;
      max-width: 250px !important;
      height: auto !important;
      padding: 0.5rem 1rem 0rem 1rem !important;
      border-left: 2px solid #faa61a !important;
      border-right: 2px solid #faa61a !important;
      border-bottom: 2px solid #faa61a !important;
      top: 94px !important;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      right: 0 !important;
      padding-top: 0 !important;
    }
  }
}

// @media screen and (min-width:1401) {
//   .submenu-list {
//       display: none;
//       background: rgba(255, 255, 255, 0);
//       flex-direction: column;
//       position: absolute;
//       border-left: 2px solid rgba(250, 168, 26, 0);
//       border-bottom: 2px solid rgba(250, 166, 26, 0);
//       border-right: 2px solid rgba(250, 166, 26, 0);
//       color: black;
//       padding: 0 1rem 0.5rem 1rem;
//       border-bottom-left-radius: 10px;
//       border-bottom-right-radius: 10px;
//       top: 49px !important;
//     }
// }



@media (min-width: 600px) {
  .MuiToolbar-root {
    min-height: 74px !important;
  }
}
@media screen and (max-width:1400px) {
    // .submenu-list {
    //     display: none;
    //     background: rgba(255, 255, 255, 0);
    //     flex-direction: column;
    //     position: absolute;
    //     border-left: 2px solid rgba(250, 168, 26, 0);
    //     border-bottom: 2px solid rgba(250, 166, 26, 0);
    //     border-right: 2px solid rgba(250, 166, 26, 0);
    //     color: black;
    //     padding: 0 1rem 0.5rem 1rem;
    //     border-bottom-left-radius: 10px;
    //     border-bottom-right-radius: 10px;
    //     top: 51px !important;
    //   }

  .dropdwn-pop.welcome-drp {
      div {
        &:nth-child(2) {
          background-color: red !important;
        }
  
        &:nth-child(3) {
          // background-color: rgb(255, 255, 255) !important;
          background-color: #2d2d2d !important;
          width: 200px !important;
          height: auto !important;
          padding: 0.5rem 1rem 1rem 1rem !important;
          border-left: 2px solid #faa61a !important;
          border-right: 2px solid #faa61a !important;
          border-bottom: 2px solid #faa61a !important;
          top: 94px !important;
          border-bottom-left-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
          right: 0 !important;
        }
      }
    }
}

// ----

.drpdown-btn-box {
  position: relative;

  .nav-link.desktop-visible.learnmore.menus.new-more-btn {
    padding-top: 0;
    padding-bottom: 0;
  }

  p{  
    color: #fff;
    text-transform: capitalize;
  }

        &:hover {
          background-color: none !important;
          &::after {
            display: none !important;
            position: absolute;
            content: "";
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #faa61a;
            height: 4px;
            left: 50%;
            bottom: 15px;
            transform: translate(-50%, 0);
            // margin-top: -10px !important;
          }
        }
        .menus.new-more-btn {
            &:hover {
                &::after {
                  display: none !important;
                  position: absolute;
                  content: "";
                  width: 40%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #faa61a;
                  height: 4px;
                  left: 50%;
                  bottom: 15px;
                  transform: translate(-50%, 0);
                  // margin-top: -10px !important;
                }
              }
        }
}

// .submenu-list {
//   display: none;
//   background: #ffffff00;
//   flex-direction: column;
//   position: absolute;
//   border-left: 2px solid #faa81a00;
//   border-bottom: 2px solid #faa61a00;
//   border-right: 2px solid #faa61a00;
//   color: black;
//   padding: 0 1rem 0.5rem 1rem;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
//   top: 49px !important;

//   &::after {
//     display: none !important;
//   }
//     .submenu-link {
//       color: #757575 !important;
//       font-family: 'GothamBold' !important;
//       padding: 0.5rem 1rem !important;
//       font-size: clamp(14px, 3vw, 16px) !important;
//       text-align: center;
//       border-left: 2px solid #faa61a;
//       border-right: 2px solid #faa61a;
//       background-color: #fff;

//       &:last-child {
//         border-bottom-left-radius: 10px;
//         border-bottom-right-radius: 10px;
//         border-left: 2px solid #faa61a;
//         border-bottom: 2px solid #faa61a;
//         border-right: 2px solid #faa61a;
//         padding: 0.5rem 1rem !important;
//       }
//     }
// }



header .nav-box-wrapper .drpdown-btn-box  {
  color: #fff;
  padding: 1rem 0;
  &:hover {
    .submenu-list {
        display: flex !important;
        // top: 38px;
        right: 0;
        left: 0;
        position: absolute;
        justify-content: center;
        width: 100%;
      }
  }
}


// #more-btn-box {
//   &:hover {
//     #basic-menu {
//         display: flex !important;
//         top: 38px;
//         right: 0;
//         left: 0;
//         position: absolute;
//         justify-content: center;
//         width: 100%;
//       }
//   }
// }

#more-drpdown-btn-box {
  position: relative;

  .nav-link.desktop-visible.learnmore.menus.new-more-btn {
    padding-top: 0;
    padding-bottom: 0;
  }

  p {
    color: #fff;
    text-transform: capitalize;
  }

  &:hover {
    &::after {
      display: none !important;
      position: absolute;
      content: "";
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #faa61a;
      height: 4px;
      left: 50%;
      bottom: 15px;
      transform: translate(-50%, 0);
      // margin-top: -10px !important;
    }
  }

  .menus.new-more-btn {
    &:hover {
      &::after {
        display: none !important;
        position: absolute;
        content: "";
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #faa61a;
        height: 4px;
        left: 50%;
        bottom: 15px;
        transform: translate(-50%, 0);
        // margin-top: -10px !important;
      }
    }
  }
}

.submenu-list {
  display: none;
  background: #ffffff00;
  flex-direction: column;
  position: absolute;
  border-left: 2px solid #faa81a00;
  border-bottom: 2px solid #faa61a00;
  border-right: 2px solid #faa61a00;
  color: black;
  padding: 0 1rem 0.5rem 1rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 50px !important;

  &::after {
    display: none !important;
  }

  .submenu-link {
    color: #ffffff !important;
    font-family: 'GothamBold' !important;
    padding: 0.5rem 1rem !important;
    text-align: center;
    border-left: 2px solid #faa61a;
    border-right: 2px solid #faa61a;
    // background-color: #fff;
 
    background-color: #2d2d2d;

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left: 2px solid #faa61a;
      border-bottom: 2px solid #faa61a;
      border-right: 2px solid #faa61a;
      padding: 0.5rem 1rem !important;
    }
  }
}

.submenu-link {
  color: #757575 !important;
  font-family: 'GothamBold' !important;
  padding: 16px 0 !important;
}

@media screen and (max-width: 1400px) {
  .submenu-list {
    display: none;
    background: rgba(255, 255, 255, 0);
    flex-direction: column;
    position: absolute;
    border-left: 2px solid rgba(250, 168, 26, 0);
    border-bottom: 2px solid rgba(250, 166, 26, 0);
    border-right: 2px solid rgba(250, 166, 26, 0);
    color: black;
    padding: 0 1rem 0.5rem 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    top: 50.5px !important;
  }
}



header .nav-box-wrapper #more-drpdown-btn-box {
  color: #fff;
  padding: 1rem 0;

  &:hover {
    .submenu-list {
      display: flex !important;
      // top: 47px;
      right: 0;
      left: 0;
      position: absolute;
      justify-content: center;
      width: max-content;
    }
  }
}


/***********************************************************/
.modal-popup {
  position: absolute;
  top: -17px;
  right: -17px;
  background-color: #f3a119;
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  width: 20px !important;
  height: 20px !important;
  color: #fff !important;
}

.submenu-link{
  cursor: pointer !important;
}

.notification-bell-icon img {
  width: 30px;
  height: auto;
  display: block;
  filter: invert(100%);
  position: relative;
}
.notification-bell-icon sup{
  color: #fff;
  position: absolute;
  top: 10px;
  background: #ff0000;
  right: -8px;
  border-radius: 20px;
  border: 2px solid #2d2d2d;
  width: 15px;
  height: 15px;
  // padding: 2px 5px;
  padding: 2px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}