.hw {
    width: 60%;
    margin: auto;
    padding: 0rem !important;
    box-sizing: border-box;
    z-index: 2;
    position: relative;

    @media (min-width: 2001px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 2000px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1800px) {
        width: 70% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1700px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1600px) {
        width: 80% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1500px) {
        width: 90% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1400px) {
        width: 90% !important;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 1300px) {
        width: 100% !important;
    }

    @media (max-width: 1024px) {
        width: 100% !important;
    }
}

.bac-top {
    display: flex;
    align-items: center;
    margin: 0rem 0rem 1rem 0rem;
    gap: 10px;

    .bac-heading {
        font-size: 45px;
        font-family: 'GothamBold' !important;
        color: #fff;
        margin: 0;

        @media (max-width: 992px) {
            font-size: 30px !important;
        }

        @media (max-width: 768px) {
            font-size: 22px !important;
        }

        span {
            color: #faa61a;
        }
    }

    p.bac-subheading {
        color: #fff;
        font-family: 'Montserrat' !important;
        font-weight: 300;
        width: 80%;
        margin-top: 0.5rem;

        @media (max-width: 768px) {
            font-size: 13px !important;
            margin: 0.3rem 0 0 0 !important;
            width: 100% !important;
        }
    }

    .bac-btn {
        font-size: 16px !important;
        font-family: 'GothamBold' !important;
        color: #fff !important;
        background: #149247 !important;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        padding: 0.3rem 1rem !important;

        @media (max-width: 992px) {
            border-radius: 8px !important;
            font-size: 14px;
            width: max-content;
        }
    }
}

.listeqp-bg-img {
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 1;

    @media (max-width: 992px) {
        display: none !important;
    }

    img {
        width: 700px;
        height: 450px;
        display: block;
        object-fit: cover;
    }

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        box-shadow: inset 154px -118px 59px 0px rgb(46, 46, 46), inset 325px 508px 64px 0px rgb(0 0 0 / 50%);
    }
}

.lgt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: #47463E;
    border-radius: 20px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);
    margin: 2rem 0;

    @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;
        margin-top: 1rem !important;
        padding: 1rem 1.5rem !important;
    }

    .lgt-content {
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: -4rem;

        @media (max-width: 992px) {
            margin-top: 0rem !important;
        }

        @media (max-width: 768px) {
            text-align: center !important;
        }

        .lgt-heading {
            font-size: clamp(25px, 3vw, 35px) !important;
            font-family: 'GothamBold' !important;
            color: #faa61a;
            text-transform: capitalize;
            line-height: clamp(30px, 3vw, 55px);
            margin: 0 !important;
        }

        .lgt-text {
            color: #fff;
            font-size: 20px;
            line-height: 26px !important;
            font-weight: 300;
            font-family: 'Montserrat' !important;
            margin: 0 !important;

            @media (max-width: 768px) {
                font-size: 16px !important;
                line-height: 22px !important;
            }

            @media (max-width: 576px) {
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }

    .lgt-img {
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 300px;
            object-fit: contain;

            @media (max-width:768px) {
                width: 70% !important;
                margin: auto;
            }
        }
    }
}

.lgt-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: #47463E;
    border-radius: 20px;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.7);
    margin: 0rem 0rem 3rem 0rem;

    @media (max-width: 768px) {
        display: flex !important;
        flex-direction: column !important;
        gap: 1.5rem !important;
        margin-top: 1rem !important;
        padding: 1rem 1.5rem !important;
    }

    @media (max-width: 576px) {
        margin-top: 1rem !important;
    }

    .lgt-content-2 {
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width:768px) {
            text-align: center !important;
            gap: 0.5rem !important;
        }
    }

    .lgt-heading {
        font-size: clamp(25px, 3vw, 35px) !important;
        font-family: 'GothamBold' !important;
        color: #faa61a;
        text-transform: capitalize;
        line-height: clamp(30px, 3vw, 55px);
        margin: 0 !important;
    }

    .lgt-text {
        color: #fff;
        font-size: 20px;
        line-height: 26px !important;
        font-weight: 300;
        font-family: 'Montserrat' !important;
        margin: 0 !important;

        @media (max-width: 768px) {
            font-size: 16px !important;
            line-height: 22px !important;
        }

        @media (max-width: 576px) {
            font-size: 14px !important;
            line-height: 20px !important;
        }

    }

    .lgt-img {
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 300px;
            object-fit: contain;

            @media (max-width: 576px) {
                width: 70% !important;
                margin: auto;
            }
        }
    }
}

.edp {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin: 3rem 0;

    @media (max-width: 768px) {
        gap: 1rem !important;
        align-items: flex-start !important;
    }

    .edp-img {
        img {
            width: 150px;
            height: auto;
            display: block;
            object-fit: contain;

            @media (max-width: 992px) {
                width: 120px !important;
            }

            @media (max-width: 768px) {
                width: 100px !important;
            }

            @media (max-width: 576px) {
                width: 80px !important;
            }
        }
    }

    .edp-content {
        display: flex;
        gap: 1rem;
        flex-direction: column;

        @media (max-width: 576px) {
            gap: 0.5rem !important;
        }
    }

    .edp-heading {
        color: #fff;
        font-size: clamp(16px, 3vw, 40px);
        font-family: 'GothamBold' !important;
        margin: 0 !important;
    }

    .edp-text {
        color: #fff;
        font-size: clamp(12.5px, 3vw, 20px);
        font-family: 'Montserrat' !important;
        margin: 0 !important;

        span {
            color: #faa61a;
        }

        a {
            color: #faa61a;
            text-decoration-color: #faa61a;
            font-weight: 600;
        }
    }
}

.mipt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    margin: 0rem 0rem 0rem 0rem;

    @media (max-width: 992px) {
        gap: 2rem !important;
        grid-template-columns: 1fr !important;
    }

    @media (max-width: 768px) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    @media (max-width: 576px) {
        gap: 2.5rem !important;
    }

    .mipt-item {
        border-radius: 13px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background-color: #47463E;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);

        @media (max-width: 576px) {
            padding: 1.4rem !important;
        }

        p.mipt-item-heading {
            color: #faa61a;
            font-size: 30px;
            font-family: 'GothamBold' !important;
            text-align: center;

            @media (max-width:768px) {
                font-size: 20px !important;
            }
        }

        p.mipt-item-heading-2 {
            color: #faa61a;
            font-size: 24px;
            font-family: 'GothamBold' !important;
            text-align: center;
            margin: 0;
        }

        p.mipt-item-text {
            color: #ffffff;
            font-size: clamp(14px, 3vw, 18px);
            line-height: 22px;
            font-family: 'Montserrat' !important;
            font-weight: 300;
            text-align: center;

            a {
                color: #faa61a;
                text-decoration-color: #faa61a;
                font-weight: 600;
            }

            span {
                color: #faa61a;
                font-weight: 600;
            }
        }

        p.mipt-item-text-2 {
            color: #ffffff;
            font-size: clamp(13px, 3vw, 18px);
            line-height: 23px;
            font-family: 'Montserrat' !important;
            text-align: center;

            a {
                color: #faa61a;
                text-decoration-color: #faa61a;
            }
        }

        .mipt-item-img {
            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 100px;
            }

            // @media (max-width: 768px) {
            //     order: 3;
            // }
        }

        .mipt-item-img-2 {
            img {
                display: block;
                object-fit: contain;
                width: 100%;
                height: 150px;
                @media (max-width: 576px) {
                    height: 90px !important;
                }
            }
        }
    }
}

.get-ot {
    margin: 0rem 0;
    background-color: #47463E;
    border-radius: 15px;
    padding: 2rem;

    @media (max-width:768px) {
        padding: 1.2rem !important;
        margin: 2rem 0 !important;
    }

    @media (max-width:768px) {
        padding: 1rem !important;
        margin: 0rem 0 !important;
    }

    .get-ot-img {
        margin: 2rem 0;
        padding: 2rem;

        @media (max-width:768px) {
            padding: 0rem !important;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .get-ot-btm {
        display: flex;
        align-items: center;
        gap: 3rem;

        @media (max-width:768px) {
            gap: 1rem !important;
        }

        .get-ot-btm-img {
            img {
                display: block;
                object-fit: contain;
                width: 150px;
                height: auto;

                @media (max-width: 768px) {
                    width: 90px !important;
                }

                @media (max-width: 576px) {
                    width: 70px !important;
                }
            }
        }

        .get-ot-btm-text {
            color: #fff;
            font-family: 'GothamBold' !important;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            margin: 0;

            @media (max-width: 768px) {
                font-size: 11px !important;
                line-height: 16px !important;
                font-family: 'Montserrat' !important;
                font-weight: 700;
            }
        }
    }

    .get-ot-head {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p.get-ot-heading {
            color: #faa61a;
            font-family: 'GothamBold' !important;
            text-align: center;
            font-size: 40px;
            margin: 0 !important;

            @media (max-width: 768px) {
                font-size: 27px !important;
            }
        }

        p.get-ot-subheading {
            color: #ffffff;
            font-family: 'Montserrat' !important;
            text-align: center;
            font-size: 20px;
            margin: 0 !important;

            @media (max-width: 768px) {
                font-size: 12px !important;
            }

            @media (max-width: 576px) {
                font-size: 10px !important;
            }
        }
    }

}

.hk {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;

    @media (max-width: 992px) {
        flex-direction: column !important;
    }

    .hk-img {
        img {
            width: 230px;
            height: auto;
            display: block;
            object-fit: contain;

            @media (max-width:992px) {
                width: 90px !important;
            }

        }
    }

    .hk-img-2 {
        img {
            width: 150px;
            height: auto;
            display: block;
            object-fit: contain;

            @media (max-width:768px) {
                width: 75px !important;
            }
        }
    }

    .hk-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .hk-heading {
        color: #fff;
        font-size: 37px;
        font-family: 'GothamBold' !important;

        @media (max-width:768px) {
            font-size: 22px !important;
            text-align: center !important;
        }
    }

    .hk-subheading {
        color: #fff;
        font-size: 22px;

        font-family: 'Montserrat' !important;
        font-weight: 300 !important;

        span {
            color: #faa61a;
            font-weight: 600 !important;
        }

        @media (max-width:768px) {
            font-size: 15px !important;
            line-height: 18px;
            margin: 0 !important;
        }
    }

    .hk-subheading-2 {
        color: #fff;
        font-size: 18px;
        font-family: 'Montserrat' !important;
        font-weight: 300 !important;
        margin: 0;

        span {
            color: #faa61a;
            font-weight: 600 !important;
        }

        @media (max-width:768px) {
            font-size: 13px !important;
            line-height: 18px;
            text-align: center !important;
        }
    }

    .hk-list {
        color: #fff;
        font-size: clamp(14px, 3vw, 20px);
        font-family: 'Montserrat' !important;
        list-style: square;
        padding-top: 0;
        margin-top: 0;

        li {
            &::marker {
                color: #faa61a;
            }

            margin: 0.5rem 0;
        }

    }
}

.help {
    display: flex;
    align-items: center;
    gap: 2rem;
    background: #47463e;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);


    @media (max-width: 992px) {
        flex-direction: column !important;
    }

    .help-img {
        padding-left: 1rem;

        img {
            display: block;
            object-fit: contain;
            width: 100px;
            height: auto;
        }
    }

    p.help-heading {
        font-size: 28px;
        font-family: 'GothamBold' !important;
        color: #faa61a;
        text-align: center;
    }

    p.help-text {
        font-size: 20px;
        line-height: 28px;
        font-family: 'Montserrat' !important;
        color: #ffffff;
        text-align: center;

        @media (max-width:576px) {
            font-size: 17px !important;
            line-height: 22px !important;
        }

        a {
            color: #faa61a;
            text-decoration-color: #faa61a;
            font-family: 'GothamBold' !important;
        }
    }

    .help-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.help-2 {
    display: flex;
    align-items: center;
    gap: 2rem;
    background: #47463e;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);


    @media (max-width: 992px) {
        flex-direction: column !important;
    }

    .help-img {
        padding-left: 1rem;

        img {
            display: block;
            object-fit: contain;
            width: 100px;
            height: auto;
        }
    }

    p.help-heading {
        font-size: 28px;
        font-family: 'GothamBold' !important;
        color: #faa61a;
        text-align: center;
        margin: 0;
    }

    p.help-text {
        font-size: 20px;
        line-height: 28px;
        font-family: 'Montserrat' !important;
        color: #ffffff;
        text-align: center;
        margin: 0;

        @media (max-width:576px) {
            font-size: 14px !important;
            line-height: 22px !important;
        }

        a {
            color: #faa61a;
            text-decoration-color: #faa61a;
            font-family: 'Montserrat' !important;
        }
    }

    .help-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.search-grid {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1rem;
}

.filter-btn {
    background: #149247;
    font-family: 'GothamBold' !important;
    color: #fff;
    width: 100%;
    padding: 0.5rem !important;
    border-radius: 8px !important;

    &:hover {
        background: #0b7736 !important;
    }
}


// search
.fq-search {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .search-subgrid {
        display: grid;
        align-items: center;
        gap: 1rem;
        box-sizing: border-box;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        grid-template-columns: 0.7fr auto;

        @media (max-width:992px) {
            grid-template-columns: 1fr !important;
        }
    }

    .fq-search-gridL {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1rem;
        align-items: center;
    }

    .fq-search-btn-grid {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2rem;
        align-items: center;
        box-sizing: border-box;

        @media (max-width:768px) {
            grid-template-columns: auto 1fr !important;
            gap: 1rem !important;

            .fq-search-btn-grid .radio-grp.list-eqp-page p {
                color: #fff !important;
                font-size: 12px !important;
                display: flex;
                align-items: center;
            }
        }


    }

    .fq-search-result-btn {
        font-size: 16px;
        font-family: 'GothamBold' !important;
        border-radius: 6px;
        padding: 0.6rem 1rem;
        background-color: #149247 !important;

        @media (max-width:576px) {
            font-size: 14px !important;
            text-transform: capitalize !important;
            border-radius: 5px !important;
            padding: 0.3rem 1rem !important;
        }
    }

    .search-filter-icon {
        display: block;
        object-fit: contain;
        width: 35px;
        height: 35px;
    }
}

.fq-radio-label {


    @media (max-width:576px) {
        font-size: 13px !important;

        .fq-radio-grp {
            display: flex;
            gap: 5px;
        }


    }

    @media (max-width:320px) {
        font-size: 12px !important;
    }
}

label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.fq-radio-grp {
    margin-left: 0 !important;
    gap: 8px;
    margin-right: 0 !important;
}

#fq-select {
    padding: 14px;
}


@media (max-width: 768px) {
    .lgt-img {
        order: 2;
    }
}